import React from "react";
import { Link } from "react-router-dom";
import Card from "../../../UI/Card";
import TableScrollable from "../../../UI/Table/TableScrollable";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";
import PageLoader from "../../../UI/PageLoader";
import Pill from "../../../UI/Pill";
import { hoverLink } from "../../../../utils/hoverLink";
import { checkPermission } from "../../../../utils/checkPermission";

export default function RolesTable({ instructor }) {
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);

  const roles = [
    ...(instructor?.head_school_admin_for_schools || []).map((role) => ({
      titleized_role: "Head School Admin",
      organization_name: role.church_name,
      organization_id: role.id,
    })),
    ...(instructor?.roles || []).map((role) => ({
      titleized_role: role.titleized_role,
      organization_name: role.organization_name,
      organization_id: role.organization_id,
    })),
  ];

  return roles ? (
    <Card classNames="overflow-y-scroll no-scrollbar min-h-[360px] mt-4">
      <CardHeader title="Schools" />

      {roles?.length ? (
        <TableScrollable height={247}>
          <thead>
            <tr>
              <TableHeader classNames="w-8/12">SCHOOL</TableHeader>
              <TableHeader classNames="w-4/12">ROLE</TableHeader>
            </tr>
          </thead>
          <tbody className="h-[247px]">
            {roles.map((role) => (
              <tr key={role.id}>
                <TableCell classNames="w-8/12">
                  {isGlobalAdmin || isAdmin ? (
                    <Link
                      className={hoverLink}
                      to={`/schools/${role.organization_id}/overview`}
                    >
                      {role.organization_name}
                    </Link>
                  ) : (
                    <span>{role.organization_name}</span>
                  )}
                </TableCell>
                <TableCell classNames="w-4/12">
                  <Pill>{role.titleized_role}</Pill>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </TableScrollable>
      ) : (
        <EmptyTable message="No schools for this instructor." />
      )}
    </Card>
  ) : (
    <Card classNames="min-h-[360px]">
      <PageLoader variant="primary" />
    </Card>
  );
}
