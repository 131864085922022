import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import AddButton from "../../../UI/AddButton";
import PageLoader from "../../../UI/PageLoader";
import { getAllCourseDecisionsRequest } from "../../../../requests/courses";
import { useParams } from "react-router-dom";
import CourseDecisionsNew from "./CourseDesisonsNew";
import CourseDecisionsRow from "./CourseDecisionsRow";
import { deleteDecisionRequest } from "../../../../requests/decisions";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import Modal from "../../../UI/Modal";
import { ToastNotification } from "../../../UI/ToastNotification";
import { checkPermission } from "../../../../utils/checkPermission";
import EmptyTable from "../../../UI/EmptyTable";

export default function CourseDecisions() {
  const params = useParams();
  const courseId = params.courseId;
  const [decisions, setDecisions] = useState();
  const [formOpen, setFormOpen] = useState({ open: false, action: null });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeDecisionId, setActiveDecisionId] = useState(null);

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({ courseId: courseId });

    if (res.data) {
      setDecisions(res.data);
    }
  };

  const deleteDecision = async () => {
    try {
      const res = await deleteDecisionRequest({ decisionId: activeDecisionId });

      if (res) {
        ToastNotification("success", "Decision deleted successfully.");
        setDeleteModalOpen(false);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const handleDeleteModal = (e, decisionId) => {
    e.preventDefault();
    setDeleteModalOpen(true);
    setActiveDecisionId(decisionId);
  };

  useEffect(() => {
    getAllCourseDecisions();
  }, [formOpen, deleteModalOpen]);

  return decisions ? (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["global_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() => setFormOpen({ open: true, action: "new" })}
              />
            )}

            <h1 className="text-xl font-bold text-primary">DECISIONS</h1>
          </div>
        </div>
        {decisions.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>DECISION</TableHeader>
                <TableHeader>DECISIONS RECORDED</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {decisions.map((decision) => (
                <CourseDecisionsRow
                  key={decision.id}
                  setFormOpen={setFormOpen}
                  formOpen={formOpen}
                  decision={decision}
                  handleDeleteModal={handleDeleteModal}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No decisions found." />
        )}

        {formOpen.action === "new" && (
          <div>
            <CourseDecisionsNew courseId={courseId} setFormOpen={setFormOpen} />
          </div>
        )}

        {formOpen.action === "new" ? null : (
          <div className="flex justify-center mt-4">
            <AddButton
              onClick={() => setFormOpen({ open: true, action: "new" })}
              label="Add Decision"
              variant="primary-outline"
            />
          </div>
        )}
      </Card>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteDecision()}
        />
      </Modal>
    </div>
  ) : (
    <Card>
      <PageLoader variant="primary" />
    </Card>
  );
}
