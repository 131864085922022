import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useMediaQuery } from "../../../../../../utils/useMediaQuery";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import ReminderRow from "./ReminderRow";
import AddButton from "../../../../../UI/AddButton";
import ReminderNew from "./ReminderNew";
import EmptyTable from "../../../../../UI/EmptyTable";
import RemindersMobile from "./Mobile/RemindersMobile";

export default function Reminders() {
  const isMobile = useMediaQuery("md", "down");
  const { enrollment } = useOutletContext();
  const [addingReminder, setAddingReminder] = useState(false);
  const reminders = enrollment?.reminders;

  return isMobile ? (
    <RemindersMobile enrollment={enrollment} />
  ) : (
    <>
      {reminders?.length ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>REMINDER DATE</TableHeader>
              <TableHeader>NOTES</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {reminders.map((reminder) => (
              <ReminderRow
                key={reminder.id}
                enrollment={enrollment}
                reminder={reminder}
                handleCancelAdd={() => setAddingReminder(false)}
                addingReminder={addingReminder}
                disabled={enrollment.readOnly}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No reminders recorded." />
      )}

      {addingReminder ? (
        <div className="mt-4">
          <ReminderNew
            enrollment={enrollment}
            handleCancelAdd={() => setAddingReminder(false)}
          />
        </div>
      ) : (
        !enrollment.readOnly && (
          <div className="flex justify-center mt-4">
            <AddButton
              onClick={() => setAddingReminder(true)}
              label="Add Reminder"
              variant="primary-outline"
            />
          </div>
        )
      )}
    </>
  );
}
