import React, { useState } from "react";
import NoteForm from "../../NoteForm";
import Modal from "../../../../../../UI/Modal";
import ConfirmationDialog from "../../../../../../UI/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";

export default function Note({
  note,
  enrollment,
  setAddingNotes,
  visit,
  disabled,
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        visits_attributes: [
          {
            id: visit.id,
            notes_attributes: [
              {
                id: note.id,
                text: data.text,
                type: data.type,
                _destroy: data === "destroy" ? true : false,
              },
            ],
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        setDeleteModalOpen(false);
        navigate(".", { replace: true });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return (
    <>
      <NoteForm
        setAddingNotes={setAddingNotes}
        note={note}
        onSubmit={onSubmit}
        setDeleteModalOpen={setDeleteModalOpen}
        disabled={disabled}
      />
      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => onSubmit("destroy")}
        />
      </Modal>
    </>
  );
}
