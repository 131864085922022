import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../../UI/Button";
import CourseSelect from "./CourseSelect";

export default function CreateNewStudent({
  setCreateNewStudent,
  setNewStudentForm,
  selectedCourseId,
  setSelectedCourseId,
}) {
  const methods = useForm();
  const selectedCourse = methods.watch("course");

  const onSubmit = (data) => {
    setSelectedCourseId(data.course);
    setCreateNewStudent(false);
    setNewStudentForm(true);
  };

  useEffect(() => {
    if (selectedCourseId) {
      methods.setValue("course", selectedCourseId);
    }
  }, [selectedCourseId]);

  return (
    <>
      <h2 className="text-2xl text-center">Assign new student automatically</h2>
      <p className="text-[15px] text-center">
        Select the course you would like to enroll the new student in.
      </p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-6">
          <CourseSelect
            onChange={(course) => setSelectedCourseId(course?.id)}
            selectedCourseId={selectedCourseId}
          />

          <div className="text-center mt-4">
            <Button type="submit" variant="primary" disabled={!selectedCourse}>
              Next
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
