import React, { useState } from "react";
import Button from "./Button";
import PageLoader from "./PageLoader";

export default function ConfirmationDialog({
  closeModal,
  confirmAction,
  label = "This action cannot be undone",
  title = "Are you sure?",
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmAction = () => {
    setIsLoading(true);
    confirmAction();
  };

  return (
    <div className="p-6 text-white">
      {isLoading ? (
        <div className="flex justify-center items-center h-full min-w-[300px]">
          <PageLoader className="h-auto md:h-auto" />
        </div>
      ) : (
        <>
          <p className="text-2xl mb-3">{title}</p>
          <p>{label}</p>
          <div className="flex mt-6">
            <Button
              variant="primary-outline"
              classNames="mr-4"
              onClick={closeModal}
              className="mr-6"
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmAction}>
              Confirm
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
