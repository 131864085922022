import React from "react";
import Decisions from "./Decisions/Decisions";

export default function LessonDecisions({
  addingDecision,
  decisionOptions,
  lesson,
  enrollment,
  episodeDelivery,
  user,
  setAddingDecision,
  disabled,
}) {
  return (
    <Decisions
      addingDecision={addingDecision}
      decisionOptions={decisionOptions}
      lesson={lesson}
      enrollment={enrollment}
      episodeDelivery={episodeDelivery}
      user={user}
      setAddingDecision={setAddingDecision}
      disabled={disabled}
    />
  );
}
