import React, { useMemo } from "react";
import AddButton from "../../../UI/AddButton";
import Checkbox from "../../../UI/Inputs/Checkbox";
import { useFieldArray, useFormContext } from "react-hook-form";
import RoleSelectRow from "./RoleSelectRow";
import { useSearchParams } from "react-router-dom";
import Button from "../../../UI/Button";
import { deleteMFAUserRequest } from "../../../../requests/users";
import { ToastNotification } from "../../../UI/ToastNotification";
import { checkPermission } from "../../../../utils/checkPermission";

export default function AccountForm({ instructor }) {
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const [searchParams] = useSearchParams();
  const schoolId = searchParams.get("school_id");
  const role = searchParams.get("role");
  const headSchoolAdmin = Boolean(
    JSON.parse(searchParams.get("head_school_admin") || "0")
  );
  const { control, watch, setValue } = useFormContext();

  const { fields, append, update } = useFieldArray({
    control,
    name: "roles_attributes",
  });

  useMemo(() => {
    if (role && schoolId) {
      append({ role_type: role, organization_id: schoolId });
      setValue("head_school_admin_of", headSchoolAdmin ? schoolId : undefined);
    }
  }, [schoolId, role, append, setValue]);

  const handleDeleteMFA = async () => {
    try {
      await deleteMFAUserRequest({ userId: instructor.id });
      ToastNotification("success", "Multi-Factor Authentication deleted");
    } catch (error) {
      ToastNotification(
        "error",
        "Failed to delete Multi-Factor Authentication"
      );
    }
  };

  return (
    <div>
      {instructor && (
        <>
          <Button
            classNames="mb-6"
            variant="primary-outline"
            onClick={handleDeleteMFA}
            disabled={!instructor["mfa_enabled?"]}
          >
            Delete MFA
          </Button>

          <div className="mb-6 border-b border-black h-px"></div>
        </>
      )}

      <h2 className="mb-6 text-2xl">Options</h2>

      <div className="mb-6">
        <div className="mb-6">
          <Checkbox
            id="followup_notification"
            label="Receive notifications by email"
            defaultChecked={
              instructor ? instructor.followup_notification : true
            }
          />
        </div>

        {isGlobalAdmin && (
          <div>
            <Checkbox
              id="admin"
              defaultChecked={instructor?.admin}
              label="Global Admin"
            />
          </div>
        )}

        {headSchoolAdmin && (
          <div className="mt-6">
            <Checkbox
              id="head_school_admin"
              label="Head School Admin"
              defaultChecked
            />
          </div>
        )}
      </div>

      {!watch("admin", instructor?.admin) && (
        <>
          <div className="mb-6 border-b border-black h-px"></div>

          <h2 className="mb-6 text-2xl">Roles</h2>

          {fields
            .filter((activeField) => !activeField.delete)
            .map((field, index) => (
              <RoleSelectRow
                key={field.id}
                field={field}
                index={index}
                update={update}
              />
            ))}

          <div className="mb-6">
            <AddButton
              onClick={() => append({ role_type: "instructor" })}
              label="Add additional role"
              variant="primary-outline"
            />
          </div>
        </>
      )}
    </div>
  );
}
