import React, { useEffect, useState } from "react";
import InputGroup from "../../../UI/Inputs/InputGroup";
import {
  getAllSeminars,
  getSeminarRequest,
} from "../../../../requests/seminars";
import SearchAsync from "../../../UI/SearchAsync";

export default function SeminarSelect({ onChange, loaderSeminar }) {
  const [defaultValue, setDefaultValue] = useState(null);

  const loadOptions = async (inputValue) => {
    const res = await getAllSeminars({
      params: { q: { name_i_cont_all: inputValue.trim().split(" ") } },
    });

    return res.data.seminars.map((seminar) => ({
      label: `${seminar.name}`,
      value: seminar.id,
    }));
  };

  const handleChange = async (selected) => {
    onChange(null);
    const res = await getSeminarRequest({ seminarId: selected.value });
    onChange(res.data);
  };

  useEffect(() => {
    if (loaderSeminar) {
      const defaultOption = {
        label: `${loaderSeminar.name}`,
        value: loaderSeminar.id,
      };
      setDefaultValue(defaultOption);
      onChange(loaderSeminar);
    }
  }, [loaderSeminar]);

  return (
    <div className="lg:w-1/2">
      <label>Seminar</label>
      <InputGroup>
        <SearchAsync
          loadOptions={loadOptions}
          onChange={handleChange}
          placeholder="Search for a seminar"
          className="border rounded"
          defaultValue={defaultValue}
        />
      </InputGroup>
    </div>
  );
}
