import axiosClient from "../utils/axios";
import { downloadCsvRequest } from "../utils/downloadCsvRequest";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/students`;

const getAllStudents = async (params) => {
  const data = await axiosClient.get(endpoint, params);

  return data;
};

const getStudentRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.studentId}`);

  return data;
};

const createStudentRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const downloadCsvStudentsRequest = async (params) =>
  downloadCsvRequest(`${endpoint}.csv`, params, "Students");

const updateStudentRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.studentId}`, params);

  return data;
};

const updateStudentAveryLabels = async (params) => {
  const data = await axiosClient.put(
    `${endpoint}/${params.studentId}/update_avery_labels`,
    params
  );

  return data;
};

const deleteStudentRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.studentId}`);

  return data;
};

const suggestedSchoolsRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/suggested_schools`, {
    params: params,
  });

  return data;
};

const averyLabelsRequest = async (params) => {
  return await axiosClient.get(`${endpoint}/avery_labels`, {
    params: params,
  });
};

const getAllStudentDecisionsRequest = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.studentId}/decisions`
  );

  return data;
};

const getAllStudentNotesRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.studentId}/notes`);

  return data;
};

const getAllStudentProductsRequest = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.studentId}/products`
  );

  return data;
};

const bulkReassignStudentsRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/bulk_reassign`, params);

  return data;
};

const bulkShareStudentsRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/bulk_share`, params);

  return data;
};

const bulkUnshareStudentsRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/bulk_unshare`, params);

  return data;
};

export {
  getAllStudents,
  getStudentRequest,
  createStudentRequest,
  downloadCsvStudentsRequest,
  updateStudentRequest,
  deleteStudentRequest,
  suggestedSchoolsRequest,
  averyLabelsRequest,
  getAllStudentDecisionsRequest,
  getAllStudentNotesRequest,
  getAllStudentProductsRequest,
  bulkReassignStudentsRequest,
  bulkShareStudentsRequest,
  bulkUnshareStudentsRequest,
  updateStudentAveryLabels,
};
