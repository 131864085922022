import React, { useState } from "react";
import ListButtonGroupItem from "../../../../../../UI/ListButtonGroup/ListButtonGroupItem";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";
import { Tooltip } from "../../../../../../UI/Tooltip";
import { useRevalidator } from "react-router-dom";

const deliveryTypes = [
  {
    label: "PO",
    value: "Post Office",
  },
  {
    label: "PD",
    value: "Personal Delivery",
  },
  {
    label: "IH",
    value: "In-home",
  },
];

export default function DeliveryType({
  lesson,
  enrollment,
  episodeDelivery,
  user,
  disabled,
}) {
  const revalidator = useRevalidator();
  const [loading, setLoading] = useState(false);
  const defaultSelected = episodeDelivery
    ? deliveryTypes.find((item) => item.value === episodeDelivery.delivery_type)
        ?.label
    : "";

  const [selected, setSelected] = useState(defaultSelected);

  const onSubmit = async (item) => {
    setLoading(true);

    try {
      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            delivery_type: selected === item.label ? "" : item.value,
            episode_id: lesson.id,
            grading_instructor_id: user.id,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        setSelected(selected === item.label ? "" : item.label);
        revalidator.revalidate();
        ToastNotification("success", "Updated successfully.");
      }
    } catch (e) {
      ToastNotification("error", e.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <span className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary" />
      </div>
    );
  }

  return deliveryTypes.map((item, index) => (
    <Tooltip content={item.value} key={item.label}>
      <ListButtonGroupItem
        label={item.label}
        isFirst={index === 0}
        isLast={index === deliveryTypes.length - 1}
        selected={selected}
        setSelected={() => onSubmit(item)}
        disabled={loading || disabled}
      />
    </Tooltip>
  ));
}
