import axiosClient from "../utils/axios";
import { downloadCsvRequest } from "../utils/downloadCsvRequest";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/schools`;

const getAllSchools = async (params) => {
  const data = await axiosClient.get(endpoint, params);

  return data;
};

const getAllSchoolsSimpleList = async () =>
  await axiosClient.get(`${endpoint}/simple_list`);

const getSchoolRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.schoolId}`);

  return data;
};

const getAllSchoolInstructors = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.school_id}/instructors`,
    {
      params: params,
    }
  );

  return data;
};

const getAllSchoolSeminars = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.school_id}/seminars_offered`,
    {
      params: params,
    }
  );

  return data;
};

const getAllSchoolCourses = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.school_id}/courses`,
    {
      params: params,
    }
  );

  return data;
};

const getAllSchoolStudents = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.school_id}/students`,
    {
      params: params,
    }
  );

  return data;
};

const createSchoolRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const updateSchoolRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.schoolId}`, params);

  return data;
};

const deleteSchoolRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.schoolId}`);

  return data;
};

const updateSchoolClaimedZipCodes = async (params) => {
  const data = await axiosClient.post(
    `${endpoint}/${params.schoolId}/claimed_zips`,
    params
  );

  return data;
};

const downloadCsvSchoolsRequest = async (params) =>
  downloadCsvRequest(`${endpoint}.csv`, params, "Schools");

const bulkImportClaimedZipsRequest = async (params) => {
  const data = await axiosClient.post(`${endpoint}/bulk_import_zips`, params);

  return data;
};

export {
  getAllSchools,
  getAllSchoolsSimpleList,
  createSchoolRequest,
  getAllSchoolInstructors,
  updateSchoolRequest,
  getSchoolRequest,
  deleteSchoolRequest,
  getAllSchoolCourses,
  getAllSchoolStudents,
  getAllSchoolSeminars,
  updateSchoolClaimedZipCodes,
  downloadCsvSchoolsRequest,
  bulkImportClaimedZipsRequest,
};
