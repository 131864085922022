import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import CardLink from "../../../../UI/Card/CardLink";
import CardCounter from "../../../../UI/Card/CardCounter";
import { getAllUserStudentsRequest } from "../../../../../requests/users";

export default function InstructorMobileOverview({ courses, needingFollowup }) {
  const { instructor } = useOutletContext();
  const baseRoute = `/instructors/${instructor?.id}`;
  const [studentsCount, setStudentsCount] = useState();

  const getStudentsCount = async () => {
    const res = await getAllUserStudentsRequest({ userId: instructor.id });

    setStudentsCount(res.data.length);
  };

  useEffect(() => {
    getStudentsCount();
  }, []);

  return (
    <div className="max-w-[320px] m-auto">
      <CardLink
        className="block w-[150px] m-auto mb-9"
        cardClassName="rounded-2xl p-3 text-center"
        to={`${baseRoute}/profile`}
      >
        View Profile
      </CardLink>

      <div className="grid gap-4 grid-cols-2">
        <CardCounter
          to={`${baseRoute}/students?q[enrollments_needing_followup_eq]=true`}
          count={needingFollowup?.length}
          label="Follow-up"
          className="text-center rounded-3xl"
        />
        <CardCounter
          to={`${baseRoute}/courses`}
          count={courses?.length}
          label="Courses"
          className="text-center rounded-3xl"
        />
      </div>
      <div className="flex mt-4">
        <CardCounter
          to={`${baseRoute}/students`}
          count={studentsCount}
          label="Students"
          className="mb-4 w-full"
        />
      </div>
    </div>
  );
}
