import React, { useEffect, useState } from "react";
import CardLink from "../../../../UI/Card/CardLink";
import CardCounter from "../../../../UI/Card/CardCounter";
import { useOutletContext } from "react-router-dom";
import {
  getAllStudentDecisionsRequest,
  getAllStudentNotesRequest,
  getAllStudentProductsRequest,
} from "../../../../../requests/students";

export default function StudentMobileOverview() {
  const { student } = useOutletContext();
  const baseRoute = `/students/${student?.id}`;
  const [decisions, setDecisions] = useState();
  const [notes, setNotes] = useState();
  const [products, setProducts] = useState();

  const getAllStudentDecisions = async () => {
    const res = await getAllStudentDecisionsRequest({
      studentId: student.id,
    });

    if (res.data) {
      setDecisions(res.data);
    }
  };

  const getAllStudentNotes = async () => {
    const res = await getAllStudentNotesRequest({
      studentId: student.id,
    });

    if (res.data) {
      setNotes([
        ...res.data.episode_delivery_notes,
        ...res.data.reminder_notes,
        ...res.data.visit_notes,
      ]);
    }
  };

  const getAllStudentProducts = async () => {
    const res = await getAllStudentProductsRequest({
      studentId: student.id,
    });

    if (res.data) {
      setProducts(res.data);
    }
  };

  useEffect(() => {
    getAllStudentDecisions();
    getAllStudentNotes();
    getAllStudentProducts();
  }, []);

  return (
    <div className="max-w-[320px] m-auto">
      <CardLink
        className="block w-[150px] m-auto mb-9"
        cardClassName="rounded-2xl p-3 text-center"
        to={`${baseRoute}/profile`}
      >
        View Profile
      </CardLink>

      <div className="grid gap-4 grid-cols-2">
        <CardCounter
          to={`${baseRoute}/courses`}
          count={student.course_enrollments?.length}
          label="Courses"
          className="text-center rounded-3xl"
        />
        <CardCounter
          to={`${baseRoute}/seminars`}
          count={student.seminar_enrollments?.length}
          label="Seminars"
          className="text-center rounded-3xl"
        />
        <CardCounter
          to={`${baseRoute}/decisions`}
          count={decisions?.length}
          label="Decisions"
          className="text-center rounded-3xl"
        />
        <CardCounter
          to={`${baseRoute}/notes`}
          count={notes?.length}
          label="Notes"
          className="text-center rounded-3xl"
        />
        <CardCounter
          to={`${baseRoute}/products`}
          count={products?.length}
          label="Products"
          className="text-center rounded-3xl"
        />
      </div>
    </div>
  );
}
