import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../UI/Inputs/Input";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import Button from "../../../UI/Button";

export default function SeminarPresentationForm({
  setOpen,
  presentation,
  onSubmit,
}) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="md:flex items-end">
          <Input
            id="name"
            classNames="md:w-3/12 w-full md:mr-4 mb-2 md:mb-0"
            placeholder="Presentation Name"
            defaultValue={presentation?.name}
            isRequired
            autoFocus
          />
          <div className="md:mr-4 mb-2 md:mb-0">
            <DatePickerInput
              id="date"
              selected={presentation ? presentation?.date : new Date()}
              isRequired
            />
          </div>
          <Input
            id="decision_card_name"
            classNames="md:w-3/12 mr-4 mb-2 md:mb-0"
            placeholder="Decision Card"
            defaultValue={presentation?.decision_card_name}
          />
          <Button
            onClick={() => setOpen({ open: false, action: null })}
            classNames="mr-4"
            variant="primary-outline"
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary-outline">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
