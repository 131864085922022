import React, { useEffect, useState } from "react";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Button from "../../../UI/Button";
import { getAllSchoolInstructors } from "../../../../requests/schools";
import { Link } from "react-router-dom";
import { displayError } from "../../../../utils/displayError";
import Checkbox from "../../../UI/Inputs/Checkbox";
import CoursesOffered from "./CoursesOffered";
import Modal from "../../../UI/Modal";
import ClaimedZipCodes from "./ClaimedZipCodes";
import { checkPermission } from "../../../../utils/checkPermission";
import HeadSchoolAdmin from "./HeadSchoolAdmin";

export default function SideSection({ school }) {
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const [admins, setAdmins] = useState([]);
  const [claimedZipCodesModal, setClaimedZipCodesModal] = useState({
    open: false,
  });

  const getAdmins = async () => {
    try {
      const res = await getAllSchoolInstructors({ school_id: school.id });

      if (res.data) {
        setAdmins(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    if (school) {
      getAdmins();
    }
  }, []);

  return (
    <div>
      {school && (
        <div className="mb-12">
          <h2 className="mb-4 text-2xl">Administrator</h2>
          {admins.length > 0 ? (
            <div className="flex items-end">
              <div className="mr-4">
                <Select
                  id="head_school_admin_id"
                  classNames="w-full"
                  label="Head School Admin"
                  defaultValue={school?.head_school_admin_id}
                  isRequired
                >
                  <SelectItem value="" disabled selected>
                    Select a head school admin
                  </SelectItem>
                  {admins.map((admin) => (
                    <SelectItem key={admin.id} value={admin.id}>
                      {admin.first_name} {admin.last_name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
            </div>
          ) : (
            <p>
              No head school admin is assigned to this school. School will not
              receive new student offers.
            </p>
          )}

          <div className="mt-4 text-sm text-gray-300 flex flex-col md:block">
            <Link
              to={`/instructors/new?school_id=${school.id}&role=instructor`}
              className="text-primary hover:text-primary-600"
            >
              New Instructor
            </Link>

            {isGlobalAdmin && (
              <>
                {" | "}
                <Link
                  to={`/instructors/new?school_id=${school.id}&role=school_admin`}
                  className="text-primary hover:text-primary-600"
                >
                  New School Admin
                </Link>
                {" | "}
                <Link
                  to={`/instructors/new?school_id=${school.id}&role=school_admin&head_school_admin=true`}
                  className="text-primary hover:text-primary-600"
                >
                  New Head School Admin
                </Link>
              </>
            )}
          </div>
        </div>
      )}

      <HeadSchoolAdmin head_school_admin={school?.head_school_admin} />

      {isGlobalAdmin && (
        <div className="mb-12">
          <h2 className="mb-4 text-2xl">Discovery Centers</h2>
          <Checkbox
            id="discovery_center"
            label="Active Event Tracking for the Evangelism Bundle"
            defaultChecked={school?.discovery_center}
          />
        </div>
      )}

      {isGlobalAdmin && (
        <div className="mb-12">
          <h2 className="mb-4 text-2xl">Options</h2>
          <Checkbox
            id="prison_ministry"
            label="Prison Ministry"
            defaultChecked={school?.prison_ministry}
            classNames="mb-4"
          />
          <Checkbox
            id="signs_box"
            label="Signs Box"
            defaultChecked={school?.signs_box}
            classNames="mb-4"
          />
          <Checkbox
            id="no_po_box"
            label="No PO Box"
            defaultChecked={school?.no_po_box}
          />
        </div>
      )}

      {school && (
        <div className="mb-12">
          <CoursesOffered
            schoolId={school.id}
            coursesOffered={school.courses_offered}
          />
        </div>
      )}

      {school && (
        <>
          <div>
            <h2 className="mb-4 text-2xl">Claimed Zip Codes</h2>
            <div className="md:flex items-center">
              {isGlobalAdmin && (
                <Button
                  variant="primary-outline"
                  onClick={() => {
                    setClaimedZipCodesModal({ open: true });
                  }}
                >
                  Manage Claimed Zip Codes
                </Button>
              )}
              {school.claimed_zips.length === 0 && (
                <p className="md:ml-4 ml-0 mt-2 md:mt-0 leading-none">
                  No claimed zip codes.
                </p>
              )}
            </div>
            <div className="block p-2">
              {school.claimed_zips.map((code) => (
                <p className="p-2 inline-block" key={code.id}>
                  {code.zip}
                </p>
              ))}
            </div>
          </div>

          {!isGlobalAdmin && (
            <p className="text-sm text-gray-500 mt-8">
              If you would like to change the courses your school offers or the
              zips where you can receive new student offers for please contact
              VOP. To contact VOP, email{" "}
              <a
                href="mailto:discoverschool@vop.com"
                className="text-secondary hover:text-secondary-600"
              >
                discoverschool@vop.com
              </a>{" "}
              or phone (970) 622-2990.
            </p>
          )}

          <Modal
            open={claimedZipCodesModal.open}
            setOpen={() => setClaimedZipCodesModal({ open: false })}
          >
            <ClaimedZipCodes
              closeModal={() => {
                setClaimedZipCodesModal({ open: false });
              }}
              postalCodes={school.claimed_zips}
            />
          </Modal>
        </>
      )}
    </div>
  );
}
