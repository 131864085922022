import React from "react";
import NavGroup from "../../UI/NavGroup/NavGroup";
import NavGroupItem from "../../UI/NavGroup/NavGroupItem";

export default function SeminarNav({ seminar }) {
  const seminarNavigation = [
    {
      label: "Details",
      url: "details",
    },
    {
      label: "Students",
      url: "students",
      count: seminar?.enrollments_count,
    },
    {
      label: "Presentations",
      url: "presentations",
      count: seminar?.episodes_count,
    },
    {
      label: "Attendance",
      url: "attendance",
    },
    {
      label: "Decisions",
      url: "decisions",
      count: seminar?.decision_options?.length,
    },
    {
      label: "Products",
      url: "products",
      count: seminar?.products?.length,
    },
    {
      label: "Print Tickets",
      url: "print-tickets",
    },
  ];

  return (
    <NavGroup>
      {seminarNavigation.map((item, index) => (
        <NavGroupItem
          key={item.label}
          label={item.label}
          url={`/seminars/${seminar?.id}/${item.url}`}
          isLast={index === seminarNavigation.length - 1}
          isFirst={index === 0}
          count={item.count}
        />
      ))}
    </NavGroup>
  );
}
