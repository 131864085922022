import React from "react";
import { twMerge } from "tailwind-merge";

export default function Pill({ children, classNames, color = "default" }) {
  let badgeColor;

  switch (color) {
    case "default":
      badgeColor = "bg-secondary";
      break;
    case "success":
      badgeColor = "bg-green-500";
      break;
    case "disabled":
      badgeColor = "bg-gray-500";
      break;
    case "error":
      badgeColor = "bg-red-500";
      break;
    default:
      badgeColor = "bg-secondary";
  }

  return (
    <div
      className={twMerge(
        "inline-flex items-center border rounded-full pl-2 pr-2 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white [&_div:last-of-type]:mr-[-.25rem] whitespace-nowrap",
        badgeColor,
        classNames
      )}
    >
      {children}
    </div>
  );
}
