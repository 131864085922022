import React from "react";
import NavGroup from "../../UI/NavGroup/NavGroup";
import NavGroupItem from "../../UI/NavGroup/NavGroupItem";

export default function SchoolNav({ school }) {
  const schoolNavigation = [
    {
      label: "Overview",
      url: "overview",
    },
    {
      label: "Instructors",
      url: "instructors",
      count: school?.users_count,
    },
    {
      label: "Courses",
      url: "courses",
      count: school?.courses_count,
    },
    {
      label: "Seminars",
      url: "seminars",
      count: school?.seminars_offered_count,
    },
    {
      label: "Students",
      url: "students",
      count: school?.active_students_count,
    },
  ];

  return (
    <NavGroup>
      {schoolNavigation.map((item, index) => (
        <NavGroupItem
          key={item.label}
          label={item.label}
          url={`/schools/${school?.id}/${item.url}`}
          isLast={index === schoolNavigation.length - 1}
          isFirst={index === 0}
          count={item.count}
        />
      ))}
    </NavGroup>
  );
}
