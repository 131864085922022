import React from "react";
import Notes from "./Notes/Notes";

export default function LessonNotes({
  addingNotes,
  setAddingNotes,
  lesson,
  enrollment,
  episodeDelivery,
  user,
  disabled,
}) {
  return (
    <Notes
      setAddingNotes={setAddingNotes}
      lesson={lesson}
      enrollment={enrollment}
      episodeDelivery={episodeDelivery}
      addingNotes={addingNotes}
      user={user}
      disabled={disabled}
    />
  );
}
