import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const login = () => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = `${baseEndpoint}/users/auth/auth0`;
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

const logout = async () => {
  const data = await axiosClient.delete(`${baseEndpoint}/logout`);

  return data;
};

const signUp = async (params) => {
  const data = await axiosClient.post(`${baseEndpoint}/signup`, params);

  return data;
};

const changePassword = async () => {
  const data = await axiosClient.patch(`${baseEndpoint}/change_password`);

  return data;
};

export { login, logout, signUp, changePassword };
