import React, { useState, useEffect } from "react";
import { useLocation, useRouteLoaderData } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Dialog from "../UI/Modal";
import {
  dashboardLinks,
  studentLinks,
} from "../../utils/actionMenu/actionMenuLinks";
import ActionMenuItem from "./ActionMenuItem";
import { actionMenuChild } from "../../utils/actionMenu/actionMenuChildMapping";
import { checkPermission } from "../../utils/checkPermission";

export default function ActionMenu({
  actionMenuModal,
  setActionMenuModal,
  user,
}) {
  const location = useLocation();
  const modalOpen = actionMenuModal.open;
  const defaultRender = actionMenuModal.defaultRender;
  const resetActionMenu = { open: false, defaultRender: null };
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const isSchoolAdmin = checkPermission(["school_admin", "conference_admin"]);
  const [render, setRender] = useState(defaultRender);
  const loaderStudent = useRouteLoaderData("student")?.data;
  const [student, setStudent] = useState(null);

  let menuContent;

  if (isGlobalAdmin) {
    menuContent = dashboardLinks;
  } else if (isSchoolAdmin) {
    menuContent = studentLinks
      .concat(dashboardLinks)
      .filter((item) => !item.globalAdmin);
  } else {
    menuContent = studentLinks;
  }

  const ActionMenuChild = actionMenuChild[render];

  const renderContent = () => {
    if (render) {
      return (
        <ActionMenuChild
          setRender={setRender}
          user={user}
          loaderStudent={student}
        />
      );
    } else {
      return (
        <ul className="sm:grid-rows-1 grid-rows-2 grid grid-flow-col gap-2 justify-center">
          {menuContent.map((menuItem) => (
            <li
              key={menuItem.id}
              className="sm:py-0 py-6 text-white text-center w-[100px] flex justify-center"
            >
              <ActionMenuItem
                menuItem={menuItem}
                setRender={setRender}
                loaderStudent={student}
              />
            </li>
          ))}
        </ul>
      );
    }
  };

  useEffect(() => {
    setRender(defaultRender);
  }, [modalOpen]);

  useEffect(() => {
    setActionMenuModal(resetActionMenu);
  }, [location]);

  // This is to get the student data from the loader.
  // It's needed for actions that requires a pre-selected student.
  useEffect(() => {
    if (loaderStudent && loaderStudent.then) {
      loaderStudent.then((resolvedStudent) => {
        setStudent(resolvedStudent.data);
      });
    } else {
      setStudent(loaderStudent);
    }
  }, [loaderStudent]);

  return (
    <div
      className={twMerge(
        menuContent.length === 0 && "hidden",
        "2xl:left-[15%] 3xl:left-[25%] 4xl:left-[30%] xl:left-[10%] lg:left-10 lg:top-[4.2em] lg:absolute lg:from-inherit lg:w-auto lg:h-[56px] mr-7 pb-5 fixed bottom-0 z-20 h-auto max-w-full cursor-pointer left-1/2 -translate-x-1/2 w-full bg-gradient-to-t from-10% from-[rgba(0,0,0,.5)]"
      )}
    >
      <img
        src="/add.svg"
        width={56}
        height={56}
        className="m-auto"
        alt="Quick Add"
        aria-hidden="true"
        onClick={() => setActionMenuModal({ open: true, defaultRender: null })}
      />

      <Dialog
        open={modalOpen}
        setOpen={() => setActionMenuModal(resetActionMenu)}
      >
        <div
          className={twMerge(
            "relative",
            render
              ? "bg-neutral-600"
              : "bg-gradient-to-r from-primary to-primary-600"
          )}
        >
          <div className="sm:hidden text-white px-3 pt-6">
            <XMarkIcon
              onClick={() => setActionMenuModal(resetActionMenu)}
              className="w-7 ml-auto cursor-pointer"
            />
          </div>
          {renderContent()}
        </div>
      </Dialog>
    </div>
  );
}
