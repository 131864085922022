import React, { useEffect, useState } from "react";
import { getAllCourseSchoolsRequest } from "../../../../requests/courses";
import { useParams, useSearchParams } from "react-router-dom";
import Card from "../../../UI/Card";
import { displayError } from "../../../../utils/displayError";
import SchoolsTable from "../../../Schools/SchoolsTable";
import QuickFind from "../../../UI/QuickFind";
import { Pagination, usePagination } from "../../../UI/Pagination";

export default function CourseSchools() {
  const params = useParams();
  const [schools, setSchools] = useState();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const sortBy = searchParams.get("sortBy") || "church_name";
  const orderBy = searchParams.get("orderBy") || "asc";
  const query = searchParams.get("query") || "";
  const pagination = usePagination();

  const getAllCourseSchools = async () => {
    try {
      setLoading(true);

      const queryParams = {
        page: page,
        per_page: pagination.recordsPerPage,
        q: { s: `${sortBy} ${orderBy}` },
      };

      // Only add the search parameter if query is not empty.
      // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
      // Splitting the query terms into an array for the `cont` matcher.
      if (query.trim()) {
        queryParams.q.church_name_or_school_code_i_cont_all = query
          .trim()
          .replace("#", "")
          .split(" ");
      }

      const res = await getAllCourseSchoolsRequest(params.courseId, {
        params: queryParams,
      });

      if (res.data) {
        setSchools(res.data.schools);
        pagination.setData(res.data.pagination);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  useEffect(() => {
    getAllCourseSchools();
  }, [searchParams, pagination.recordsPerPage]);

  return (
    <Card>
      <div className="flex items-center justify-between mb-6">
        <div className="relative">
          <h1 className="text-xl font-bold text-primary">SCHOOLS</h1>
        </div>
        <div className="flex">
          <QuickFind onSubmit={onSubmit} value={query} />
        </div>
      </div>
      <Pagination
        pagination={pagination}
        loading={loading && !schools?.length}
        empty={Boolean(!schools?.length)}
      >
        <SchoolsTable schools={schools} loading={loading} />
      </Pagination>
    </Card>
  );
}
