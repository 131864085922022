import React from "react";
import Card from "../../../UI/Card";
import TableScrollable from "../../../UI/Table/TableScrollable";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";
import PageLoader from "../../../UI/PageLoader";
import { Link } from "react-router-dom";
import { hoverLink } from "../../../../utils/hoverLink";

export default function CoursesTable({ instructor, courses, needingFollowup }) {
  const getFollowupCount = (courseId) => {
    const followups = needingFollowup?.filter(
      (followup) => followup.series_id === courseId
    );

    return followups?.length;
  };

  return courses ? (
    <Card classNames="overflow-y-scroll no-scrollbar min-h-[360px]">
      <CardHeader
        title="Courses"
        to={`/instructors/${instructor.id}/courses`}
      />

      {courses?.length ? (
        <TableScrollable height={247}>
          <thead>
            <tr>
              <TableHeader classNames="w-5/12">COURSE</TableHeader>
              <TableHeader classNames="w-3/12 text-center">
                STUDENTS
              </TableHeader>
              <TableHeader classNames="w-4/12 text-center">
                NEEDING FOLLOW-UP
              </TableHeader>
            </tr>
          </thead>
          <tbody className="h-[247px]">
            {courses.map((course) => (
              <tr key={course.id}>
                <TableCell classNames="w-5/12">
                  <Link
                    className={hoverLink}
                    to={`/courses/${course.id}/overview`}
                  >
                    {course.name}
                  </Link>
                </TableCell>
                <TableCell classNames="w-3/12 text-center">
                  {course.students_count}
                </TableCell>
                <TableCell classNames="w-4/12 text-center">
                  {getFollowupCount(course.id)}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </TableScrollable>
      ) : (
        <EmptyTable message="No courses for this instructor." />
      )}
    </Card>
  ) : (
    <Card classNames="min-h-[360px]">
      <PageLoader variant="primary" />
    </Card>
  );
}
