import React from "react";
import Input from "../../../UI/Inputs/Input";
import InputGroup from "../../../UI/Inputs/InputGroup";

export default function Info({ seminar }) {
  return (
    <>
      <div className="lg:w-2/4 lg:border-r lg:pr-10 lg:mr-10 border-black">
        <InputGroup>
          <Input
            id="name"
            label="Name"
            defaultValue={seminar?.name}
            isRequired
          />
        </InputGroup>
      </div>
      <div className="lg:w-2/4">
        <InputGroup>
          <Input
            id="square_access_token"
            label="Square Access Token: (Optional)"
            defaultValue={seminar?.square_access_token}
            placeholder="(Please leave blank, if you don't know what this is.)"
          />
        </InputGroup>

        <div className="2xl:flex items-center mb-4">
          <p>Instructor follow-up notification delay:</p>
          <div className="lg:items-center flex">
            <Input
              type="number"
              min="0"
              id="instructor_followup_notification_delay"
              classNames="2xl:ml-4 mr-4 w-[80px]"
              placeholder="Note"
              defaultValue={seminar?.instructor_followup_notification_delay}
            />
            <p>{`(days; leave blank for default of: 7)`}</p>
          </div>
        </div>

        <div className="2xl:flex items-center">
          <p>Head school admin follow-up notification delay:</p>
          <div className="lg:items-center flex">
            <Input
              type="number"
              min="0"
              id="head_admin_followup_notification_delay"
              classNames="2xl:ml-4 mr-4 w-[80px]"
              placeholder="Note"
              defaultValue={seminar?.head_admin_followup_notification_delay}
            />
            <p>{`(days; leave blank for default of: 14)`}</p>
          </div>
        </div>
      </div>
    </>
  );
}
