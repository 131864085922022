import React from "react";
import { useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";

export default function Checkbox({
  id,
  isRequired,
  label,
  defaultChecked,
  disabled,
  onClick,
  labelColor = "text-black",
  labelFont = "font-medium",
  classNames,
  inputClassNames,
  dark,
}) {
  const { register } = useFormContext();

  return (
    <div className={twMerge("flex items-center", classNames)}>
      <input
        id={id}
        onClick={onClick}
        disabled={disabled}
        type="checkbox"
        defaultChecked={defaultChecked}
        className={twMerge(
          "h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600",
          dark
            ? "dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            : "",
          inputClassNames
        )}
        {...register(id, {
          required: isRequired ? "This field is required" : false,
        })}
      />
      {label && (
        <label
          htmlFor={id ? id : "default-checkbox"}
          className={twMerge(
            "ms-2 text-sm leading-none",
            labelColor,
            labelFont,
            dark ? "dark:text-white" : ""
          )}
        >
          {label}
        </label>
      )}
    </div>
  );
}
