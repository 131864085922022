import React from "react";
import dayjs from "dayjs";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import StudentEnrollmentsCell from "../../../UI/StudentEnrollmentsCell";
import { fullName } from "../../../../utils/fullName";
import { addressMultiLine } from "../../../../utils/fullAddress";
import { highlightText } from "../../../../utils/highlightText";
import MobileResults from "./Mobile/MobileResults";

export default function Results({ students, query }) {
  if (students.length === 0) {
    return (
      <p className="text-center border-b border-white pb-6">
        No students found.
      </p>
    );
  }

  return (
    <>
      <div className="sm:hidden block">
        <MobileResults students={students} />
      </div>
      <div className="sm:block hidden">
        <Table>
          <thead>
            <tr>
              <TableHeader>NAME</TableHeader>
              <TableHeader>ENROLLMENTS</TableHeader>
              <TableHeader>ADDRESS</TableHeader>
              <TableHeader>CREATED</TableHeader>
              <TableHeader>INSTRUCTOR</TableHeader>
              <TableHeader>SCHOOL</TableHeader>
            </tr>
          </thead>
          <tbody>
            {students.map((student) => (
              <tr key={student.id}>
                <TableCell>
                  <a href={`/students/${student.id}/overview`}>
                    {highlightText(
                      fullName(
                        student.first_name,
                        student.middle_name,
                        student.last_name
                      ),
                      query
                    )}
                  </a>
                </TableCell>
                <TableCell classNames="text-sm min-w-[350px]">
                  <StudentEnrollmentsCell student={student} targetBlank />
                </TableCell>
                <TableCell classNames="whitespace-nowrap">
                  {addressMultiLine({
                    address: student.address,
                    address_2: student.address_2,
                    city: student.city,
                    state: student.state,
                    country: student.country,
                    zip_code: student.zip_code,
                  })}
                </TableCell>
                <TableCell>
                  {dayjs(student.created_at).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell>
                  {fullName(
                    student.instructor?.first_name,
                    " ",
                    student.instructor?.last_name
                  )}
                </TableCell>
                <TableCell>
                  (#{student.school?.school_code}) {student.school?.church_name}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
