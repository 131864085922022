import React, { useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { suggestedSchoolsRequest } from "../../../../requests/students";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Table from "../../../UI/Table/Table";
import { addressMultiLine } from "../../../../utils/fullAddress";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function SuggestedSchools({
  setSuggestedSchools,
  setSuccess,
  selectedCourseId,
  zipCode,
  setSchool,
}) {
  const [schools, setSchools] = useState([]);

  const handleClick = async (school) => {
    if (!school.head_school_admin_id) {
      ToastNotification(
        "error",
        "No head school admin is assigned to this school."
      );
      return;
    }
    setSchool(school);
    setSuggestedSchools(false);
    setSuccess(true);
  };

  const fetchSchools = async () => {
    const res = await suggestedSchoolsRequest({
      courseId: selectedCourseId,
      zipCode: zipCode,
    });

    if (res.data) {
      setSchools(res.data);
    }
  };

  useEffect(() => {
    if (schools.length === 0) {
      fetchSchools();
    }
  }, []);

  return (
    <>
      <p className="text-[15px] text-center pb-10">
        The following schools have been found that match the students criteria.
      </p>
      <Table>
        <thead>
          <tr>
            <TableHeader classNames="text-base font-normal">School</TableHeader>
            <TableHeader classNames="text-base font-normal">Status</TableHeader>
            <TableHeader classNames="text-base font-normal">
              Church Address
            </TableHeader>
            <TableHeader classNames="text-base font-normal">
              School Address
            </TableHeader>
            <TableHeader classNames="text-base font-normal">
              Courses
            </TableHeader>
            <TableHeader />
          </tr>
        </thead>
        <tbody>
          {schools.length > 0 ? (
            schools.map((school) => (
              <tr key={school.id}>
                <TableCell>
                  (#{school.school_code}) {school.church_name}
                </TableCell>
                <TableCell>{school.active ? "Active" : "Inactive"}</TableCell>
                <TableCell>
                  {addressMultiLine({
                    address: school.school_address,
                    address2: school.school_address_2,
                    city: school.school_city,
                    state: school.school_state,
                    country: school.school_country,
                    zip_code: school.school_zip_code,
                  })}
                </TableCell>
                <TableCell>
                  {addressMultiLine({
                    address: school.church_address,
                    address2: school.church_address_2,
                    city: school.church_city,
                    state: school.church_state,
                    country: school.church_country,
                    zip_code: school.church_zip_code,
                  })}
                </TableCell>
                <TableCell classNames="max-w-[250px]">
                  {school.courses &&
                    school.courses.map((c) => (
                      <span
                        key={c.id}
                        className="border border-green-800 inline-block bg-green-100 text-green-900 text-xs px-2 py-1 rounded-full mr-1 mb-1"
                      >
                        {c.name}
                      </span>
                    ))}
                </TableCell>
                <TableCell>
                  <div className="text-center">
                    <Button
                      classNames="whitespace-nowrap"
                      variant="primary"
                      onClick={() => handleClick(school)}
                    >
                      Add Student
                    </Button>
                  </div>
                </TableCell>
              </tr>
            ))
          ) : (
            <tr>
              <TableCell colSpan={7} classNames="text-center">
                No schools match the criteria.
              </TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
