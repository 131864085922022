import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import Card from "../../../../UI/Card";
import { useMediaQuery } from "../../../../../utils/useMediaQuery";
import { useMobileNavigation } from "../../../../../contexts/MobileNavigationContext";
import { fullName } from "../../../../../utils/fullName";
import StudentSeminarNav from "./StudentSeminarNav";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { hoverLink } from "../../../../../utils/hoverLink";
import { checkPermission } from "../../../../../utils/checkPermission";

export default function StudentSeminar({ enrollment }) {
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const isMobile = useMediaQuery("md", "down");
  const { user, student } = useOutletContext();
  const seriesEnrollment = "seminarEnrollment";
  const params = useParams();
  const studentId = params.studentId;
  const { setThirdMobileNavigation } = useMobileNavigation();

  useEffect(() => {
    if (student && enrollment && isMobile) {
      setThirdMobileNavigation({
        title: enrollment.series.name,
        subtitle: fullName(student?.first_name, " ", student?.last_name),
        links: [
          {
            name: "Presentations",
            href: `/students/${enrollment?.student_id}/seminars/enrollments/${enrollment.id}/presentations`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Decisions",
            href: `/students/${enrollment?.student_id}/seminars/enrollments/${enrollment.id}/decisions`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Products",
            href: `/students/${enrollment?.student_id}/seminars/enrollments/${enrollment.id}/products`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Reminders",
            href: `/students/${enrollment?.student_id}/seminars/enrollments/${enrollment.id}/reminders`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Visits",
            href: `/students/${enrollment?.student_id}/seminars/enrollments/${enrollment.id}/visits`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Management",
            href: `/students/${enrollment?.student_id}/seminars/enrollments/${enrollment.id}/management`,
            show: true, // TODO: Add permission check
          },
        ],
      });
    }

    return () => {
      setThirdMobileNavigation(null);
    };
  }, [enrollment, student]);

  return isMobile ? (
    <>
      <div className="flex items-center gap-2">
        <Link to={`/students/${enrollment?.student_id}/seminars`}>
          <ArrowLeftIcon className="mr-1 w-7" />
        </Link>
        <span className="tracking-tight leading-none text-[24px] text-center flex-1">
          {enrollment?.series.name}
        </span>
        <span className="w-7" />
      </div>

      <div className="mt-4">
        <Outlet context={{ user, seriesEnrollment, enrollment }} />
      </div>
    </>
  ) : (
    <Card>
      <StudentSeminarNav enrollmentId={enrollment?.id} studentId={studentId} />

      <div>
        {isAdmin ? (
          <Link
            to={`/seminars/${enrollment?.series.id}/details`}
            className={twMerge("text-2xl", hoverLink)}
          >
            {enrollment?.series.name}
          </Link>
        ) : (
          <span className="text-2xl">{enrollment?.series.name}</span>
        )}
      </div>

      <div className="mt-6">
        <Outlet context={{ user, seriesEnrollment, enrollment }} />
      </div>
    </Card>
  );
}
