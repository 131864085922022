import React, { useState } from "react";
import { Link } from "react-router-dom";
import { addressMultiLine } from "../../../../utils/fullAddress";
import Pill from "../../../UI/Pill";
import Button from "../../../UI/Button";
import { fullName } from "../../../../utils/fullName";
import EditOnHover from "../../../UI/EditOnHover";
import { hoverLink } from "../../../../utils/hoverLink";
import { welcomeEmailUserRequest } from "../../../../requests/users";
import { ToastNotification } from "../../../UI/ToastNotification";
import { displayError } from "../../../../utils/displayError";

export default function HeadSchoolAdmin({ head_school_admin }) {
  if (!head_school_admin) return null;

  const [justSentWelcomeEmail, setJustSentWelcomeEmail] = useState(false);

  const handleResendWelcomeEmail = async (e) => {
    e.preventDefault();
    setJustSentWelcomeEmail(true);

    try {
      await welcomeEmailUserRequest({ userId: head_school_admin.id });
      ToastNotification("success", "Welcome email sent successfully.");
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <div className="mb-12">
      <h2 className="mb-4 text-2xl">Current Head School Admin</h2>
      <table className="w-full text-sm [&_td]:align-top [&_td]:border-b [&_td]:border-gray-200 [&_td]:py-2 [&_td]:pr-4">
        <tbody>
          <tr>
            <td>Name</td>
            <td className="[&_a]:py-0">
              <EditOnHover to={`/instructors/${head_school_admin?.id}/profile`}>
                <Link
                  to={`/instructors/${head_school_admin?.id}/overview`}
                  className={hoverLink}
                >
                  {fullName(
                    head_school_admin?.first_name,
                    " ",
                    head_school_admin?.last_name
                  )}
                </Link>
              </EditOnHover>
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <Pill
                color={head_school_admin?.inactive ? "disabled" : "success"}
              >
                {head_school_admin?.inactive ? "Inactive" : "Activated"}
              </Pill>
            </td>
          </tr>
          {head_school_admin?.email && (
            <tr>
              <td>Email</td>
              <td>{head_school_admin?.email}</td>
            </tr>
          )}
          {head_school_admin?.address && (
            <tr>
              <td>Address</td>
              <td>
                {addressMultiLine({
                  address: head_school_admin?.address,
                  address_2: head_school_admin?.address_2,
                  city: head_school_admin?.city,
                  state: head_school_admin?.state,
                  country:
                    head_school_admin?.country === "United States"
                      ? null
                      : head_school_admin?.country,
                  zip_code: head_school_admin?.zip_code,
                })}
              </td>
            </tr>
          )}
          {head_school_admin?.phone && (
            <tr>
              <td>Phone</td>
              <td>{head_school_admin?.phone}</td>
            </tr>
          )}
          <tr>
            <td></td>
            <td>
              <Button
                variant="primary-outline"
                classNames="rounded-full h-[28px] p-0 px-2"
                onClick={(e) => handleResendWelcomeEmail(e)}
                disabled={justSentWelcomeEmail}
              >
                {justSentWelcomeEmail
                  ? "Welcome Email Sent"
                  : "Resend Welcome Email"}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
