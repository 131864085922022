import React, { useState } from "react";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import Button from "../../UI/Button";
import { ToastNotification } from "../../UI/ToastNotification";
import { displayError } from "../../../utils/displayError";
import TableHeader from "../../UI/Table/TableHeader";
import Table from "../../UI/Table/Table";
import TableCell from "../../UI/Table/TableCell";
import PageLoader from "../../UI/PageLoader";
import { updateStudentImportRequest } from "../../../requests/studentImports";

const selectableColumns = [
  { id: "email", name: "E-mail address" },
  { id: "name", name: "Name" },
  { id: "first_name", name: "First name" },
  { id: "last_name", name: "Last name" },
  { id: "middle_name", name: "Middle name" },
  { id: "school_number", name: "School number" },
  { id: "instructor_name", name: "Instructor" },
  { id: "title", name: "Title" },
  { id: "gender", name: "Gender" },
  { id: "birthdate", name: "Birthdate" },
  { id: "denomination", name: "Denomination" },
  { id: "baptized", name: "Baptized" },
  { id: "married", name: "Married" },
  { id: "address", name: "Street address" },
  { id: "address_2", name: "Address 2" },
  { id: "city", name: "City" },
  { id: "state", name: "State/Province" },
  { id: "zip_code", name: "Zip/Postal code" },
  { id: "country", name: "Country" },
  { id: "phone", name: "Phone" },
  { id: "phone_2", name: "Phone 2" },
  { id: "phone_is_sms", name: "Phone is SMS" },
  { id: "phone_2_is_sms", name: "Phone 2 is SMS" },
  { id: "social_media", name: "Social media" },
  { id: "referral", name: "Advertising Method / Referrer" },
  { id: "series_name", name: "Course/Seminar" },
  { id: "registration_number", name: "Registration number" },
];

export default function CsvColumns({
  closeModal,
  studentImport,
  setStudentImport,
  setShowColumns,
  methods,
}) {
  const originalCsvRows = studentImport?.original_csv_array;
  const firstRow = originalCsvRows ? originalCsvRows[0] : null;
  const [loading, setLoading] = useState(false);
  const [removedRows, setRemovedRows] = useState(
    studentImport?.removed_rows || []
  );
  const [selectedColumns, setSelectedColumns] = useState(
    studentImport?.columns || []
  );
  const [includeFirstRow, setIncludeFirstRow] = useState(
    studentImport?.include_first_row
  );

  const handleRemovedRows = async (event, value) => {
    if (!event.target.checked) {
      setRemovedRows([...removedRows, value]);
    } else {
      setRemovedRows(removedRows.filter((item) => item !== value));
    }
  };

  const onSubmit = async () => {
    setLoading(true);

    if (selectedColumns.length === 0) {
      setLoading(false);
      ToastNotification("error", "No columns selected.");
      return;
    }

    if (removedRows.length === originalCsvRows.length) {
      setLoading(false);
      ToastNotification("error", "No rows selected.");
      return;
    }

    try {
      const res = await updateStudentImportRequest(studentImport.id, {
        student_import: {
          columns: selectedColumns,
          removed_rows: removedRows,
          include_first_row: Boolean(includeFirstRow),
          results: "",
        },
      });

      if (res.data) {
        ToastNotification("success", "Import preview updated.");
        setStudentImport(res.data.student_import);
        methods.setValue("csv_students", res.data.student_import.parsed_csv);
        methods.clearErrors();
        setShowColumns(false);
      }
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnChange = (event, index) => {
    const { value } = event.target;

    setSelectedColumns((prev) => {
      const newSelectedColumns = [...prev];

      // If the column is already selected, remove it
      if (value !== "") {
        const existingIndex = newSelectedColumns.indexOf(value);
        if (existingIndex !== -1) newSelectedColumns[existingIndex] = "";
      }

      newSelectedColumns[index] = value;
      return newSelectedColumns;
    });
  };

  const ColumnSelector = ({ index }) => (
    <div className="select-wrapper relative min-w-[200px]">
      <select
        id={`column-selector-${index}`}
        className="uppercase rounded w-full"
        defaultValue={
          selectableColumns.find(
            (option) => option.id === selectedColumns[index]
          )?.id || ""
        }
        onChange={(event) => handleColumnChange(event, index)}
      >
        <option value="">(Skip this column)</option>

        {selectableColumns.map((option) => (
          <option value={option.id} key={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );

  if (loading) return <PageLoader className="md:h-screen" />;

  return (
    <>
      <div className="h-[73px]">
        <div className="fixed w-full">
          <ActionMenuContainer
            light
            label="CSV students preview"
            clickToClose={closeModal}
          />
        </div>
      </div>

      <div className="bg-gray-100 relative z-[1] mb-24">
        <Table
          noOverflow
          className="whitespace-nowrap [&_tr>:first-child]:pl-12 [&_tr>:last-child]:pr-12 text-sm"
        >
          <thead className="uppercase [&_th]:sticky [&_th]:top-0 [&_th]:z-[999] [&_th]:bg-white [&_th]:py-4 [&_label]:text-sm">
            <tr>
              <TableHeader />
              <TableHeader />

              {firstRow?.map((column, index) => (
                <TableHeader key={index}>
                  <ColumnSelector index={index} />
                </TableHeader>
              ))}
            </tr>
          </thead>
          <tbody className="bg-gray-100">
            {originalCsvRows?.map((row, index) => (
              <tr key={index} className="[&_td]:px-4 [&_td]:h-[63px]">
                <TableCell>
                  {index === 0 && (
                    <div className="select-wrapper relative">
                      <select
                        onChange={(event) => {
                          setIncludeFirstRow(event.target.value === "1");
                        }}
                        defaultValue={includeFirstRow ? "1" : "0"}
                        className="rounded"
                      >
                        <option value="0">(Skip this row)</option>
                        <option value="1">Import this row</option>
                      </select>
                    </div>
                  )}
                </TableCell>

                <TableCell classNames="flex items-center gap-2">
                  <span className="w-8 text-right">{index + 1}.</span>
                  <input
                    type="checkbox"
                    id={`checkbox-${index}`}
                    defaultChecked={!removedRows.includes(index)}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleRemovedRows(event, index);
                    }}
                  />
                </TableCell>

                {row.map((value, index) => (
                  <TableCell key={index}>{value}</TableCell>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="flex justify-center pt-4 fixed bottom-8 w-full">
          <div className="text-center md:mr-4">
            <Button
              onClick={onSubmit}
              type="button"
              variant="primary"
              classNames="shadow-lg"
            >
              Save and next
            </Button>

            <Button
              onClick={() => setShowColumns(false)}
              type="button"
              variant="secondary"
              classNames="shadow-lg ml-2"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
