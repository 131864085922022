import React from "react";
import InputGroup from "../../../../../UI/Inputs/InputGroup";
import Input from "../../../../../UI/Inputs/Input";
import Select from "../../../../../UI/Inputs/Select/Select";
import SelectItem from "../../../../../UI/Inputs/Select/SelectItem";
import TextArea from "../../../../../UI/Inputs/TextArea";
import ToggleSwitch from "../../../../../UI/ToggleSwitch";
import DatePickerInput from "../../../../../UI/Inputs/DatePickerInput";

export default function CourseForm({ enrollment }) {
  return (
    <>
      <InputGroup classNames="justify-end">
        <ToggleSwitch
          id="active"
          label="Active Enrollment"
          labelPosition="left"
          defaultChecked={enrollment?.active}
          disabled={enrollment.readOnly}
        />
      </InputGroup>
      <InputGroup>
        <Input
          id="referral"
          placeholder="Referral Name"
          label="Referral"
          defaultValue={enrollment?.referral}
          disabled={enrollment.readOnly}
        />
        <Input
          id="diploma_delivered_visitor"
          placeholder="Visitor Name"
          label="Visitor"
          defaultValue={enrollment?.diploma_delivered_visitor}
          disabled={enrollment.readOnly}
        />
        <DatePickerInput
          id="diploma_ordered_date"
          label="Diploma Ordered"
          selected={enrollment?.diploma_ordered_date}
          disabled={enrollment.readOnly}
        />
        <DatePickerInput
          id="diploma_delivered_date"
          label="Diploma Delivered"
          selected={enrollment?.diploma_delivered_date}
          disabled={enrollment.readOnly}
        />
        <Select
          id="diploma_delivery_type"
          defaultValue={enrollment?.diploma_delivery_type || ""}
          label="Delivery Type"
          disabled={enrollment.readOnly}
        >
          <SelectItem value="" disabled selected>
            Select a delivery type
          </SelectItem>
          <SelectItem value="Post Office">Post Office</SelectItem>
          <SelectItem value="Personal Delivery">Personal Delivery</SelectItem>
          <SelectItem value="In-home">In-home</SelectItem>
        </Select>
      </InputGroup>
      <InputGroup>
        <TextArea
          id="notes"
          label="Student Enrollment Notes"
          defaultValue={enrollment?.notes}
          disabled={enrollment.readOnly}
        />
      </InputGroup>
    </>
  );
}
