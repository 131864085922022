import React, { useState } from "react";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import { useForm, FormProvider, Controller } from "react-hook-form";
import Button from "../../UI/Button";
import Modal from "../../UI/Modal";
import SearchUser from "../../UI/SearchUser";
import { bulkReassignStudentsRequest } from "../../../requests/students";
import { ToastNotification } from "../../UI/ToastNotification";
import { displayError } from "../../../utils/displayError";

export default function ReassignStudents({
  open,
  setOpen,
  students,
  onSuccess,
}) {
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const schoolId = data.school_and_instructor?.value?.split("#")[0];
      const instructorId = data.school_and_instructor?.value?.split("#")[1];

      await bulkReassignStudentsRequest({
        student_ids: students,
        instructor_id: instructorId,
        school_id: schoolId,
      });

      onSuccess();
      ToastNotification("success", "Students re-assigned successfully.");
    } catch (e) {
      displayError(e);
      setLoading(false);
    } finally {
      handleClose();
      setTimeout(() => setLoading(false), 1);
    }
  };

  return (
    <Modal open={open} setOpen={handleClose}>
      <div className="bg-neutral-600 relative">
        <ActionMenuContainer
          label="Reassign Students"
          clickToClose={handleClose}
        >
          {!loading ? (
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="flex flex-row items-center gap-4 max-w-[600px] m-auto mt-10 mb-[350px]"
              >
                <div className="relative flex-grow">
                  <Controller
                    name="school_and_instructor"
                    control={methods.control}
                    render={({ field }) => (
                      <SearchUser
                        field={field}
                        userType="Instructor"
                        reassign
                      />
                    )}
                  />
                </div>

                <Button
                  disabled={!methods.watch("school_and_instructor")?.value}
                  type="submit"
                  classNames="h-[38px] whitespace-nowrap"
                >
                  Reassign
                </Button>
              </form>
            </FormProvider>
          ) : (
            <div className="flex justify-center items-center h-[300px]">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-white"></div>
            </div>
          )}
        </ActionMenuContainer>
      </div>
    </Modal>
  );
}
