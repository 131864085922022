import React from "react";
import { twMerge } from "tailwind-merge";

export default function Table({ children, className, dark, noOverflow }) {
  return (
    <div
      className={twMerge(
        "mx-[-1.5rem] w-[calc(100%+3rem)]",
        !noOverflow && "overflow-x-auto",
        className
      )}
    >
      <table
        className={twMerge(
          "w-full table-auto rounded",
          "[&_th]:py-3 [&_td]:py-3 [&_th]:px-2 [&_th]:border-b [&_td]:px-2",
          "[&_tr>:first-child]:pl-6 [&_tr>:last-child]:pr-6",
          "[&_tbody_tr:last-of-type>td]:border-b-0",
          "[&_td]:border-black/5 [&_th]:border-black/5",
          "[&_tbody_tr:hover]:text-black [&_tr]:transition-colors duration-200",
          dark
            ? "[&_tbody_tr:hover]:bg-gray-500/50"
            : "[&_tbody_tr:hover]:bg-white",
          className
        )}
      >
        {children}
      </table>
    </div>
  );
}
