import React, { useState, useEffect } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";
import PageLoader from "../../../UI/PageLoader";
import { getAllCourseLessonsRequest } from "../../../../requests/courses";

export default function LessonsSummary({ course }) {
  const [lessons, setLessons] = useState();

  const getLessons = async () => {
    const res = await getAllCourseLessonsRequest({ courseId: course.id });
    setLessons(res.data);
  };

  useEffect(() => {
    getLessons();
  }, [course.id]);

  return lessons?.length ? (
    <Card classNames="overflow-y-scroll no-scrollbar">
      <CardHeader
        title="Lessons Summary"
        to={`/courses/${course.id}/lessons`}
      />
      <Table>
        <thead>
          <tr>
            <TableHeader>LESSON</TableHeader>
            <TableHeader>LESSON DELIVERED</TableHeader>
          </tr>
        </thead>
        <tbody>
          {lessons.map((lesson) => (
            <tr key={lesson.id}>
              <TableCell>{lesson.name}</TableCell>
              <TableCell>{`${lesson.lessons_delivered} delivered of ${course.enrollments_count} enrolled`}</TableCell>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  ) : !lessons ? (
    <Card>
      <PageLoader variant="primary" />
    </Card>
  ) : (
    <Card>
      <EmptyTable message="No lessons available." />
    </Card>
  );
}
