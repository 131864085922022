import React, { useState, useEffect } from "react";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../UI/Button";
import Modal from "../../UI/Modal";
import TextArea from "../../UI/Inputs/TextArea";
import { displayError } from "../../../utils/displayError";
import { bulkImportClaimedZipsRequest } from "../../../requests/schools";

export default function BulkImportZipCodes({ open, setOpen }) {
  const [showHelp, setShowHelp] = useState(false);
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState();
  const [showForm, setShowForm] = useState(true);

  const handleClose = () => {
    setOpen(false);
    methods.reset();
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const res = await bulkImportClaimedZipsRequest(data);
      setResults(res.data);
      setShowForm(false);
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) setShowForm(true);
  }, [open]);

  return (
    <Modal open={open} setOpen={handleClose}>
      <div className="bg-neutral-600 relative">
        <ActionMenuContainer
          label="Bulk Import of Claimed Zips"
          clickToClose={handleClose}
        >
          {loading ? (
            <div className="flex justify-center items-center h-[300px]">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-white"></div>
            </div>
          ) : (
            <>
              {results && (
                <div className="mb-4 border-b border-white/10 pb-4">
                  <h2 className="text-lg font-bold">Results</h2>
                  <p>{results.import_count} zips imported.</p>

                  {results.zip_success_lines.length > 0 && (
                    <>
                      <p className="text-green-400 mt-4">
                        The following lines were imported successfully:
                      </p>

                      <pre className="text-green-400 text-xs mt-2">
                        {results.zip_success_lines.join("\n")}
                      </pre>
                    </>
                  )}

                  {results.zip_error_lines.length > 0 && (
                    <>
                      <p className="text-red-400 mt-4">
                        The following lines contained invalid zips which were
                        not imported:
                      </p>

                      <pre className="text-red-400 text-xs mt-2">
                        {results.zip_error_lines.join("\n")}
                      </pre>
                    </>
                  )}
                </div>
              )}

              {showForm ? (
                <>
                  <p>
                    You can copy and paste the school ids and claimed zips to do
                    a bulk import of claimed zips for several schools.
                  </p>

                  <button
                    className="text-secondary hover:text-secondary-600 mt-4"
                    onClick={() => setShowHelp(!showHelp)}
                  >
                    {showHelp
                      ? "Hide Claimed Zip Format Help"
                      : "Show Claimed Zip Format Help"}
                  </button>

                  {showHelp && (
                    <div className="[&_p]:my-2 text-sm">
                      <p>
                        The school id must be first, followed by zips separated
                        by spaces. Each school id must be on it's own line.
                      </p>

                      <p>
                        Here is example of what would be pasted in to add some
                        claimed zips for schools #474 and #1003:
                      </p>

                      <p>
                        474 93062 93065
                        <br />
                        1003 97750 97751
                      </p>
                    </div>
                  )}

                  <FormProvider {...methods}>
                    <form
                      onSubmit={methods.handleSubmit(onSubmit)}
                      className="mt-4"
                    >
                      <TextArea
                        id="claimed_zips"
                        rows={10}
                        placeholder="Add zips to import here"
                        dark
                      />

                      <Button classNames="mt-2" variant="primary" type="submit">
                        Import Zips
                      </Button>
                    </form>
                  </FormProvider>
                </>
              ) : (
                <>
                  <p className="mb-4">
                    Click the button below to re-open the bulk import form.
                  </p>

                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowForm(true);
                      setResults(null);
                    }}
                  >
                    Show Bulk Import Form
                  </Button>
                </>
              )}
            </>
          )}
        </ActionMenuContainer>
      </div>
    </Modal>
  );
}
