import React, { useState, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

export const Tooltip = ({
  content,
  children,
  wrap = false,
  disabled = false,
}) => {
  if (disabled) return children;

  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);
  const timeoutRef = useRef(null);

  const showTooltip = () => {
    clearTimeout(timeoutRef.current);
    setIsVisible(true);
  };

  const hideTooltip = () => {
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 50);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      className="relative inline-block"
      ref={tooltipRef}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisible && (
        <div className="absolute z-20 w-max max-w-xs bottom-full left-1/2 transform -translate-x-1/2 mb-2">
          <div
            className={twMerge(
              "px-3 py-2 text-sm text-white bg-gray-900 rounded-lg shadow-md tooltip dark:bg-gray-700 text-center",
              !wrap && "whitespace-nowrap"
            )}
          >
            {content}
          </div>
        </div>
      )}
    </div>
  );
};
