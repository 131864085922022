import React from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";

export default function ProductsSummary({ products, course }) {
  return (
    <Card classNames="overflow-y-scroll no-scrollbar mb-4">
      <CardHeader
        title="Products Summary"
        to={`/courses/${course.id}/products`}
      />

      {products.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>PRODUCTS</TableHeader>
              <TableHeader>SOLD/GIVEN TO</TableHeader>
            </tr>
          </thead>
          <tbody>
            {products.map((item) => (
              <tr key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>#</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="Work in progress." />
      )}
    </Card>
  );
}
