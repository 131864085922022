import React from "react";
import { twMerge } from "tailwind-merge";

export default function TableCell({ children, classNames, colSpan, onClick }) {
  return (
    <td
      className={twMerge("border-b py-3", classNames)}
      colSpan={colSpan}
      onClick={onClick}
    >
      {children}
    </td>
  );
}
