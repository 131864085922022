import React from "react";
import NavGroup from "../../UI/NavGroup/NavGroup";
import NavGroupItem from "../../UI/NavGroup/NavGroupItem";

export default function CourseNav({ course }) {
  const courseNavigation = [
    {
      label: "Overview",
      url: "overview",
    },
    {
      label: "Lessons",
      url: "lessons",
      count: course?.episodes_count,
    },
    {
      label: "Decisions",
      url: "decisions",
      count: course?.decision_options?.length,
    },
    {
      label: "Products",
      url: "products",
      count: course?.products?.length,
    },
    {
      label: "Schools",
      url: "schools",
      count: course?.schools_count,
    },
  ];

  return (
    <NavGroup>
      {courseNavigation.map((item, index) => (
        <NavGroupItem
          key={item.label}
          label={item.label}
          url={`/courses/${course?.id}/${item.url}`}
          isLast={index === courseNavigation.length - 1}
          isFirst={index === 0}
          count={item.count}
        />
      ))}
    </NavGroup>
  );
}
