import React, { Fragment, useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Link, Outlet, useLocation } from "react-router-dom";
import DesktopNavigation from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";
import { Bars3Icon } from "@heroicons/react/24/outline";
import ActionMenu from "../../ActionMenu/ActionMenu";
import SearchInput from "./SearchInput";
import ProfileMenu from "./ProfileMenu";
import PageActions from "./PageActions";
import Footer from "./Footer";
import { Transition } from "@headlessui/react";
import {
  dashboardIcon,
  studentsIcon,
  instructorsIcon,
  coursesIcon,
  seminarsIcon,
  schoolsIcon,
  reportIcon,
  settingsIcon,
  trainingIcon,
} from "./NavIcons";
import Breadcrumb from "../../Breadcrumb";
import { parseJwt, storedToken } from "../../../utils/auth";
import { checkPermission } from "../../../utils/checkPermission";
import { PageActionsProvider } from "../../../contexts/PageActionsContext";
import { MobileNavigationProvider } from "../../../contexts/MobileNavigationContext";
import { getUserRequest } from "../../../requests/users";

export default function MainLayout() {
  const user = parseJwt(storedToken)?.user;
  const [userData, setUserData] = useState(null);
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [actionMenuModal, setActionMenuModal] = useState({
    open: false,
    defaultRender: null,
  });

  const getUserData = async () => {
    const res = await getUserRequest({ userId: user.id });
    setUserData(res.data);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const baseNavigation = [
    {
      name: "Dashboard",
      href: "/",
      icon: dashboardIcon,
      show: checkPermission(["school_admin", "conference_admin", "instructor"]),
    },
    {
      name: "Students",
      href: "/students",
      icon: studentsIcon,
      show: checkPermission(["school_admin", "conference_admin", "instructor"]),
    },
    {
      name: "Instructors",
      href: "/instructors",
      icon: instructorsIcon,
      show: checkPermission(["school_admin", "conference_admin"]),
    },
    {
      name: "Courses",
      href: "/courses",
      icon: coursesIcon,
      show: checkPermission(["school_admin", "conference_admin"]),
    },
    {
      name: "Schools",
      href: "/schools",
      icon: schoolsIcon,
      show: checkPermission(["school_admin", "conference_admin"]),
    },
    {
      name: "Seminars",
      href: "/seminars",
      icon: seminarsIcon,
      show: checkPermission(["school_admin", "conference_admin"]),
    },
    {
      name: "Reports",
      href: "/reports",
      icon: reportIcon,
      show: true,
    },
    {
      name: "Settings",
      href: "/account/settings",
      icon: settingsIcon,
      show: false,
    },
    {
      name: "Training",
      href: "https://discoverbibleschool.tawk.help/",
      icon: trainingIcon,
      show: true,
      blank: true,
    },
  ];

  const userNavigation = [{ name: "Settings", href: "/account/settings" }];

  return (
    <PageActionsProvider>
      <MobileNavigationProvider>
        <div
          className="min-h-screen"
          style={{
            backgroundImage: userData?.background_image_url
              ? `url(${userData.background_image_url})`
              : `url(/bkgd_7-3.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        >
          <header className="justify-center relative border-b border-gray-200/50 backdrop-blur-3xl pt-10 pb-2 hidden lg:flex z-10">
            <div className="container flex items-end">
              <div className="flex-1" />
              <div className="flex-1 justify-center">
                <DesktopNavigation
                  baseNavigation={baseNavigation.filter((nav) => nav.show)}
                />
              </div>

              <div className="flex-1 items-center flex justify-end pb-1 pr-4">
                <ProfileMenu
                  userNavigation={userNavigation}
                  userData={userData}
                />
              </div>
            </div>
          </header>

          <Transition
            show={mobileMenuOpen}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="h-full fixed inset-0 z-[9990] lg:hidden bg-[#2C2C2C] text-white">
              <MobileNavigation
                baseNavigation={baseNavigation}
                userNavigation={userNavigation}
                setMobileMenuOpen={setMobileMenuOpen}
              />
            </div>
          </Transition>

          <ActionMenu
            actionMenuModal={actionMenuModal}
            setActionMenuModal={setActionMenuModal}
            user={user}
          />

          <div className="bg-background container relative mx-auto flex items-center justify-between px-4 py-5 lg:justify-end lg:bg-transparent">
            <div className="lg:hidden">
              <div onClick={() => setMobileMenuOpen(true)}>
                <span />
                {!mobileMenuOpen && (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </div>
            </div>

            {location.pathname !== "/" && (
              <div className="md:hidden block absolute left-1/2 -translate-x-1/2 z-50">
                <Link to="/" className="block">
                  <img src="/logo.png" width={65} className="m-auto" />
                </Link>
              </div>
            )}

            <SearchInput admin={user?.admin} />

            <div className="hidden lg:flex items-center justify-center w-full">
              <div className="flex justify-center hidden md:block">
                <PageActions />
              </div>
            </div>
          </div>

          <main className="container mx-auto min-h-screen px-4 md:pb-0 pb-20">
            <Breadcrumb />
            <Outlet
              context={{
                actionMenuModal,
                setActionMenuModal,
                user,
                userData,
                reloadUserData: getUserData,
              }}
            />
            <ToastContainer />
          </main>
          <Footer />
        </div>
      </MobileNavigationProvider>
    </PageActionsProvider>
  );
}
