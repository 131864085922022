import React from "react";
import { defer, Link } from "react-router-dom";
import RouteLoader from "../UI/RouteLoader";
import Students from "../Students/Students";
import StudentOverview from "../Students/Student/StudentOverview/StudentOverview";
import Student from "../Students/Student/Student";
import StudentProfile from "../Students/Student/StudentProfile/StudentProfile";
import StudentCourses from "../Students/Student/StudentCoursesAndSeminars/StudentCourses";
import StudentSeminars from "../Students/Student/StudentCoursesAndSeminars/StudentSeminars";
import StudentDecisions from "../Students/Student/StudentDecisions/StudentDecisions";
import StudentProducts from "../Students/Student/StudentProducts/StudentProducts";
import StudentNotes from "../Students/Student/StudentNotes/StudentNotes";
import StudentNew from "../Students/Student/StudentNew";
import { getStudentRequest } from "../../requests/students";
import StudentCourse from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/StudentCourse";
import Management from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/Management/Management";
import Lessons from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/Lessons/Lessons";
import Decisions from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/Decisions/Decisions";
import Questions from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/Questions/Questions";
import Products from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/Products/Products";
import Reminders from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/Reminders/Reminders";
import { getEnrollmentRequest } from "../../requests/enrollments";
import Visits from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/Visits/Visits";
import StudentSeminar from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/StudentSeminar";
import Presentations from "../Students/Student/StudentCoursesAndSeminars/StudentEnrollment/Presentations/Presentations";

export default [
  {
    path: "/students",
    element: <Students />,
  },
  {
    path: "/students/new",
    element: <StudentNew />,
  },
  {
    path: "/students/:studentId",
    element: (
      <RouteLoader>
        {(loader) => <Student student={loader?.data} />}
      </RouteLoader>
    ),
    id: "student",
    loader: ({ params }) => {
      return defer({
        data: getStudentRequest({ studentId: params.studentId }),
      });
    },
    handle: {
      actionMenu: "students",
      crumb: ({ data }) => (
        <span>
          <Link to="/students">STUDENTS</Link> /{" "}
          <Link to={`/students/${data?.id}/overview`}>
            {data?.last_name?.toUpperCase()}
          </Link>
        </span>
      ),
    },
    children: [
      {
        path: "/students/:studentId/overview",
        element: <StudentOverview />,
        handle: {
          actionMenu: "students",
        },
      },
      {
        path: "/students/:studentId/profile",
        element: <StudentProfile />,
        handle: {
          actionMenu: "students",
          crumb: () => <span className="pl-1">/ PROFILE</span>,
        },
      },
      {
        path: "/students/:studentId/courses",
        element: <StudentCourses />,
        handle: {
          actionMenu: "students",
          crumb: () => <span className="pl-1">/ COURSES</span>,
        },
      },
      {
        path: "/students/:studentId/courses/enrollments/:enrollmentId",
        element: (
          <RouteLoader>
            {(loader) => <StudentCourse enrollment={loader?.data} />}
          </RouteLoader>
        ),
        id: "courseEnrollment",
        loader: ({ params }) => {
          return defer({
            data: getEnrollmentRequest({ enrollmentId: params.enrollmentId }),
          });
        },
        handle: {
          actionMenu: "students",
          crumb: () => <span className="pl-1">/ COURSE NAME</span>,
        },
        children: [
          {
            path: "/students/:studentId/courses/enrollments/:enrollmentId/management",
            element: <Management />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/courses/enrollments/:enrollmentId/lessons",
            element: <Lessons />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/courses/enrollments/:enrollmentId/decisions",
            element: <Decisions />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/courses/enrollments/:enrollmentId/questions",
            element: <Questions />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/courses/enrollments/:enrollmentId/products",
            element: <Products />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/courses/enrollments/:enrollmentId/reminders",
            element: <Reminders />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/courses/enrollments/:enrollmentId/visits",
            element: <Visits />,
            handle: {
              actionMenu: "students",
            },
          },
        ],
      },
      {
        path: "/students/:studentId/seminars",
        element: <StudentSeminars />,
        handle: {
          actionMenu: "students",
          crumb: () => <span className="pl-1">/ SEMINARS</span>,
        },
      },
      {
        path: "/students/:studentId/seminars/enrollments/:enrollmentId",
        element: (
          <RouteLoader>
            {(loader) => <StudentSeminar enrollment={loader?.data} />}
          </RouteLoader>
        ),
        id: "seminarEnrollment",
        loader: ({ params }) => {
          return defer({
            data: getEnrollmentRequest({ enrollmentId: params.enrollmentId }),
          });
        },
        handle: {
          actionMenu: "students",
          crumb: () => <span className="pl-1">/ SEMINARS</span>,
        },
        children: [
          {
            path: "/students/:studentId/seminars/enrollments/:enrollmentId/management",
            element: <Management />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/seminars/enrollments/:enrollmentId/presentations",
            element: <Presentations />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/seminars/enrollments/:enrollmentId/decisions",
            element: <Decisions />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/seminars/enrollments/:enrollmentId/visits",
            element: <Visits />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/seminars/enrollments/:enrollmentId/reminders",
            element: <Reminders />,
            handle: {
              actionMenu: "students",
            },
          },
          {
            path: "/students/:studentId/seminars/enrollments/:enrollmentId/products",
            element: <Products />,
            handle: {
              actionMenu: "students",
            },
          },
        ],
      },
      {
        path: "/students/:studentId/decisions",
        element: <StudentDecisions />,
        handle: {
          actionMenu: "students",
          crumb: () => <span className="pl-1">/ DECISIONS</span>,
        },
      },
      {
        path: "/students/:studentId/products",
        element: <StudentProducts />,
        handle: {
          actionMenu: "students",
          crumb: () => <span className="pl-1">/ PRODUCTS</span>,
        },
      },
      {
        path: "/students/:studentId/notes",
        element: <StudentNotes />,
        handle: {
          actionMenu: "students",
          crumb: () => <span className="pl-1">/ NOTES</span>,
        },
      },
    ],
  },
];
