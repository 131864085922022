import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import Button from "../../UI/Button";
import { ToastNotification } from "../../UI/ToastNotification";
import { displayError } from "../../../utils/displayError";
import TableHeader from "../../UI/Table/TableHeader";
import Table from "../../UI/Table/Table";
import PageLoader from "../../UI/PageLoader";
import { updateStudentImportRequest } from "../../../requests/studentImports";
import {
  getAllSchoolsSimpleList,
  getAllSchoolInstructors,
} from "../../../requests/schools";
import { getLookupsByType } from "../../../requests/lookups";
import { getAllCountries } from "../../../requests/countries";
import { getAllStates } from "../../../requests/states";
import CsvAutoAssign from "./CsvAutoAssign";
import CsvReadOnlyRow from "./CsvReadOnlyRow";
import CsvStudentsRow from "./CsvStudentsRow";
import CsvColumns from "./CsvColumns";
import CsvResults from "./CsvResults";

export default function CsvStudents({
  closeModal,
  setRefreshStudents,
  studentImport,
  setStudentImport,
}) {
  const importResults = studentImport?.results;
  const csvStudents = studentImport?.parsed_csv;
  const columns = studentImport?.columns;
  const methods = useForm({
    defaultValues: {
      csv_students: importResults,
      school_id: studentImport?.school_id,
      import_series_id: studentImport?.series_id,
    },
  });
  const { fields, append } = useFieldArray({
    control: methods.control,
    name: "csv_students",
  });
  const appendCalled = useRef(false);
  const [schools, setSchools] = useState();
  const [countries, setCountries] = useState([]);
  const [genders, setGenders] = useState([]);
  const [states, setStates] = useState([]);
  const [showResults, setShowResults] = useState(studentImport?.imported);
  const [showColumns, setShowColumns] = useState(!studentImport?.imported);
  const selectedSchoolId = methods.watch("school_id");
  const selectedSeriesId = methods.watch("import_series_id");
  const [selectedRow, setSelectedRow] = useState(null);
  const [focusedCell, setFocusedCell] = useState(null);
  const [loading, setLoading] = useState(true);
  const [instructors, setInstructors] = useState();
  const [schoolSearchFocused, setSchoolSearchFocused] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    setFocusedCell(null);
    setSelectedRow(null);

    const dataCsv = data.csv_students;

    if (dataCsv.length === 0) {
      ToastNotification("error", "No students selected.");
      return;
    }

    try {
      const res = await updateStudentImportRequest(studentImport.id, {
        student_import: {
          results: dataCsv,
          school_id: data.school_id,
          series_id: data.import_series_id,
          include_first_row: studentImport.include_first_row,
          columns: studentImport.columns,
          removed_rows: studentImport.removed_rows,
        },
      });

      if (res.data) {
        ToastNotification("success", "Import preview updated.");
        setStudentImport(res.data.student_import);
        methods.setValue("csv_students", res.data.student_import.results);
        setShowResults(true);
      }
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  const getSchools = async () => {
    const res = await getAllSchoolsSimpleList();
    if (res.data) {
      setSchools(res.data.schools);
    }
  };

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getStates = async () => {
    try {
      const res = await getAllStates();

      if (res.data) {
        setStates(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getGenders = async () => {
    try {
      const res = await getLookupsByType({ lookupType: 1 });

      if (res.data) {
        setGenders(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getInstructors = async (schoolId) => {
    try {
      const res = await getAllSchoolInstructors({ school_id: schoolId });
      if (res.data) setInstructors(res.data);
    } catch (e) {
      displayError(e);
    }
  };

  const hasColumn = (column) => columns.includes(column);

  // This is the place where we read the CSV file uplodaded by the user and try to
  // match the columns assigning to the existing `availableColumns` array.
  useEffect(() => {
    if (!appendCalled.current && !importResults) {
      csvStudents.map((student) =>
        append({
          index: "",
          school_id: student.school_id,
          instructor_id: student.instructor_id,
          series_id: student.series_id,
          series_name: student.series_name,
          first_name: student.first_name?.trim(),
          middle_name: student.middle_name?.trim(),
          last_name: student.last_name?.trim(),
          school_name: student.school_name?.trim(),
          school_code: student.school_code?.trim(),
          instructor_name: student.instructor_name?.trim(),
          title: student.title?.trim(),
          address: student.address?.trim(), // "Street Address"
          address_2: student.address_2?.trim(),
          city: student.city?.trim(),
          state: student.state?.trim(), // "State/Province"
          zip_code: student.zip_code?.trim(), // "Zip/Postal Code"
          country: student.country?.trim(),
          gender: student.gender?.trim(),
          married: student.married,
          birthdate: student.birthdate?.trim(),
          denomination: student.denomination?.trim(),
          baptized: student.baptized,
          phone: student.phone?.trim(),
          phone_is_sms: student.phone_is_sms,
          phone_2: student.phone_2?.trim(),
          phone_2_is_sms: student.phone_2_is_sms,
          email: student.email?.trim(), // "E-mail Address"
          social_media: student.social_media?.trim(),
          referral: student.referral?.trim(), // "Referrals"
          enrollments: student.enrollments?.trim(),
          registration_number: student.registration_number?.trim(),
        })
      );
      appendCalled.current = true;
    }
  }, []);

  useEffect(() => {
    getSchools();
    getCountries();
    getStates();
    getGenders();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const el = document.getElementById("csv-preview-form");
    if (el?.value) el.value = null;
  });

  useEffect(() => {
    if (selectedSchoolId) getInstructors(selectedSchoolId);
  }, [selectedSchoolId]);

  if (loading) return <PageLoader className="md:h-screen" />;

  return showColumns ? (
    <CsvColumns
      studentImport={studentImport}
      setShowColumns={setShowColumns}
      closeModal={closeModal}
      setStudentImport={setStudentImport}
      methods={methods}
    />
  ) : showResults ? (
    <CsvResults
      studentImport={studentImport}
      setShowResults={setShowResults}
      onImportExecuted={() => {
        closeModal();
        setRefreshStudents(true);
      }}
      closeModal={closeModal}
    />
  ) : (
    <FormProvider {...methods}>
      <div className={schoolSearchFocused ? "h-[530px]" : "h-[230px]"}>
        <div className="fixed w-full z-1">
          <ActionMenuContainer
            light
            label="CSV students editor"
            clickToClose={closeModal}
          />

          <CsvAutoAssign
            methods={methods}
            schools={schools}
            onFocus={() => setSchoolSearchFocused(true)}
            onBlur={() => setSchoolSearchFocused(false)}
          />
        </div>
      </div>

      <form
        className="bg-gray-100 relative z-[1] mb-24"
        onSubmit={methods.handleSubmit(onSubmit)}
        id="csv-preview-form"
      >
        <Table
          noOverflow
          className="whitespace-nowrap [&_tr>:first-child]:pl-12 [&_tr>:last-child]:pr-12 text-sm"
        >
          <thead className="uppercase [&_th]:sticky [&_th]:px-4 [&_th]:top-0 [&_th]:z-[999] [&_th]:bg-white [&_th]:py-4 [&_label]:text-sm">
            <tr>
              <TableHeader />

              <TableHeader classNames="font-bold min-w-[150px]">
                Title
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[150px]">
                First Name
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[150px]">
                Middle Name
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[150px]">
                Last Name
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[180px]">
                School
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[215px]">
                Instructor
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[200px]">
                Course/Seminar
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                Registration Number
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[160px]">
                Referrals
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[150px]">
                Address
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                Address 2
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                City
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[190px]">
                State/Province
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[110px]">
                Zip/Postal
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[190px]">
                Country
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[180px]">
                Gender
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px] text-center">
                Married
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[130px]">
                Birthdate
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[130px]">
                Denomination
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px] text-center">
                Baptized
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[140px]">
                Phone
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px] text-center">
                SMS
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[140px]">
                Phone 2
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[120px] text-center">
                Phone 2 SMS
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[190px]">
                Email
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[160px]">
                Social Media
              </TableHeader>
            </tr>
          </thead>
          <tbody className="bg-gray-100">
            {fields.map((student, index) =>
              selectedRow !== index ? (
                <CsvReadOnlyRow
                  key={index}
                  student={student}
                  index={index}
                  onClick={(cell) => {
                    setSelectedRow(index);
                    setFocusedCell(cell);
                  }}
                  methods={methods}
                  importSchoolInstructors={instructors}
                  selectedSchool={schools?.find(
                    (s) => s.id === selectedSchoolId
                  )}
                  hasColumn={hasColumn}
                />
              ) : (
                <CsvStudentsRow
                  key={index}
                  index={index}
                  student={student}
                  schools={schools}
                  methods={methods}
                  countries={countries}
                  states={states}
                  genders={genders}
                  hasColumn={hasColumn}
                  importSchoolId={selectedSchoolId}
                  importSeriesId={selectedSeriesId}
                  focusedCell={focusedCell}
                  importSchoolInstructors={instructors}
                />
              )
            )}
          </tbody>
        </Table>

        <div className="flex justify-center pt-4 fixed bottom-8 w-full">
          <div className="text-center md:mr-4">
            <Button
              onClick={() => setShowColumns(true)}
              type="button"
              variant="secondary"
              classNames="shadow-lg"
            >
              Back
            </Button>

            <Button type="submit" variant="primary" classNames="shadow-lg ml-2">
              Save and preview
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
