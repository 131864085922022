import React from "react";
export default function Footer() {
  return (
    <footer className="hidden md:block w-full">
      <div className="container mx-auto flex justify-end py-10">
        <div className="flex flex-col justify-end p-2 text-right text-xs font-normal leading-tight text-black">
          <span className="block">
            Visit our{" "}
            <a
              className="underline"
              href="https://discoverbibleschool.tawk.help/"
            >
              help guide
            </a>{" "}
            to find more ways use the Voice of Prophecy SRS System in your
            ministry.
          </span>
          <span className="block">
            &copy; 2002-{new Date().getFullYear()} Voice of Prophecy
          </span>
        </div>
        <img
          src="/logo-footer.png"
          alt="logo"
          className="h-16 w-48 self-end p-2"
        />
      </div>
    </footer>
  );
}
