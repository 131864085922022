import React, { useState } from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
import TableCell from "../../../../../UI/Table/TableCell";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Modal from "../../../../../UI/Modal";
import ConfirmationDialog from "../../../../../UI/ConfirmationDialog";
import { updateEnrollmentRequest } from "../../../../../../requests/enrollments";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { hoverLink } from "../../../../../../utils/hoverLink";
import VisitForm from "./VisitForm";
import { displayError } from "../../../../../../utils/displayError";
import Notes from "./Notes/Notes";
import Decisions from "./Decisions/Decisions";
import DropdownMenu from "../../../../../UI/DropdownMenu";
import { PlusIcon } from "@heroicons/react/20/solid";

export default function VisitRow({ visit, enrollment, decisionOptions }) {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addingDecision, setAddingDecision] = useState(false);
  const [addingNotes, setAddingNotes] = useState(false);
  const [editing, setEditing] = useState(false);

  const dropdownItems = [
    {
      label: "Add Note",
      icon: <PlusIcon />,
      onClick: () => setAddingNotes(true),
    },
    {
      label: "Add Decision",
      icon: <PlusIcon />,
      onClick: () => setAddingDecision(true),
    },
    {
      label: "Delete",
      icon: <TrashIcon />,
      onClick: () => setDeleteModalOpen(true),
    },
  ];

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        visits_attributes: [
          {
            id: visit.id,
            date: data.date,
            _destroy: data === "destroy" ? true : false,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        const toastMessage =
          data === "destroy"
            ? "Visit deleted successfully."
            : "Visit updated successfully.";
        ToastNotification("success", toastMessage);
        setEditing(false);
        navigate(".", { replace: true });
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <tr>
      <TableCell>
        {editing ? (
          <VisitForm
            visit={visit}
            enrollment={enrollment}
            handleCancelAdd={() => setEditing(false)}
            onSubmit={onSubmit}
          />
        ) : (
          <span
            onClick={() => (enrollment.readOnly ? null : setEditing(true))}
            className={hoverLink}
          >
            {dayjs(visit.date).format("MM/DD/YYYY")}
          </span>
        )}
      </TableCell>
      <TableCell>
        <Notes
          visit={visit}
          enrollment={enrollment}
          addingNotes={addingNotes}
          setAddingNotes={setAddingNotes}
          disabled={enrollment.readOnly}
        />
      </TableCell>
      <TableCell>
        <Decisions
          addingDecision={addingDecision}
          decisionOptions={decisionOptions}
          setAddingDecision={setAddingDecision}
          visit={visit}
          enrollment={enrollment}
          addingNotes={addingNotes}
          disabled={enrollment.readOnly}
        />
      </TableCell>
      <TableCell classNames="text-center">
        {!enrollment.readOnly && <DropdownMenu items={dropdownItems} />}
      </TableCell>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => onSubmit("destroy")}
        />
      </Modal>
    </tr>
  );
}
