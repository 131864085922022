import React from "react";
import Card from "../../../UI/Card";
import TableScrollable from "../../../UI/Table/TableScrollable";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";
import PageLoader from "../../../UI/PageLoader";
import { Link } from "react-router-dom";
import { hoverLink } from "../../../../utils/hoverLink";
import { fullName } from "../../../../utils/fullName";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default function StudentsTable({ instructor, needingFollowup }) {
  dayjs.extend(relativeTime);

  return needingFollowup ? (
    <Card classNames="overflow-y-scroll no-scrollbar min-h-[736px]">
      <CardHeader
        title="Students needing follow-up"
        to={`/instructors/${instructor.id}/students`}
      />

      {needingFollowup?.length ? (
        <TableScrollable height={623}>
          <thead>
            <tr>
              <TableHeader classNames="w-4/12">STUDENT NAME</TableHeader>
              <TableHeader classNames="w-4/12">COURSE</TableHeader>
              <TableHeader classNames="w-4/12">LAST FOLLOW UP</TableHeader>
            </tr>
          </thead>
          <tbody className="h-[623px]">
            {needingFollowup.map((followup) => (
              <tr key={followup.id}>
                <TableCell classNames="w-4/12">
                  <Link
                    className={hoverLink}
                    to={`/students/${followup.student.id}/overview`}
                  >
                    {fullName(
                      followup.student.first_name,
                      " ",
                      followup.student.last_name
                    )}
                  </Link>
                </TableCell>
                <TableCell classNames="w-4/12">
                  <Link
                    className={hoverLink}
                    to={`/students/${followup.student.id}/courses/enrollments/${followup.id}/lessons`}
                  >
                    {followup.series.name}
                  </Link>
                </TableCell>
                <TableCell classNames="w-4/12">
                  {dayjs().to(dayjs(followup.cached_latest_followup_date))}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </TableScrollable>
      ) : (
        <EmptyTable message="No students needing followup." />
      )}
    </Card>
  ) : (
    <Card classNames="min-h-[360px]">
      <PageLoader variant="primary" />
    </Card>
  );
}
