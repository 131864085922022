import React from "react";
import ActionMenuHeader from "./ActionMenuHeader";
import { twMerge } from "tailwind-merge";

export default function ActionMenuContainer({
  children,
  label,
  handleGoingBack,
  clickToClose,
  light,
}) {
  return (
    <div
      className={twMerge(
        "lg:min-w-[870px]",
        // We need to set select options text "black" due to white bg in Window OS.
        !light &&
          "text-white font-thin [&_select_option]:text-black [&_select_optgroup]:text-black"
      )}
    >
      <ActionMenuHeader
        label={label}
        goBack={handleGoingBack}
        clickToClose={clickToClose}
        light={light}
      />
      <div className="p-6">{children}</div>
    </div>
  );
}
