import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Login from "./Auth/Login/Login";
import AuthLayout from "./Layouts/Auth/AuthLayout";
import PrivateRoute from "./Routes/PrivateRoute";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import MainLayout from "./Layouts/Main/MainLayout";
import Dashboard from "./Dashboard/Dashboard";
import studentRoutes from "./Routes/studentRoutes";
import instructorRoutes from "./Routes/instructorRoutes";
import coursesRoutes from "./Routes/coursesRoutes";
import seminarsRoutes from "./Routes/seminarsRoutes";
import schoolsRoutes from "./Routes/schoolsRoutes";
import accountRoutes from "./Routes/accountRoutes";
import versionRoutes from "./Routes/versionRoutes";
import axiosClient from "../utils/axios";
import { removeToken } from "../utils/auth";
import { ToastNotification } from "./UI/ToastNotification";
import reportRoutes from "./Routes/reportRoutes";

export default function App() {
  axiosClient.interceptors.response.use(
    (res) => res,
    (error) => {
      const isTokenExpired = error.response?.data === "Signature has expired";
      const isTokenRevoked = error.response?.data === "revoked token";
      const unprocessableEntity = 422;
      const unauthorized = 403;
      const unauthenticated = 401;

      if (error.response?.status === unauthorized) {
        ToastNotification(
          "error",
          "You are not authorized to access this page"
        );
      }

      if (
        error.response?.status === unauthenticated ||
        isTokenExpired ||
        isTokenRevoked ||
        error.response?.data === "nil user"
      ) {
        removeToken();
        const currentPath = window.location.pathname + window.location.search;
        localStorage.setItem("auth_redirect", currentPath);
        window.location.href = "/login";
        return Promise.reject(error);
      }

      if (
        window.location.href !== `${window.origin}/login` &&
        error.response?.status !== unprocessableEntity
      ) {
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  const router = createBrowserRouter([
    {
      path: "*",
      element: <Navigate to="/login" replace />,
    },
    {
      element: <AuthLayout />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
      ],
    },
    {
      element: (
        <PrivateRoute>
          <MainLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
      ].concat(
        studentRoutes,
        instructorRoutes,
        coursesRoutes,
        seminarsRoutes,
        schoolsRoutes,
        reportRoutes,
        accountRoutes,
        versionRoutes
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}
