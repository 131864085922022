import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import QuickFind from "../../../UI/QuickFind";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import { Pagination, usePagination } from "../../../UI/Pagination";
import SeminarButton from "../SeminarButton";
import {
  getAllSeminarAssignStudentsRequest,
  getAllSeminarStudentsRequest,
  getRecentlyAssignedStudentsRequest,
} from "../../../../requests/seminars";
import { displayError } from "../../../../utils/displayError";
import { highlightText } from "../../../../utils/highlightText";
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { fullName } from "../../../../utils/fullName";
import { hoverLink } from "../../../../utils/hoverLink";
import Modal from "../../../UI/Modal";
import { addressMultiLine } from "../../../../utils/fullAddress";
import PageLoader from "../../../UI/PageLoader";
import NewStudent from "./NewStudent";
import AddRegistrationNumber from "./AddRegistrationNumber";
import AssignStudents from "../SeminarDetails/AssignStudents";
import EditOnHover from "../../../UI/EditOnHover";

export default function SeminarStudents() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const sortBy = searchParams.get("sortBy") || "student_last_name";
  const orderBy = searchParams.get("orderBy") || "asc";
  const page = searchParams.get("page") || "1";
  const params = useParams();
  const seminarId = params.seminarId;
  const pagination = usePagination();
  const [enrollments, setEnrollments] = useState();
  const { user, seminar } = useOutletContext();
  const [newStudentModal, setNewStudentModal] = useState({
    open: false,
    seminar: null,
  });
  const [reloadStudents, setReloadStudents] = useState(false);
  const [assignStudentsModal, setAssignStudentsModal] = useState({
    open: false,
  });
  const [assignStudents, setAssignStudents] = useState([]);
  const [recentlyAssigned, setRecentlyAssigned] = useState([]);
  const [loading, setLoading] = useState(false);

  const addressHighlight =
    sortBy === "student_city"
      ? "city"
      : sortBy === "student_state"
        ? "state"
        : sortBy === "student_zip_code"
          ? "zip_code"
          : sortBy === "student_country"
            ? "country"
            : undefined;

  const getRecentlyAssigned = async () => {
    try {
      const res = await getRecentlyAssignedStudentsRequest({
        seminarId: seminar.id,
      });

      if (res.data) {
        setRecentlyAssigned(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getAllSeminarStudents = async () => {
    setLoading(true);

    const queryParams = {
      page: page,
      per_page: pagination.recordsPerPage,
      q: { sorts: `${sortBy} ${orderBy}` },
    };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      const attributes = [
        "student_full_name",
        "registration_number",
        "student_country",
        "student_state",
        "student_city",
        "student_zip_code",
        "student_address",
        "student_address_2",
        "student_school_church_name",
      ];
      queryParams.q[`${attributes.join("_or_")}_i_cont_all`] = query
        .trim()
        .split(" ");
    }

    try {
      const res = await getAllSeminarStudentsRequest(seminarId, {
        params: queryParams,
      });

      if (res.data) {
        setEnrollments(res.data.enrollments);
        pagination.setData(res.data.pagination);
      }
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  const getAllSeminarAssignStudents = async () => {
    try {
      const res = await getAllSeminarAssignStudentsRequest({
        seminarId: seminar.id,
      });

      if (res.data) {
        setAssignStudents(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  useEffect(() => {
    getAllSeminarAssignStudents();
    getRecentlyAssigned();
  }, [
    assignStudentsModal,
    reloadStudents,
    params,
    searchParams,
    pagination.recordsPerPage,
  ]);

  useEffect(() => {
    if (newStudentModal.open === false || reloadStudents) {
      getAllSeminarStudents();
    }
  }, [
    newStudentModal,
    reloadStudents,
    params,
    searchParams,
    pagination.recordsPerPage,
  ]);

  if (!enrollments)
    return (
      <Card classNames="p-6 lg:mt-10">
        <PageLoader />
      </Card>
    );

  return (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            <img
              src="/add-new.svg"
              className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
              width={30}
              height={30}
              alt="Add New"
              onClick={() =>
                setNewStudentModal({ open: true, seminar: seminar })
              }
            />
            <h1 className="text-xl font-bold text-primary">STUDENTS</h1>
          </div>
          <div className="absolute left-1/2 inline-flex -translate-x-1/2">
            <SeminarButton
              onClick={() => {
                if (assignStudents.length > 0) {
                  setAssignStudentsModal({ open: true });
                }
              }}
            >
              Assign Students ({assignStudents.length})
            </SeminarButton>
          </div>
          <div className="flex">
            <QuickFind onSubmit={onSubmit} value={query} minLength={1} />
          </div>
        </div>
        <Pagination
          pagination={pagination}
          loading={loading && !enrollments?.length}
          empty={Boolean(!enrollments?.length)}
        >
          <Table>
            <thead>
              <tr>
                <TableHeader
                  isSortable
                  isMultiSort
                  multiSortOptions={[
                    { name: "First", value: "student_first_name" },
                    { name: "Last", value: "student_last_name" },
                  ]}
                >
                  STUDENT
                </TableHeader>
                <TableHeader isSortable sortBy="registration_number">
                  REG. NO.
                </TableHeader>
                <TableHeader isSortable sortBy="cached_latest_received_date">
                  LATEST PRESENTATION
                </TableHeader>
                <TableHeader
                  isSortable
                  isMultiSort
                  multiSortOptions={[
                    { name: "City", value: "student_city" },
                    { name: "State", value: "student_state" },
                    { name: "Zip", value: "student_zip_code" },
                    { name: "Country", value: "student_country" },
                  ]}
                >
                  ADDRESS
                </TableHeader>
                <TableHeader isSortable sortBy="created_at">
                  CREATED
                </TableHeader>
                <TableHeader isSortable sortBy="student_instructor_first_name">
                  INSTRUCTOR
                </TableHeader>
                <TableHeader isSortable sortBy="student_school_school_name">
                  SCHOOL
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {enrollments.map((enrollment) => (
                <tr
                  key={enrollment.id}
                  className={loading ? "opacity-25" : undefined}
                >
                  <TableCell>
                    <EditOnHover
                      to={`/students/${enrollment.student.id}/profile`}
                    >
                      <Link
                        className={`${hoverLink}`}
                        to={`/students/${enrollment.student.id}/overview`}
                      >
                        {highlightText(
                          fullName(
                            enrollment.student.first_name,
                            enrollment.student.middle_name,
                            enrollment.student.last_name
                          ),
                          query
                        )}
                      </Link>
                    </EditOnHover>
                  </TableCell>
                  <TableCell>
                    <AddRegistrationNumber
                      enrollment={enrollment}
                      reloadStudents={getAllSeminarStudents}
                      query={query}
                    />
                  </TableCell>
                  <TableCell>
                    {enrollment.last_completed_episode_name}
                  </TableCell>
                  <TableCell>
                    {addressMultiLine(
                      {
                        address: enrollment.student.address,
                        address_2: enrollment.student.address_2,
                        city: enrollment.student.city,
                        state: enrollment.student.state,
                        zip_code: enrollment.student.zip_code,
                        country:
                          enrollment.student.country === "United States"
                            ? null
                            : enrollment.student.country,
                      },
                      { highlight: addressHighlight, query }
                    )}
                  </TableCell>
                  <TableCell>
                    {Intl.DateTimeFormat("en-US", {
                      timeZone: "UTC",
                    }).format(new Date(enrollment.created_at))}
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/instructors/${enrollment.student.instructor_id}/overview`}
                    >
                      {enrollment.student.instructor_name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/schools/${enrollment.student.school.id}/overview`}
                    >
                      {highlightText(
                        enrollment.student.school.church_name,
                        query
                      )}
                    </Link>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Pagination>
      </Card>
      <Modal
        open={newStudentModal.open}
        setOpen={() => setNewStudentModal({ open: false, seminar: null })}
      >
        <NewStudent
          defaultSeminar={newStudentModal.seminar}
          closeModal={() => setNewStudentModal({ open: false, seminar: null })}
          reloadStudents={() => setReloadStudents(true)}
          user={user}
        />
      </Modal>
      <Modal
        open={assignStudentsModal.open}
        setOpen={() => setAssignStudentsModal({ open: false })}
        fullWidth
      >
        <AssignStudents
          closeModal={() => {
            setAssignStudentsModal({ open: false });
            setReloadStudents(true);
          }}
          seminar={seminar}
          assignStudents={assignStudents}
          recentlyAssigned={recentlyAssigned}
          setRecentlyAssigned={setRecentlyAssigned}
          getRecentlyAssigned={getRecentlyAssigned}
        />
      </Modal>
    </div>
  );
}
