import React, { useEffect, useState } from "react";
import ActionMenuContainer from "../../../ActionMenu/ActionMenuContainer";
import { FormProvider, useForm } from "react-hook-form";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import Checkbox from "../../../UI/Inputs/Checkbox";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import { createStudentRequest } from "../../../../requests/students";
import { ToastNotification } from "../../../UI/ToastNotification";
import { getAllSchoolInstructors } from "../../../../requests/schools";
import { checkPermission } from "../../../../utils/checkPermission";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { useNavigate } from "react-router-dom";
import { displayError } from "../../../../utils/displayError";
import { createEnrollmentRequest } from "../../../../requests/enrollments";
import { getAllCountries } from "../../../../requests/countries";
import { getStatesByCountry } from "../../../../requests/states";
import { getAvailableAdvertisingMethods } from "../../../../requests/availableAdvertisingMethods";
import SeminarSelect from "./SeminarSelect";
import PageLoader from "../../../UI/PageLoader";

export default function NewStudent({
  defaultSeminar,
  closeModal,
  reloadStudents,
  takingAttendance,
  user,
  registrationNumber,
  presentation,
}) {
  const methods = useForm();
  const [instructors, setInstructors] = useState([]);
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const [seminar, setSeminar] = useState(defaultSeminar);
  const seminarId = seminar?.id;
  const navigate = useNavigate();
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [advertisingMethods, setAdvertisingMethods] = useState([]);
  const selectedCountry = methods.watch("country");
  const [loading, setLoading] = useState(false);

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching countries.");
    }
  };

  const getStates = async () => {
    try {
      const countryId = countries.find(
        (country) => country.name === selectedCountry
      )?.id;
      const res = await getStatesByCountry({
        countryId: countryId || countries[0]?.id,
      });

      if (res.data) {
        setStates(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching states.");
    }
  };

  const getInstructors = async () => {
    try {
      const res = await getAllSchoolInstructors({
        school_id: seminar.host_school?.id,
      });

      if (res.data) {
        setInstructors(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const fetchAdvertisingMethods = () => {
    getAvailableAdvertisingMethods().then((res) => {
      setAdvertisingMethods(res.data.map((tag) => tag.name));
    });
  };

  const assignSeminarPresets = () => {
    if (seminar) {
      methods.setValue("school_id", seminar.host_school?.church_name);

      try {
        const today = new Date().toISOString().split("T")[0];

        const presentationForCurrentDay = seminar.presentations.find(
          (presentation) => presentation.date === today
        );

        methods.setValue("presentation", presentationForCurrentDay?.id);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Error setting presentation for current day");
      }
    }
  };

  useEffect(() => {
    getCountries();
    fetchAdvertisingMethods();
  }, []);

  useEffect(() => {
    if (countries?.length) getStates();
  }, [selectedCountry, countries]);

  useEffect(() => {
    if (isAdmin && seminar) {
      getInstructors();
    }
  }, [seminar]);

  useEffect(() => {
    if (seminar && !loading) {
      assignSeminarPresets();
    }
  }, [seminar, loading]);

  const onSubmit = async (data) => {
    setLoading(true);
    const action = window.event.submitter.textContent;
    const schoolId = seminar.host_school?.id;

    try {
      const res = await createStudentRequest({
        ...data,
        school_id: schoolId,
        series_id: seminarId,
      });

      if (res.data) {
        let enrollmentRequestData = {
          student_id: res.data.id,
          series_id: seminarId,
          school_id: schoolId,
          registration_number: data.registration_number,
          notify_instructor_by_email: data.notify_instructor_by_email,
        };

        if (data.presentation) {
          enrollmentRequestData.episode_deliveries_attributes = [
            {
              date_completed: new Date(),
              episode_id: data.presentation,
              grading_instructor_id: user.id,
              delivery_type: "Personal Delivery",
            },
          ];
        }

        await createEnrollmentRequest(enrollmentRequestData);

        ToastNotification(
          "success",
          "Student created and enrolled successfully."
        );

        if (action === "Add & New Student") {
          methods.reset();
          reloadStudents();
        } else if (action === "Add & View Student") {
          navigate(`/students/${res.data.id}/overview`);
        } else {
          closeModal();
          navigate(`/seminars/${seminarId}/students`);
        }
      }
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SeminarSelect onChange={setSeminar} loaderSeminar={seminar} />

          <div className="md:flex-row md:mb-6 flex justify-between flex-col">
            <div className="md:w-2/6 md:mr-8">
              <Input
                id="registration_number"
                label="Registration Number"
                defaultValue={registrationNumber}
                dark
              />
            </div>
            <div className="md:w-4/6 flex items-center">
              <Input
                id="interest_card"
                type="file"
                inputClassNames="border-none"
                label="Interest Card Scan:"
              />
            </div>
          </div>

          <div className="md:flex-row md:mb-6 flex justify-between flex-col">
            <div className="md:w-2/4 md:mr-4">
              <Input id="first_name" label="First Name" dark isRequired />
            </div>
            <div className="md:w-2/4">
              <Input id="last_name" label="Last Name" dark isRequired />
            </div>
          </div>

          <div className="md:flex-row flex justify-between flex-col">
            <div className="md:w-2/4 md:mr-4">
              <InputGroup>
                <Select
                  isRequired
                  classNames="w-full bg-transparent"
                  label="Instructor"
                  disabled={!isAdmin}
                  id="instructor_id"
                  defaultValue={
                    instructors.find((instructor) => instructor.id === user?.id)
                      ?.id
                  }
                >
                  <SelectItem value="" disabled>
                    Select an instructor
                  </SelectItem>
                  {instructors.map((instructor) => (
                    <SelectItem
                      key={instructor.id}
                      value={instructor.id}
                      selected={
                        seminar?.host_school?.head_school_admin_id ===
                        instructor.id
                      }
                    >
                      {instructor.first_name} {instructor.last_name}
                    </SelectItem>
                  ))}
                </Select>
              </InputGroup>
            </div>
            <div className="md:w-2/4 md:mr-4">
              <Input
                id="school_id"
                label="School"
                dark
                disabled
                defaultValue={seminar?.host_school?.church_name}
              />
            </div>
            <div className="md:w-2/4">
              <Select
                id="share_with"
                classNames="w-full bg-transparent"
                label="Share with"
                defaultValue=""
              >
                <SelectItem value="" disabled>
                  Select share with
                </SelectItem>
              </Select>
            </div>
          </div>

          <div className="md:w-2/4 md:mb-6">
            <Select
              id="presentation"
              classNames="w-full bg-transparent"
              label="Presentation"
              defaultValue={presentation?.id}
            >
              <SelectItem value="" selected>
                No attendance
              </SelectItem>
              {seminar?.presentations?.map((presentation) => (
                <SelectItem key={presentation.id} value={presentation.id}>
                  {presentation.name}
                </SelectItem>
              ))}
            </Select>
          </div>

          <div className="md:w-2/4 md:mb-6">
            <Select
              id="country"
              classNames="w-full bg-transparent"
              isRequired
              defaultValue={countries ? "United States" : null}
              placeholder={countries ? "-- Select a country --" : "Loading..."}
              disabled={!countries}
              dark
              label="Country"
            >
              {countries?.map((country) => (
                <SelectItem
                  key={country.id}
                  value={country.name}
                  selected={country.name === "United States"}
                >
                  {country.name}
                </SelectItem>
              ))}
            </Select>
          </div>

          <InputGroup col>
            <Input
              id="address"
              placeholder="Address 1"
              label="Address"
              dark
              isRequired
            />
            <Input id="address_2" placeholder="Address 2" dark />
          </InputGroup>

          <InputGroup>
            <Input id="city" placeholder="City" dark isRequired />

            <Select
              id="state"
              classNames="w-full"
              isRequired
              placeholder={
                states
                  ? "-- Select a state --"
                  : !selectedCountry
                    ? "Select a country first"
                    : "Loading..."
              }
              disabled={!states}
              dark
            >
              {states?.map((state) => (
                <SelectItem key={state.id} value={state.abbreviation}>
                  {state.name}
                </SelectItem>
              ))}
            </Select>

            <Input id="zip_code" placeholder="Zip/Postal" dark isRequired />
          </InputGroup>

          <Select
            id="include_in_mailing_list"
            classNames="w-full bg-transparent"
            label="Include in mailing list"
          >
            <SelectItem value="if_address_valid">If Address Valid</SelectItem>
            <SelectItem value="always">Always</SelectItem>
            <SelectItem value="never">Never</SelectItem>
          </Select>

          <InputGroup classNames="my-6">
            <Input id="email" placeholder="Email" dark />

            <Input id="social_media" placeholder="Social Media" dark />
          </InputGroup>

          <div className="md:flex">
            <InputGroup classNames="md:w-1/2 flex items-center">
              <Input
                classNames="md:w-2/3"
                id="phone"
                placeholder="Phone"
                label="Phone"
                dark
              />
              <Checkbox classNames="mt-6" id="phone_is_sms" label="SMS" dark />
            </InputGroup>

            <InputGroup classNames="md:w-1/2 flex items-center">
              <Input
                classNames="md:w-2/3"
                id="phone_2"
                placeholder="Phone 2"
                label="Phone 2"
                dark
              />
              <Checkbox
                classNames="mt-6"
                id="phone_2_is_sms"
                label="SMS"
                dark
              />
            </InputGroup>
          </div>

          <div className="md:flex gap-4 items-center">
            <DatePickerInput
              classNames="mb-2"
              id="birthdate"
              label="Birthdate"
              dark
            />

            <Input
              classNames="md:w-1/3 mb-2"
              id="denomination"
              placeholder="Denomination"
              label="Denomination"
              dark
            />

            <MultiSelect
              className="md:w-1/3"
              label="Advertising Method"
              id="advertising_method_list"
              placeholder="Advertising Method"
              options={advertisingMethods}
              defaultSelected={[]}
              dark
            />
          </div>

          <div className="my-6">
            <Checkbox
              id="notify_instructor_by_email"
              label="Notify the instructor about their new student"
              labelColor="#ebebeb"
              labelFont="font-small"
              defaultChecked
            />
          </div>

          <div className="md:flex-row md:flex justify-center mb-[150px]">
            <div className="text-center md:mr-4 mb-4">
              <Button type="submit" variant="primary" classNames="w-full">
                Add Student
              </Button>
            </div>
            <div className="text-center md:mr-4 mb-4">
              <Button type="submit" variant="primary" classNames="w-full">
                Add & New Student
              </Button>
            </div>
            <div className="text-center md:mr-4">
              <Button type="submit" variant="primary" classNames="w-full">
                Add & View Student
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    );
  };

  return (
    <div className="bg-neutral-600 relative">
      {loading ? (
        <div className="flex justify-center items-center h-full px-4 min-w-[300px]">
          <PageLoader />
        </div>
      ) : takingAttendance ? (
        renderForm()
      ) : (
        <ActionMenuContainer label="Add New Student" clickToClose={closeModal}>
          {renderForm()}
        </ActionMenuContainer>
      )}
    </div>
  );
}
