import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenuContainer";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Button from "../../../UI/Button";
import InputGroup from "../../../UI/Inputs/InputGroup";
import {
  getAllCourseDecisionsRequest,
  getAllCourseLessonsRequest,
} from "../../../../requests/courses";
import { displayError } from "../../../../utils/displayError";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import {
  getEnrollmentRequest,
  updateEnrollmentRequest,
} from "../../../../requests/enrollments";
import { ToastNotification } from "../../../UI/ToastNotification";
import StudentSelect from "../StudentSelect";
import SeriesSelect from "../SeriesSelect";

export default function NewStudentDecision({ setRender, user, loaderStudent }) {
  const [student, setStudent] = useState(loaderStudent);
  const methods = useForm();
  const [lessons, setLessons] = useState([]);
  const [decisionOptions, setDecisionOptions] = useState([]);
  const [enrollment, setEnrollment] = useState(null);
  const selectedSeries = methods.watch("series_id");
  const selectedEpisodeId = methods.watch("episode_id");

  const selectedEnrollmentId = [
    ...(student?.course_enrollments || []),
    ...(student?.seminar_enrollments || []),
  ].find((enrollment) => enrollment?.series_id === selectedSeries)?.id;

  const episodeDelivery = enrollment?.episode_deliveries.find(
    (delivery) => delivery.episode_id === selectedEpisodeId
  );

  const selectedDecisionOptions = decisionOptions.filter((option) =>
    episodeDelivery?.decisions.some(
      (decision) => decision.decision_option_id === option.id
    )
  );

  const handleStudentChange = (student) => {
    methods.reset();
    setLessons([]);
    setDecisionOptions([]);
    setStudent(student);
  };

  const getLessons = async () => {
    try {
      const res = await getAllCourseLessonsRequest({
        courseId: selectedSeries,
      });

      if (res.data) {
        setLessons(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({
      courseId: selectedSeries,
    });

    if (res.data) {
      setDecisionOptions(res.data);
    }
  };

  const getEnrollment = async () => {
    if (!selectedEnrollmentId) return;

    try {
      const res = await getEnrollmentRequest({
        enrollmentId: selectedEnrollmentId,
      });

      if (res.data) {
        setEnrollment(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      const decisions = decisionOptions.map((decision_option) => ({
        id: episodeDelivery?.decisions.find(
          (decision) => decision.decision_option_id === decision_option.id
        )?.id,
        decision_option_id: decision_option.id,
        cached_enrollment_id: selectedEnrollmentId,
        _destroy: !data.decisions.find(
          (decision) => decision.id === decision_option.id
        ),
      }));

      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            grading_instructor_id: user.id,
            decisions_attributes: decisions,
            episode_id: selectedEpisodeId,
          },
        ],
        enrollmentId: selectedEnrollmentId,
      });

      if (res.data) {
        ToastNotification("success", "Decisions updated successfully.");
        setRender(null);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    if (student && selectedSeries) {
      getLessons();
      getAllCourseDecisions();
      getEnrollment();
    }
  }, [student, selectedSeries]);

  return (
    <ActionMenuContainer
      label="Record a decision"
      handleGoingBack={() => setRender(null)}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="min-h-[350px]"
        >
          <StudentSelect
            onChange={handleStudentChange}
            loaderStudent={student}
          />
          {student && (
            <>
              <InputGroup>
                <SeriesSelect student={student} methods={methods} />
                <Select
                  id="episode_id"
                  classNames="w-full bg-transparent"
                  label={
                    !enrollment?.series?.type
                      ? "Lesson / Presentation"
                      : enrollment?.series?.type === "Course"
                        ? "Lesson"
                        : "Presentation"
                  }
                  isRequired
                >
                  <SelectItem value="">
                    {selectedSeries
                      ? "Select"
                      : "Select a course or seminar first"}
                  </SelectItem>
                  {lessons?.map((lesson) => (
                    <SelectItem key={lesson.id} value={lesson.id}>
                      {lesson.name}
                    </SelectItem>
                  ))}
                </Select>
              </InputGroup>
              {decisionOptions.length > 0 && (
                <InputGroup>
                  <MultiSelect
                    id="decisions"
                    label="Decisions"
                    placeholder="Add Decision"
                    options={decisionOptions}
                    defaultSelected={selectedDecisionOptions}
                    dark
                  />
                </InputGroup>
              )}

              <div className="text-center">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={decisionOptions.length === 0}
                >
                  Save Decision
                </Button>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </ActionMenuContainer>
  );
}
