import Button from "../../UI/Button";
import {
  ChevronLeftIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { averyLabelsRequest } from "../../../requests/students";
import PageLoader from "../../UI/PageLoader";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function DownloadAveryLabels({
  setPdfPreview,
  fullFilterValues,
  totalStudents,
}) {
  const [students, setStudents] = useState();
  let data = [];

  const docDefinition = () => {
    data = [];
    let count = 0;
    let row = [];

    students.forEach((student, index) => {
      const line = {
        text: `${student.first_name} ${student.last_name} \n ${student.address ? student.address : "N/A"} \n ${student.city}, ${student.state} ${student.zip_code} \n ${student.country ? student.country : "N/A"}`,
        fontSize: 10,
        margin: [0, 0, 0, 21],
      };

      row.push(line);
      count++;

      if (count % 3 === 0 || index === students.length - 1) {
        data.push(row);
        row = [];
      }

      // Add a page break after every 30 students (10 rows)
      if ((index + 1) % 30 === 0 && index !== students.length - 1) {
        data.push({ text: "", pageBreak: "after" });
      }
    });

    let content = data.map((item) => {
      // If the item is a page break, return it as is
      if (item.pageBreak) {
        return item;
      }

      // Otherwise, return the item as a row in a table
      return {
        table: {
          widths: ["*", "*", "*"], // Set the width of each column
          body: [item],
        },
        layout: "noBorders", // Remove the borders from the table
      };
    });

    return {
      info: {
        title: "students.pdf",
      },
      pageSize: {
        width: 612, //points
        height: 792, //points
      },
      pageMargins: [25, 45, 0, 45],
      content: content,
    };
  };

  const fetchAveryLabelsStudents = async () => {
    const res = await averyLabelsRequest({
      q: fullFilterValues,
    });

    if (res.data) {
      setStudents(res.data);
    }
  };

  useEffect(() => {
    if (students?.length && data.length === 0) {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition());
      pdfDocGenerator.getDataUrl((dataUrl) => {
        document.querySelector("#pdfViewer").src = dataUrl;
      });
    }
  }, [students]);

  useEffect(() => {
    fetchAveryLabelsStudents();
  }, []);

  return (
    <>
      <div className="flex mb-10 items-center">
        <b className="mr-2">Step Two:</b>
        <p>
          Please review the formatted addresses and click the download button.
        </p>
        <Button
          variant="primary-outline"
          classNames="ml-auto mr-4"
          icon
          onClick={() => setPdfPreview(false)}
        >
          <ChevronLeftIcon className="w-5 mr-1" />
          Return To List
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            pdfMake.createPdf(docDefinition()).download("students.pdf")
          }
        >
          Download Avery 5160 Labels
        </Button>
      </div>
      <div>
        {students ? (
          <>
            {totalStudents > 2000 && (
              <p className="mb-4 text-orange-500 flex items-center">
                <ExclamationCircleIcon className="w-8 mr-1" />
                We are only showing the first 2000 students. Please adjust your
                filters to narrow down the results.
              </p>
            )}
            <iframe id="pdfViewer" className="w-full h-[700px]"></iframe>
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <PageLoader />
          </div>
        )}
      </div>
    </>
  );
}
