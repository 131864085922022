import React from "react";
import BackLink from "../../../../Mobile/BackLink";
import { useOutletContext } from "react-router-dom";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";

export default function StudentMobileProducts({
  deliveries,
  seriesTypePlural,
}) {
  if (!deliveries) return <PageLoader className="h-[60vh]" />;

  const { student } = useOutletContext();

  return deliveries.length ? (
    <>
      <BackLink path={`/students/${student?.id}/overview`} label="Products" />

      <div className="pt-4">
        {deliveries.map((delivery) => (
          <CardLink
            key={delivery.id}
            to={`/students/${student?.id}/${seriesTypePlural(
              delivery.series_type
            )}/enrollments/${delivery.enrollment_id}/products`}
            className="mb-4 last:mb-0 block"
            cardClassName="px-4 rounded-2xl flex items-center justify-between gap-4"
            dark
          >
            <span>
              <span className="text-lg block">{delivery.product.name}</span>
              <span className="text-sm block">{delivery.context}</span>
            </span>
            {delivery.delivered_at && (
              <span className="text-xs whitespace-nowrap">
                {Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  timeZone: "UTC",
                }).format(new Date(delivery.delivered_at))}
              </span>
            )}
          </CardLink>
        ))}
      </div>
    </>
  ) : (
    <div className="text-center py-10 text-gray-500">
      No products found for this student.
    </div>
  );
}
