import React, { useEffect, useState } from "react";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import { Pagination, usePagination } from "../../../UI/Pagination";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import { useFilterValues } from "../../../UI/Filter/Filter";
import PageLoader from "../../../UI/PageLoader";
import Card from "../../../UI/Card";
import QuickFind from "../../../UI/QuickFind";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import { getAllUserStudentsRequest } from "../../../../requests/users";
import { fullName } from "../../../../utils/fullName";
import Modal from "../../../UI/Modal";
import PrintTicket from "../../../Students/Student/PrintTicket";
import { hoverLink } from "../../../../utils/hoverLink";
import dayjs from "dayjs";
import StudentEnrollmentsCell from "../../../UI/StudentEnrollmentsCell";
import { checkPermission } from "../../../../utils/checkPermission";
import InstructorStudentsMobile from "./Mobile/InstructorStudentsMobile";
import { addressMultiLine } from "../../../../utils/fullAddress";
import EditOnHover from "../../../UI/EditOnHover";
import { highlightText } from "../../../../utils/highlightText";

export default function InstructorStudents() {
  const isMobile = useMediaQuery("md", "down");
  const pagination = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const query = searchParams.get("query") || "";
  const sortBy = searchParams.get("sortBy") || "created_at";
  const orderBy = searchParams.get("orderBy") || "desc";
  const filterValues = useFilterValues();
  const { setActionMenuModal } = useOutletContext();
  const { instructor } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [printTicketModal, setPrintTicketModal] = useState({
    open: false,
    student: null,
  });

  const [students, setStudents] = useState();

  const getStudents = async () => {
    setLoading(true);

    const params = {
      q: {
        ...filterValues,
        sorts: `${sortBy} ${orderBy}`,
        enrollments_active_true: true,
      },
      page: page,
      per_page: pagination.recordsPerPage,
    };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      const attributes = [
        "full_name",
        "enrollments_registration_number",
        "school_church_name",
        "school_school_code",
        "country",
        "state",
        "city",
        "zip_code",
        "address",
        "address_2",
      ];
      params.q[`${attributes.join("_or_")}_i_cont_all`] = query
        .trim()
        .split(" ");
    }

    const res = await getAllUserStudentsRequest({
      userId: instructor.id,
      params,
    });

    setStudents(res.data.students);
    pagination.setData(res.data.pagination);
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  useEffect(() => {
    getStudents();
  }, [searchParams, pagination.recordsPerPage]);

  if (!students && !isMobile)
    return (
      <Card classNames="p-6 lg:mt-10">
        <PageLoader />
      </Card>
    );

  return isMobile ? (
    <InstructorStudentsMobile students={students} />
  ) : (
    <>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["school_admin", "conference_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() =>
                  setActionMenuModal({
                    open: true,
                    defaultRender: "NewStudent",
                  })
                }
              />
            )}

            <h1 className="text-xl font-bold text-primary">STUDENTS</h1>
          </div>
          <div className="flex">
            <QuickFind onSubmit={onSubmit} />
          </div>
        </div>
        <Pagination
          pagination={pagination}
          loading={loading && !students?.length}
          empty={Boolean(!students?.length)}
        >
          <Table>
            <thead>
              <tr>
                <TableHeader
                  isSortable
                  isMultiSort
                  multiSortOptions={[
                    { name: "First", value: "first_name" },
                    { name: "Last", value: "last_name" },
                  ]}
                >
                  STUDENT
                </TableHeader>
                <TableHeader
                  isSortable
                  isMultiSort
                  multiSortOptions={[
                    {
                      name: "Latest Lesson Sent",
                      value: "cached_latest_sent_date",
                    },
                    {
                      name: "Registration number",
                      value: "cached_registration_numbers",
                    },
                  ]}
                >
                  ENROLLMENTS
                </TableHeader>
                <TableHeader
                  isSortable
                  isMultiSort
                  multiSortOptions={[
                    { name: "City", value: "city" },
                    { name: "State", value: "state" },
                    { name: "Zip", value: "zip_code" },
                    { name: "Country", value: "country" },
                  ]}
                >
                  ADDRESS
                </TableHeader>
                <TableHeader isSortable sortBy="created_at">
                  CREATED
                </TableHeader>
                <TableHeader isSortable sortBy="school_church_name">
                  SCHOOL
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {students?.map((student) => (
                <tr
                  key={student.id}
                  className={loading ? "opacity-25" : undefined}
                >
                  <TableCell>
                    <EditOnHover to={`/students/${student.id}/profile`}>
                      <Link
                        className={`${hoverLink}`}
                        to={`/students/${student.id}/overview`}
                      >
                        {highlightText(
                          fullName(
                            student.first_name,
                            student.middle_name,
                            student.last_name
                          ),
                          query
                        )}
                      </Link>
                    </EditOnHover>
                  </TableCell>
                  <TableCell classNames="text-sm min-w-[350px]">
                    <StudentEnrollmentsCell
                      student={student}
                      searchQuery={query}
                    />
                  </TableCell>
                  <TableCell classNames="whitespace-nowrap">
                    {addressMultiLine(
                      {
                        address: student.address,
                        address_2: student.address_2,
                        city: student.city,
                        state: student.state,
                        country:
                          student.country !== "United States"
                            ? student.country
                            : undefined,
                        zip_code: student.zip_code,
                      },
                      { highlight: sortBy, query: query }
                    )}
                  </TableCell>
                  <TableCell>
                    {dayjs(student.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/schools/${student.school?.id}/overview`}
                    >
                      {highlightText(student.school?.church_name, query)}
                    </Link>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Pagination>
      </Card>

      <Modal
        open={printTicketModal.open}
        setOpen={() => setPrintTicketModal({ open: false, student: null })}
      >
        <PrintTicket
          student={printTicketModal.student}
          closeModal={() => setPrintTicketModal({ open: false, student: null })}
        />
      </Modal>
    </>
  );
}
