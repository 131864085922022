import React, { useCallback, useState, useEffect } from "react";
import SearchAsync from "../../UI/SearchAsync";
import Select from "../../UI/Inputs/Select/Select";
import SelectItem from "../../UI/Inputs/Select/SelectItem";
import { displayError } from "../../../utils/displayError";
import {
  getAllSchoolCourses,
  getAllSchoolSeminars,
} from "../../../requests/schools";

const SearchSchoolAsync = ({
  field,
  onChange,
  placeholder,
  onFocus,
  onBlur,
  schools,
}) => {
  const loadOptions = async (inputValue) => {
    const schoolOptions = schools.map((school) => ({
      label: `(#${school.school_code}) ${school.church_name}`,
      value: school.id,
    }));

    if (inputValue) {
      return schoolOptions.filter((school) =>
        school.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    return schoolOptions;
  };

  return (
    <SearchAsync
      {...field}
      light
      className="w-full"
      placeholder={placeholder || "Search School"}
      loadOptions={loadOptions}
      onChange={(e) => {
        onChange(e);
        onBlur();
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default function CsvAutoAssign({ methods, schools, onFocus, onBlur }) {
  const selectedSchoolId = methods.watch("school_id");
  const seriesId = methods.watch("import_series_id");
  const selectedSchool = schools?.find((s) => s.id === selectedSchoolId);
  const [seminars, setSeminars] = useState();
  const [courses, setCourses] = useState();

  const getSeminars = useCallback(async () => {
    try {
      const res = await getAllSchoolSeminars({ school_id: selectedSchoolId });
      if (res.data) setSeminars(res.data);
    } catch (e) {
      displayError(e);
    }
  }, [selectedSchoolId]);

  const getCourses = useCallback(async () => {
    const res = await getAllSchoolCourses({ school_id: selectedSchoolId });
    if (res.data) setCourses(res.data);
  }, [selectedSchoolId]);

  useEffect(() => {
    if (selectedSchoolId) {
      setSeminars(null);
      setCourses(null);
      getSeminars();
      getCourses();
    }
  }, [selectedSchoolId]);

  return (
    <div className="mb-6 px-6 flex gap-4">
      <div className="w-1/3 text-sm">
        Enroll each student from this import in the following School and
        (optionally) a Course/Seminar:
      </div>

      <div className="w-2/3 flex gap-4">
        <div className="w-1/2">
          <SearchSchoolAsync
            schools={schools}
            id="school_id"
            className="w-full"
            onChange={(e) => {
              methods.setValue("school_id", e?.value);
              methods.setValue("import_series_id", "");
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={selectedSchoolId}
            placeholder={
              schools
                ? selectedSchool
                  ? `(#${selectedSchool?.school_code}) ${selectedSchool?.church_name}`
                  : "Select School (optional)"
                : "Loading..."
            }
          />
        </div>

        {selectedSchoolId && (
          <div className="w-1/2">
            <Select
              id="import_series_id"
              classNames="w-full bg-transparent border-gray-500"
              defaultValue={seriesId}
              disabled={!seminars?.length && !courses?.length}
            >
              <SelectItem value="">
                {!seminars || !courses
                  ? "Loading..."
                  : "-- Default course/seminar --"}
              </SelectItem>
              {seminars?.length > 0 && (
                <optgroup label="Seminars" key="seminars">
                  {seminars.map((seminar) => (
                    <SelectItem
                      key={seminar.id}
                      value={seminar.id}
                      selected={seriesId === seminar.id}
                    >
                      {seminar.name}
                    </SelectItem>
                  ))}
                </optgroup>
              )}
              {courses?.length > 0 && (
                <optgroup label="Courses" key="courses">
                  {courses.map((course) => (
                    <SelectItem
                      key={course.id}
                      value={course.id}
                      selected={seriesId === course.id}
                    >
                      {course.name}
                    </SelectItem>
                  ))}
                </optgroup>
              )}
            </Select>
          </div>
        )}
      </div>
    </div>
  );
}
