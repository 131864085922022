import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import EmptyTable from "../../../../../UI/EmptyTable";
import AddButton from "../../../../../UI/AddButton";
import ProductsNew from "./ProductsNew";
import { getAllCourseProductsRequest } from "../../../../../../requests/courses";
import { useMediaQuery } from "../../../../../../utils/useMediaQuery";
import ProductRow from "./ProductRow";
import ProductsMobile from "./Mobile/ProductsMobile";

export default function Products() {
  const isMobile = useMediaQuery("md", "down");
  const { enrollment } = useOutletContext();
  const [products, setProducts] = useState();
  const [addingProduct, setAddingProduct] = useState(false);
  const [updatedEnrollment, setUpdatedEnrollment] = useState(enrollment);
  const productDeliveries = updatedEnrollment?.product_deliveries;

  const getAllCourseProducts = async () => {
    const res = await getAllCourseProductsRequest({
      courseId: enrollment?.series_id,
    });

    if (res.data) {
      setProducts(res.data);
    }
  };

  useEffect(() => {
    getAllCourseProducts();
  }, []);

  return isMobile ? (
    <ProductsMobile
      deliveries={productDeliveries}
      products={products}
      enrollment={updatedEnrollment}
      setUpdatedEnrollment={setUpdatedEnrollment}
    />
  ) : (
    <>
      {productDeliveries?.length ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>PRODUCT OFFER</TableHeader>
              <TableHeader>DELIVERED AT</TableHeader>
              <TableHeader>QUANTITY</TableHeader>
              <TableHeader>PRICE</TableHeader>
              <TableHeader>DELIVERY TYPE</TableHeader>
              <TableHeader>VISITOR</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {productDeliveries.map((delivery) => (
              <ProductRow
                key={delivery.id}
                delivery={delivery}
                enrollment={updatedEnrollment}
                products={products}
                setUpdatedEnrollment={setUpdatedEnrollment}
                disabled={enrollment.readOnly}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No products found." />
      )}

      {addingProduct ? (
        <div className="mt-4">
          <ProductsNew
            enrollment={updatedEnrollment}
            handleCancelAdd={() => setAddingProduct(false)}
            products={products}
            setUpdatedEnrollment={setUpdatedEnrollment}
          />
        </div>
      ) : (
        !enrollment.readOnly && (
          <div className="flex justify-center mt-4">
            <AddButton
              onClick={() => setAddingProduct(true)}
              label="Add Product"
              variant="primary-outline"
            />
          </div>
        )
      )}
    </>
  );
}
