import AttendanceDecisionReport from "../../components/Reports/AttendanceDecisionReport";
import AttendanceReport from "../../components/Reports/AttendanceReport";
import MasterTeamReport from "../../components/Reports/MasterTeamReport";
import StudentContactReport from "../../components/Reports/StudentContactReport";
import StudentFollowupReport from "../../components/Reports/StudentFollowupReport";
import StudentAveryLabelsReports from "../../components/Reports/StudentAveryLabelsReports/StudentAveryLabelsReports";
import ClaimedZipsReport from "../../components/Reports/ClaimedZipsReport";
import SchoolContactReport from "../../components/Reports/SchoolContactReport";
import SchoolInformationFormsReport from "../../components/Reports/SchoolInformationFormsReport";
import SchoolAveryLabelsReport from "../../components/Reports/SchoolAveryLabelsReport";
import NadBibleSchoolsReport from "../../components/Reports/NadBibleSchoolsReport";
import PrintSchoolEnvelopesReport from "../../components/Reports/PrintSchoolEnvelopesReport";
import ArchivedNadBibleSchoolsReport from "../../components/Reports/ArchivedNadBibleSchoolsReport";

export const reportsChildMapping = {
  AttendanceReport: AttendanceReport,
  AttendanceDecisionReport: AttendanceDecisionReport,
  StudentFollowupReport: StudentFollowupReport,
  StudentContactReport: StudentContactReport,
  MasterTeamReport: MasterTeamReport,
  StudentAveryLabelsReports: StudentAveryLabelsReports,
  ClaimedZipsReport: ClaimedZipsReport,
  SchoolContactReport: SchoolContactReport,
  SchoolInformationFormsReport: SchoolInformationFormsReport,
  SchoolAveryLabelsReport: SchoolAveryLabelsReport,
  NadBibleSchoolsReport: NadBibleSchoolsReport,
  PrintSchoolEnvelopesReport: PrintSchoolEnvelopesReport,
  ArchivedNadBibleSchoolsReport: ArchivedNadBibleSchoolsReport,
};
