import React, { useEffect, useState } from "react";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import PageLoader from "../../../UI/PageLoader";
import { getAllSchoolCourses } from "../../../../requests/schools";
import { Link, useOutletContext } from "react-router-dom";
import { checkPermission } from "../../../../utils/checkPermission";
import { hoverLink } from "../../../../utils/hoverLink";
import EmptyTable from "../../../UI/EmptyTable";
import SchoolCoursesMobile from "./Mobile/SchoolCoursesMobile";

export default function SchoolCourses() {
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const isMobile = useMediaQuery("md", "down");
  const { school } = useOutletContext();
  const [courses, setCourses] = useState();

  const getCourses = async () => {
    const res = await getAllSchoolCourses({ school_id: school.id });
    if (res.data) {
      setCourses(res.data);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return isMobile ? (
    <SchoolCoursesMobile courses={courses} />
  ) : courses ? (
    <Card>
      <div className="flex items-center justify-between mb-6">
        <div className="relative">
          <h1 className="text-xl font-bold text-primary">COURSES</h1>
        </div>
      </div>
      {courses?.length ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>COURSE</TableHeader>
              <TableHeader>LESSONS</TableHeader>
              <TableHeader>ACTIVE STUDENTS</TableHeader>
              <TableHeader>TOTAL STUDENTS</TableHeader>
              <TableHeader>PRODUCTS</TableHeader>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course.id}>
                <TableCell>
                  {isGlobalAdmin ? (
                    <Link
                      className={hoverLink}
                      to={`/courses/${course.id}/overview`}
                    >
                      {course.name}
                    </Link>
                  ) : (
                    <span>{course.name}</span>
                  )}
                </TableCell>
                <TableCell>{course.lessons_count}</TableCell>
                <TableCell>{course.active_students_count}</TableCell>
                <TableCell>{course.students_count}</TableCell>
                <TableCell>{course.products_count}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No courses found." />
      )}
    </Card>
  ) : (
    <Card>
      <PageLoader variant="primary" />
    </Card>
  );
}
