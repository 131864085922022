import React, { useState } from "react";
import LessonsSummary from "./LessonsSummary";
import DecisionsSummary from "./DecisionsSummary";
import ProductsSummary from "./ProductsSummary";
import CourseForm from "./CourseForm";
import Card from "../../../UI/Card";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../../UI/Button";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { ToastNotification } from "../../../UI/ToastNotification";
import {
  deleteCourseRequest,
  updateCourseRequest,
} from "../../../../requests/courses";
import Modal from "../../../UI/Modal";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import { displayError } from "../../../../utils/displayError";

export default function CourseOverview() {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { course } = useOutletContext();
  const navigate = useNavigate();
  const params = useParams();
  const methods = useForm();

  const onSubmit = async (data) => {
    const res = await updateCourseRequest({
      ...data,
      courseId: params.courseId,
    });

    if (res.data) {
      try {
        ToastNotification("success", "Course updated successfully.");
        navigate(`/courses/${res.data.id}/overview`);
      } catch (e) {
        ToastNotification("error", "Something went wrong.");
      }
    }
  };

  const deleteCourse = async () => {
    try {
      const res = await deleteCourseRequest({ courseId: params.courseId });

      if (res) {
        ToastNotification("success", "Course deleted successfully.");
        navigate(`/courses`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  return (
    <div>
      <Card classNames="mb-4">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CourseForm course={course} />
            <Button
              classNames="md:w-auto md:mr-4 md:mb-0 w-full mb-4"
              variant="primary"
              type="submit"
            >
              Update Course
            </Button>
            {course.enrollments_count === 0 && (
              <Button
                classNames="md:w-auto md:mr-4 md:mb-0 w-full"
                variant="secondary"
                onClick={(e) => handleDeleteModal(e)}
              >
                Delete Course
              </Button>
            )}
          </form>
        </FormProvider>
      </Card>
      <div className="md:flex">
        <div className="md:w-1/2 md:mr-4">
          <LessonsSummary course={course} />
        </div>
        <div className="md:w-1/2 mt-4 md:mt-0">
          <DecisionsSummary
            course={course}
            decisions={course.decision_options}
          />
          <ProductsSummary course={course} products={course.products} />
        </div>
      </div>
      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteCourse()}
        />
      </Modal>
    </div>
  );
}
