import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import SearchAsync from "../../UI/SearchAsync";
import { getAllSchoolsSimpleList } from "../../../requests/schools";
import { getAllStudents } from "../../../requests/students";

export default function SearchInput({ admin }) {
  const [searchParams] = useSearchParams();
  const [selectedSchoolId, setSelectedSchoolId] = useState();
  const [inputFocused, setInputFocused] = useState(null);
  const [allSchools, setAllSchools] = useState();
  const navigate = useNavigate();

  const loadAllSchools = async () => {
    const response = await getAllSchoolsSimpleList();
    setAllSchools(response.data.schools);
  };

  const handleSchoolChange = (e) => {
    setInputFocused(null);
    setSelectedSchoolId(e?.value);
    navigate(`/schools/${e?.value}/overview`);
  };

  const schoolsOptions = async (inputValue) => {
    const schools = allSchools.map((school) => ({
      label: `(#${school.school_code}) ${school.church_name} (${school.church_city || ""}, ${school.church_state || ""} ${school.church_zip_code || ""}) (${school.students_count} students)`,
      value: school.id,
    }));

    if (inputValue) {
      return schools.filter((school) =>
        school.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    return schools;
  };

  // Find a student by registration number if there is only one student with that registration number.
  // It should only search by registration number if the input contains only numbers.
  const findStudentByRegistrationNumber = async (registrationNumber) => {
    if (!/^\d+$/.test(registrationNumber)) {
      return;
    }

    const response = await getAllStudents({
      params: {
        q: { enrollments_registration_number_eq: registrationNumber },
        per_page: 2,
      },
    });
    const students = response?.data?.students;
    return students?.length === 1 ? students[0] : null;
  };

  const handleSearchStudents = async (e) => {
    e.preventDefault();

    const searchQuery = e.target.studentsQuery.value.trim();

    if (searchQuery) {
      setInputFocused(null);
      const student = await findStudentByRegistrationNumber(searchQuery);

      if (student) {
        navigate(`/students/${student.id}/profile`);
      } else {
        navigate(`/students?studentsQuery=${encodeURIComponent(searchQuery)}`);
      }
    }
  };

  useEffect(() => {
    loadAllSchools();

    const handleClickOutside = (event) => {
      const searchContainer = document.querySelector(".search-container");
      if (searchContainer && !searchContainer.contains(event.target)) {
        setInputFocused(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      setInputFocused(null);
    };
  }, []);

  return (
    <div className="hidden lg:block absolute right-4 top-6">
      <div className="w-full flex gap-4 search-container">
        {admin && allSchools && (
          <div
            className={twMerge(
              "w-full transition-all duration-200",
              inputFocused === "schools" ? "w-[200px]" : "w-[110px]"
            )}
          >
            <SearchAsync
              light
              className="w-full"
              onChange={handleSchoolChange}
              placeholder="Schools"
              key={`school-search-${selectedSchoolId}`}
              loadOptions={schoolsOptions}
              inputBgWhite
              onFocus={() => setInputFocused("schools")}
              onClick={() => setInputFocused("schools")}
            />
          </div>
        )}

        <form
          onSubmit={handleSearchStudents}
          className={twMerge(
            "w-full relative transition-all duration-200",
            inputFocused === "students" ? "w-[200px]" : "w-[110px]"
          )}
        >
          <input
            name="studentsQuery"
            className="w-full rounded border border-[#6b7280] hover:border-secondary px-2 py-1.5 h-[38px] bg-white placeholder:text-[#333] placeholder:text-sm focus:outline-none focus:border-2"
            placeholder="Students"
            defaultValue={searchParams.get("studentsQuery") || undefined}
            onFocus={() => setInputFocused("students")}
            onClick={() => setInputFocused("students")}
          />

          <button
            type="submit"
            className="hover:text-secondary absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 backdrop-blur-3xl rounded-full text-[#333]"
          >
            <MagnifyingGlassIcon aria-hidden="true" className="w-5 h-5" />
          </button>
        </form>
      </div>
    </div>
  );
}
