import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../../UI/Card";
import ContactInformationForm from "./ContactInformation";
import SideSection from "./SideSection";
import {
  useNavigate,
  useRevalidator,
  useOutletContext,
} from "react-router-dom";
import {
  deleteSchoolRequest,
  updateSchoolRequest,
} from "../../../../requests/schools";
import { ToastNotification } from "../../../UI/ToastNotification";
import Button from "../../../UI/Button";
import Modal from "../../../UI/Modal";
import { checkPermission } from "../../../../utils/checkPermission";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import RecentChanges from "../../../Versions/RecentChanges";
import { displayError } from "../../../../utils/displayError";

export default function SchoolOverview() {
  const { school } = useOutletContext();
  const { revalidate } = useRevalidator();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const isGlobalAdmin = checkPermission(["global_admin"]);

  const defaultValues = {
    ...school,
    school_notes_attributes: (school.school_notes || []).map((note) => ({
      id: note.id,
      note: note.note,
      _destroy: false,
    })),
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [school]);

  const onSubmit = async (data) => {
    try {
      const selectedCourses = data.courses.map((course) => ({
        id: course.series_offered_id,
        school_id: school?.id,
        series_id: course.id,
        _destroy: false,
      }));

      const coursesToDestroy = school.courses_offered
        .filter((course) => !data.courses.some((c) => c.id === course.id))
        .map((course) => ({
          id: course.series_offered_id,
          school_id: school?.id,
          series_id: course.id,
          _destroy: true,
        }));

      const finalCourses = [...selectedCourses, ...coursesToDestroy];

      const schoolNotesAttributes = data.school_notes_attributes?.filter(
        (note) => note.note || (note.id && !note._destroy)
      );

      const res = await updateSchoolRequest({
        ...data,
        schoolId: school?.id,
        series_offered_attributes: finalCourses,
        school_notes_attributes: schoolNotesAttributes,
      });

      if (res.data) {
        ToastNotification("success", "School updated successfully.");
        revalidate();
      }
    } catch (e) {
      displayError(e);
    }
  };

  const deleteSchool = async () => {
    try {
      const res = await deleteSchoolRequest({ schoolId: school?.id });

      if (res) {
        ToastNotification("success", "School deleted successfully.");
        navigate(`/schools`);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const handleDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card classNames="p-3 rounded-2xl md:rounded md:p-6">
            <div className="md:flex">
              <div className="md:w-2/3 md:border-r border-black md:pr-6">
                <ContactInformationForm school={school} />
              </div>
              <div className="md:w-1/3 md:pl-6">
                <SideSection school={school} />
              </div>
            </div>

            <RecentChanges itemType="school" itemId={school.id} />
          </Card>

          <div className="md:flex items-center p-6">
            <Button
              classNames="md:w-auto w-full md:mr-4 mb-4 md:mb-0"
              variant="primary"
              type="submit"
            >
              Update School
            </Button>

            {isGlobalAdmin && (
              <>
                <Button
                  classNames="md:w-auto w-full md:mr-4 mb-4 md:mb-0"
                  variant="secondary"
                  onClick={(e) => handleDeleteModal(e)}
                  disabled={school.users_count > 0}
                >
                  Delete School
                </Button>

                <div>
                  {school.users_count > 0 && (
                    <p>
                      This school has {school.users_count} instructors. All
                      instructors must be removed before deleting a school.
                    </p>
                  )}
                  {school.courses_count > 0 && (
                    <p>This school has course data</p>
                  )}
                  {school.seminars_hosted_count > 0 && (
                    <p>
                      This school has {school.seminars_hosted_count} seminars
                      hosted.
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </form>
      </FormProvider>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteSchool()}
        />
      </Modal>
    </div>
  );
}
