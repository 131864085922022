import React from "react";
import CardCounter from "../UI/Card/CardCounter";

export default function MobileDashboard({
  studentsCount,
  interestsCount,
  needingFollowup,
  courses,
  seminars,
  instructors,
  isAdmin,
}) {
  return (
    <div className="max-w-[85%] mx-auto">
      <div className="mb-8 text-center">
        <img src="/logo.png" width={260} className="m-auto w-full" />
        <p className="font-bold uppercase">Student Records System</p>
      </div>
      <div className="flex mb-4">
        <CardCounter
          to={
            isAdmin
              ? "/courses"
              : "/students?q[enrollments_course_id_not_null]=true"
          }
          count={courses?.length}
          label="Courses"
          className="mr-4 w-1/2"
        />
        <CardCounter
          to={
            isAdmin
              ? "/seminars"
              : "/students?q[enrollments_series_id_not_null]=true"
          }
          count={seminars?.length}
          label="Seminars"
          className="w-1/2"
        />
      </div>
      <div className="flex">
        <CardCounter
          to="/students"
          count={studentsCount}
          label="Students"
          className="mb-4 w-full"
        />
      </div>
      <div className="flex">
        <CardCounter
          to="/students?q[enrollments_needing_followup_eq]=true"
          count={needingFollowup?.length}
          label="Follow-up"
          className="mr-4 w-1/2"
        />
        {isAdmin ? (
          <CardCounter
            to="/instructors"
            count={instructors?.length}
            label="Instructors"
            className="w-1/2"
          />
        ) : (
          <CardCounter
            to="/students?section=interests"
            count={interestsCount}
            label="Interests"
            className="w-1/2"
          />
        )}
      </div>
    </div>
  );
}
