import React from "react";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import { highlightText } from "../../utils/highlightText";
import { returnEnrollmentStatus } from "../../utils/enrollmentStatus";

export default function StudentEnrollmentsCell({
  student,
  searchQuery,
  targetBlank,
}) {
  const showDate = (enrollment) => {
    const date = enrollment.last_sent_delivery?.date_sent;

    return date
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          timeZone: "UTC",
        }).format(new Date(date))
      : "-";
  };

  return student.enrollments.map((enrollment) => (
    <Link
      to={
        enrollment.series_type === "Seminar"
          ? `/students/${student.id}/seminars/enrollments/${enrollment.id}/presentations`
          : `/students/${student.id}/courses/enrollments/${enrollment.id}/lessons`
      }
      target={targetBlank ? "_blank" : ""}
      key={enrollment.id}
      className="group flex flex-row space-x-2 leading-none mb-1 p-1 last:mb-0 rounded-md border border-transparent transition-colors hover:border-gray-200"
    >
      {enrollment.series_type === "Seminar" ? (
        <>
          <div
            className={twMerge(
              "w-5/12 group-hover:text-secondary",
              enrollment.orphaned && "text-gray-500"
            )}
          >
            {enrollment.series_name}
          </div>
          <div className="w-3/12 whitespace-nowrap text-xs">
            {highlightText(enrollment.registration_number || "-", searchQuery)}
          </div>
        </>
      ) : (
        <>
          <div
            className={twMerge(
              "w-5/12 group-hover:text-secondary",
              enrollment.orphaned && "text-gray-500"
            )}
          >
            {enrollment.series_name}
            {enrollment.lesson_number > 0 && (
              <span
                title={`Lesson ${enrollment.lesson_number} sent`}
                className="text-xs group-hover:text-secondary-600 w-4 h-4 ml-1 inline-block rounded-full bg-gray-200 group-hover:bg-secondary/20 text-center leading-4"
              >
                {enrollment.lesson_number}
              </span>
            )}
          </div>
          <div className="w-3/12 whitespace-nowrap text-xs">
            {showDate(enrollment)}
          </div>
        </>
      )}
      <div className="w-4/12 text-xs">
        {returnEnrollmentStatus(enrollment.series_id, enrollment)}
      </div>
    </Link>
  ));
}
