import React from "react";
import { useFieldArray, useForm, FormProvider } from "react-hook-form";
import { ToastNotification } from "../../../UI/ToastNotification";
import { bulkCreateLessonRequest } from "../../../../requests/lessons";
import Input from "../../../UI/Inputs/Input";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import AddButton from "../../../UI/AddButton";
import Button from "../../../UI/Button";

export default function CourseLessonRowNew({
  setFormOpen,
  courseId,
  count,
  lessons,
}) {
  const getUniqueDefaultNames = (count) => {
    const existingNumbers = lessons.map((lesson) => {
      const match = lesson.name.match(/Lesson (\d+)/);
      return match ? parseInt(match[1], 10) : 0;
    });

    let lessonNumber = 1;
    const newNames = [];

    for (let i = 0; i < count; i++) {
      while (existingNumbers.includes(lessonNumber)) {
        lessonNumber++;
      }
      newNames.push(`Lesson ${lessonNumber}`);
      existingNumbers.push(lessonNumber);
      lessonNumber++;
    }

    return newNames;
  };

  const methods = useForm({
    defaultValues: {
      lessons: getUniqueDefaultNames(count).map((name) => ({
        name,
        date: new Date(),
      })),
    },
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: "lessons",
  });

  const handleSubmitAll = async (data) => {
    if (data.lessons.length === 0) {
      ToastNotification("error", "No lessons to create.");
      return;
    }

    try {
      const lessonsData = data.lessons.map((lesson) => ({
        ...lesson,
        type: "Lesson",
        series_id: courseId,
      }));

      const res = await bulkCreateLessonRequest({ episodes: lessonsData });

      if (res.data) {
        ToastNotification(
          "success",
          `${lessonsData.length} lessons created successfully.`
        );
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      const errors = [
        ...new Set(e.response.data.errors.map((error) => error.errors).flat()),
      ];
      ToastNotification("error", errors.join(", "));
    }
  };

  const handleCancel = () => {
    setFormOpen({ open: false, action: null });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitAll)}>
        <div className="mt-4">
          {fields.map((field, index) => (
            <div key={field.id} className="mb-4 flex items-end space-x-4">
              <Input
                id={`lessons.${index}.name`}
                classNames="w-3/12"
                placeholder="Lesson Name"
                isRequired
                autoFocus={index === 0}
              />
              <DatePickerInput
                id={`lessons.${index}.date`}
                label="Date"
                isRequired
              />
            </div>
          ))}
          <div className="mt-4 flex justify-start space-x-4">
            <AddButton
              type="submit"
              label="Create All Lessons"
              variant="primary"
            />
            <Button onClick={handleCancel} variant="secondary" type="button">
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
