import React, { Suspense } from "react";
import { useLoaderData, Await, useAsyncError } from "react-router-dom";
import Card from "../UI/Card";
import PageLoader from "../UI/PageLoader";

const ErrorDisplay = () => {
  const error = useAsyncError();
  return (
    <Card classNames="p-6 lg:mt-10 text-center">
      <h2 className="text-xl font-semibold text-red-700">
        Error Loading Content
      </h2>
      <p className="text-red-600">
        {error.message || "Failed to load content"}
      </p>
    </Card>
  );
};

const LoadingDisplay = () => (
  <Card classNames="p-6 lg:mt-10">
    <PageLoader />
  </Card>
);

export default function RouteLoader({ children }) {
  const { data } = useLoaderData();

  return (
    <Suspense fallback={<LoadingDisplay />}>
      <Await resolve={data} errorElement={<ErrorDisplay />}>
        {children}
      </Await>
    </Suspense>
  );
}
