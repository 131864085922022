import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/series`;

const getAllCourses = async (params) => {
  const data = await axiosClient.get(endpoint, {
    params: { type: "Course", ...params.params },
  });

  return data;
};

const getAllCoursesSimpleList = async () =>
  await axiosClient.get(`${endpoint}/simple_list`, {
    params: { type: "Course" },
  });

const getAllCourseLessonsRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.courseId}/episodes`);

  return data;
};

const getAllCourseDecisionsRequest = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.courseId}/decision_options`
  );

  return data;
};

const getAllCourseProductsRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.courseId}/products`);

  return data;
};

const getAllCourseSchoolsRequest = async (courseId, params) => {
  return await axiosClient.get(`${endpoint}/${courseId}/schools`, {
    ...params,
  });
};

const createCourseRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const updateCourseRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.courseId}`, params);

  return data;
};

const getCourseRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.courseId}`);

  return data;
};

const getSimpleCourseRequest = async (courseId) =>
  await axiosClient.get(`${endpoint}/${courseId}/simple_show`);

const deleteCourseRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.courseId}`);

  return data;
};

export {
  createCourseRequest,
  getCourseRequest,
  getAllCourses,
  updateCourseRequest,
  deleteCourseRequest,
  getAllCourseLessonsRequest,
  getAllCourseDecisionsRequest,
  getAllCourseProductsRequest,
  getAllCourseSchoolsRequest,
  getAllCoursesSimpleList,
  getSimpleCourseRequest,
};
