import React, { useEffect, useState } from "react";
import InputGroup from "../../../../UI/Inputs/InputGroup";
import { TrashIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../../UI/Inputs/Input";
import Button from "../../../../UI/Button";
import ClipboardIcon from "../../../../UI/Icons/ClipboardIcon";
import PrayerRequestIcon from "../../../../UI/Icons/PrayerRequestIcon";

export default function NoteForm({
  setAddingNotes,
  onSubmit,
  note,
  setDeleteModalOpen,
  disabled,
}) {
  const methods = useForm();
  const [isEditing, setIsEditing] = useState(false);

  const handleNoteTypeChange = (type) => {
    if (note) {
      setIsEditing(true);
    }

    methods.setValue("type", type);
  };

  const handleClose = () => {
    if (!note) {
      setAddingNotes(false);
    }
    setIsEditing(false);
  };

  const onSubmitAndClose = async (data) => {
    await onSubmit(data);
    handleClose();
  };

  const handleDelete = () => {
    if (note) {
      setDeleteModalOpen(true);
    } else {
      setAddingNotes(false);
    }
  };

  useEffect(() => {
    if (!note) {
      methods.setValue("type", "Note");
    } else {
      methods.setValue("type", note.note_type);
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitAndClose)}>
        <InputGroup classNames="items-center inline-grid gap-2 lg:gap-2 mb-2">
          <div className="flex h-full items-center justify-start">
            <button
              onClick={() => (disabled ? null : handleNoteTypeChange("Note"))}
              type="button"
              className={twMerge(
                methods.watch("type") === "Note"
                  ? "bg-secondary text-white"
                  : "text-secondary",
                "cursor-pointer rounded-l-lg border border-r-0 border-secondary p-1 h-[30px] w-[30px] flex items-center justify-center",
                disabled && "cursor-not-allowed"
              )}
            >
              <ClipboardIcon />
            </button>
            <button
              type="button"
              onClick={() =>
                disabled ? null : handleNoteTypeChange("PrayerRequest")
              }
              className={twMerge(
                methods.watch("type") === "PrayerRequest"
                  ? "bg-secondary text-white"
                  : "text-secondary",
                "cursor-pointer rounded-r-lg border border-secondary p-1 h-[30px] w-[30px] flex items-center justify-center",
                disabled && "cursor-not-allowed"
              )}
            >
              <PrayerRequestIcon />
            </button>
          </div>
          <div
            onClick={() => {
              note && setIsEditing(true);
            }}
          >
            <Input
              id="text"
              defaultValue={note?.text}
              placeholder="Add Note"
              isRequired
              disabled={disabled}
              inputClassNames={twMerge(
                "h-[32px]",
                Boolean(note) === isEditing
                  ? "w-[calc(270px-1rem)]"
                  : "w-[400px]"
              )}
            />
          </div>

          {Boolean(note) === isEditing && (
            <>
              <Button
                onClick={() => handleClose()}
                variant="primary-outline"
                classNames="py-0 h-[32px] w-[75px]"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary-outline"
                classNames="py-0 h-[32px] w-[55px]"
              >
                Save
              </Button>
            </>
          )}

          {Boolean(note) !== isEditing && !disabled ? (
            <TrashIcon
              className="h-5 w-5 cursor-pointer text-gray-500 hover:text-secondary transition-colors"
              onClick={() => handleDelete()}
            />
          ) : (
            <div className="w-[20px]" />
          )}
        </InputGroup>
      </form>
    </FormProvider>
  );
}
