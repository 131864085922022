import React from "react";
import CardLink from "../../UI/Card/CardLink";
import BackLink from "../../Mobile/BackLink";
import PageLoader from "../../UI/PageLoader";

export default function SchoolsMobileList({ schools, loading }) {
  if (loading) return <PageLoader />;

  return (
    <div>
      <BackLink path="/" label="Schools" />

      <div className="pt-4">
        {schools?.map((school) => (
          <CardLink
            key={school.id}
            to={`/schools/${school.id}/overview`}
            className="mb-4 last:mb-0 block"
            cardClassName="rounded-xl p-0 pb-2"
          >
            <span className="block px-4 pt-4 pb-2 border-b border-gray-500/20">
              <span className="block text-xs leading-tight">
                #{school.school_code}
              </span>
              <span className="block text-lg leading-tight">
                {school.church_name}
              </span>
            </span>
            <span className="flex justify-center items-center py-2 w-2/3 m-auto">
              <span className="w-1/3 text-center">
                <span className="block text-[40px] leading-tight">
                  {school.instructors_count}
                </span>
                <span className="block text-xs leading-none">Instructors</span>
              </span>
              <span className="w-1/3 text-center">
                <span className="block text-[40px] leading-tight">
                  {school.courses_count}
                </span>
                <span className="block text-xs leading-none">Courses</span>
              </span>
              <span className="w-1/3 text-center">
                <span className="block text-[40px] leading-tight">
                  {school.students_count}
                </span>
                <span className="block text-xs leading-none">Students</span>
              </span>
            </span>
          </CardLink>
        ))}
      </div>
    </div>
  );
}
