import React, { useEffect, useState } from "react";
import SearchSelect from "react-select";
import { TrashIcon } from "@heroicons/react/20/solid";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Checkbox from "../../../UI/Inputs/Checkbox";
import { getAllSchoolsSimpleList } from "../../../../requests/schools";
import { useFormContext } from "react-hook-form";
import { getAllConferences } from "../../../../requests/conferences";
import { checkPermission } from "../../../../utils/checkPermission";

export default function RoleSelectRow({ field, index, update }) {
  const { watch, setValue } = useFormContext();

  const [organizations, setOrganization] = useState([]);

  const getSchools = async () => {
    const res = await getAllSchoolsSimpleList();
    if (res.data) {
      setOrganization(
        res.data.schools.map((school) => {
          return {
            organization_id: school.id,
            organization_name: `${school.church_name} (#${school.school_code})`,
          };
        })
      );
    }
  };

  const getConferences = async () => {
    const res = await getAllConferences();
    if (res.data) {
      setOrganization(
        res.data.map((conference) => {
          return {
            organization_id: conference.id,
            organization_name: conference.name,
          };
        })
      );
    }
  };

  const isConference =
    watch(`roles_attributes.${index}.role_type`, field.role_type) ===
    "conference_admin";

  useEffect(() => {
    if (isConference) {
      getConferences();
    } else {
      getSchools();
    }
  }, [watch(`roles_attributes.${index}.role_type`)]);

  const roleTypes = [
    {
      name: "Instructor",
      role_type: "instructor",
      show: checkPermission(["school_admin", "conference_admin"]),
    },
    {
      name: "School Admin",
      role_type: "school_admin",
      show: checkPermission(["school_admin", "conference_admin"]),
    },
    {
      name: "Conference Admin",
      role_type: "conference_admin",
      show: checkPermission(["global_admin"]),
    },
  ];

  return (
    <div>
      <div className="mb-6 w-3/5 w-full flex items-center">
        <div className="mr-4">
          <p className="ml-2">Roles</p>
          <Select
            id={`roles_attributes.${index}.role_type`}
            defaultValue={field.role_type}
            classNames="w-full"
          >
            {roleTypes
              .filter((type) => type.show)
              .map((role) => (
                <SelectItem key={role.role_type} value={role.role_type}>
                  {role.name}
                </SelectItem>
              ))}
          </Select>
        </div>
        <div className="mr-4">
          <p className="ml-2">Organization</p>
          {organizations.length > 0 && (
            <SearchSelect
              id={`roles_attributes.${index}.organization_id`}
              options={organizations.map((organization) => ({
                value: organization.organization_id,
                label: `${organization.organization_name}`,
              }))}
              defaultValue={field.organization_id}
              className="w-[150px] xl:w-[250px]"
              onChange={(e) => {
                setValue(`roles_attributes.${index}.organization_id`, e.value);
              }}
              placeholder={
                field.organization_id
                  ? organizations.find(
                      (organization) =>
                        organization.organization_id === field.organization_id
                    )?.organization_name
                  : `Select a ${isConference ? "conference" : "school"}`
              }
            />
          )}
        </div>

        {checkPermission(["school_admin", "conference_admin"]) && (
          <div className="mt-5 cursor-pointer">
            <TrashIcon
              onClick={() => update(index, { ...field, delete: true })}
              className="w-5"
            />
          </div>
        )}
      </div>

      {watch(`roles_attributes.${index}.role_type`, field.role_type) ===
        "instructor" && (
        <div className="mb-6">
          <Checkbox
            id={`roles_attributes.${index}.allow_sharing`}
            label="Can share students with other instructors"
            defaultChecked={field.allow_sharing}
          />
        </div>
      )}
    </div>
  );
}
