import React from "react";
import { Link } from "react-router-dom";

export default function ActionMenuItem({ menuItem, setRender }) {
  const reset = () => {
    setRender(menuItem.child);
  };

  if (menuItem.path) {
    return (
      <Link to={menuItem.path} className="block sm:p-7 group outline-none">
        <span className="block w-10 m-auto border border-white rounded p-2 mb-2 group-hover:bg-white group-hover:text-neutral-600">
          {menuItem.icon}
        </span>
        <span>{menuItem.name}</span>
      </Link>
    );
  }

  return (
    <div
      onClick={() => reset()}
      className="block sm:p-7 group outline-none cursor-pointer"
    >
      <span className="block w-10 m-auto border border-white rounded p-2 mb-2 group-hover:bg-white group-hover:text-neutral-600">
        {menuItem.icon}
      </span>
      <span>{menuItem.name}</span>
    </div>
  );
}
