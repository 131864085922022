import React from "react";
import QuestionResponseForm from "./QuestionResponseForm";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { createQuestionResponseRequest } from "../../../../../../requests/questionResponses";

export default function QuestionNew({ question, enrollment, disabled }) {
  const onSubmit = async (data) => {
    try {
      const res = await createQuestionResponseRequest({
        ...data,
        question_id: question.id,
        enrollment_id: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Created successfully.");
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return <QuestionResponseForm onSubmit={onSubmit} disabled={disabled} />;
}
