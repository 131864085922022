import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function NavGroupItem({ label, url, count }) {
  const activeLink =
    "bg-gradient-to-r from-primary-400 to-primary text-white rounded-full";

  return (
    <NavLink
      to={url}
      className={({ isActive }) =>
        twMerge(
          isActive && activeLink,
          "min-w-[100px] px-4 py-1.5 text-sm font-medium whitespace-nowrap"
        )
      }
    >
      {label}
      {count > 0 && (
        <span className="inline-flex ml-1 text-[10px] font-normal bg-secondary/20 rounded-full min-w-4 h-4 px-1 items-center justify-center font-mono">
          {count}
        </span>
      )}
    </NavLink>
  );
}
