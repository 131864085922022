import React from "react";
import { useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";

export default function Input({
  id,
  isRequired,
  placeholder,
  classNames,
  defaultValue,
  label,
  inputClassNames,
  disabled,
  size,
  min,
  step,
  dark = false,
  type = "text",
  readOnly,
  index,
  autoFocus,
  pattern,
  title,
  onBlur,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={twMerge("w-full relative", classNames)}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        title={title}
        pattern={pattern}
        autoFocus={autoFocus}
        readOnly={readOnly}
        disabled={disabled}
        type={type}
        defaultValue={defaultValue}
        size={size}
        min={min}
        step={step}
        className={twMerge(
          "w-full rounded border px-2 py-1.5 h-[38px]",
          dark && "bg-transparent",
          errors[id] && "border-red-500",
          inputClassNames,
          disabled && !dark && "bg-gray-100 opacity-80 cursor-not-allowed",
          disabled && dark && "bg-gray-100/10 opacity-80 cursor-not-allowed"
        )}
        placeholder={placeholder}
        {...register(id, {
          required: isRequired ? "This field is required" : false,
        })}
        onBlur={onBlur}
      />

      {index >= 0 ? (
        <>
          {errors?.[id.split(".")[0]]?.[id.split(".")[1]]?.[
            id.split(".")[2]
          ] && (
            <p className="absolute text-red-500 text-sm">
              {
                errors?.[id.split(".")[0]]?.[id.split(".")[1]]?.[
                  id.split(".")[2]
                ]?.message
              }
            </p>
          )}
        </>
      ) : (
        <>
          {errors[id] && (
            <p className="absolute text-red-500">{errors[id]?.message}</p>
          )}
        </>
      )}
    </div>
  );
}
