import React, { useState } from "react";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import Table from "../../UI/Table/Table";
import TableHeader from "../../UI/Table/TableHeader";
import TableCell from "../../UI/Table/TableCell";
import Button from "../../UI/Button";
import PageLoader from "../../UI/PageLoader";
import { ToastNotification } from "../../UI/ToastNotification";
import { importStudentImportRequest } from "../../../requests/studentImports";

const availableColumns = {
  title: "Title",
  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  school_id: "School",
  instructor_id: "Instructor",
  series_id: "Course/Seminar",
  registration_number: "Registration Number",
  referral: "Referrals",
  address: "Address",
  address_2: "Address 2",
  city: "City",
  state: "State/Province",
  zip_code: "Zip/Postal",
  country: "Country",
  gender: "Gender",
  married: "Married",
  birthdate: "Birthdate",
  denomination: "Denomination",
  baptized: "Baptized",
  phone: "Phone",
  phone_is_sms: "Phone is SMS",
  phone_2: "Phone 2",
  phone_2_is_sms: "Phone 2 is SMS",
  email: "Email",
  social_media: "Social Media",
};

const formattedBirthdate = (value) => {
  if (!value) return;

  try {
    return Intl.DateTimeFormat("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      timeZone: "UTC",
    }).format(new Date(value));
  } catch (error) {
    return value;
  }
};

// In some cases, the value is not a simple string or number, so we need to
// format it differently from it's object like School, Instructor and Course/Seminar.
const getColumnValue = (key, result) => {
  const value = result[key];
  if (typeof value === "boolean") return value ? "Yes" : "No";
  if (!value) return "";

  switch (key) {
    case "school_id":
      return `(#${result["school"]["school_code"]}) ${result["school"]["church_name"]}`;
    case "instructor_id":
      return `${result["instructor"]["first_name"]} ${result["instructor"]["last_name"]}`;
    case "series_id":
      return result["series"] ? result["series"]["name"] : "";
    case "birthdate":
      return formattedBirthdate(result["birthdate"]);
    default:
      return value;
  }
};

const getResultMessage = (importResult, result) => {
  const valid = importResult.valid;
  const exists = Boolean(importResult.existing_student);
  const enrolled = importResult.already_enrolled;

  if (exists && enrolled)
    return `Skipping - The existing student is already enrolled in "${result.series_id}"`;

  if (exists && result.series_id && result.registration_number)
    return `The existing student will be enrolled in "${result.series_id}" with the registration number "${result.registration_number}"`;

  if (exists && result.series_id && !result.registration_number)
    return `The existing student will be enrolled in "${result.series_id}" without a registration number.`;

  if (exists && !result.series_id)
    return "Skipping - The existing student has no course/seminar selected to enroll in.";

  if (valid && result.series_id && result.registration_number)
    return `Student will be created and enrolled in "${result.series_id}" with the registration number "${result.registration_number}"`;

  if (valid && result.series_id && !result.registration_number)
    return `Student will be created and enrolled in "${result.series_id}" without a registration number.`;

  if (valid && !result.series_id)
    return "The new student will be created without a course/seminar enrollment.";

  if (!valid) return "Skipping - The row data is not valid.";

  return "Something went wrong. Please contact support before proceeding.";
};

export default function CsvResults({
  studentImport,
  onImportExecuted,
  setShowResults,
  closeModal,
}) {
  const [importing, setImporting] = useState(false);
  const selectedColumns = Object.entries(availableColumns);

  const results = studentImport.results.map((result) => {
    return selectedColumns.reduce((acc, [key]) => {
      acc[key] = getColumnValue(key, result);
      return acc;
    }, {});
  });

  const handleExecuteImport = async () => {
    if (!window.confirm("Are you sure you want to execute the import?")) return;

    setImporting(true);

    try {
      const res = await importStudentImportRequest(studentImport.id);

      if (res.status === 200 && res.data.student_import?.imported) {
        ToastNotification("success", "Import executed successfully");
        onImportExecuted();
      } else {
        ToastNotification(
          "error",
          "Something went wrong. Please contact support before proceeding."
        );
      }
    } catch (error) {
      ToastNotification("error", error.message);
    } finally {
      setImporting(false);
    }
  };

  const errorsForRow = (index) => {
    return [
      ...(studentImport.results[index].enrollment_errors || []),
      ...(studentImport.results[index].new_student_errors || []),
    ].join(", ");
  };

  if (importing) return <PageLoader className="md:h-screen" />;

  return (
    <>
      <div className="h-[200px]">
        <div className="fixed w-full z-1">
          <ActionMenuContainer
            light
            label="CSV students import"
            clickToClose={closeModal}
          />

          <div className="flex justify-center gap-4 mb-4">
            <span>Total rows: {studentImport.results.length}</span>
            <span>
              Valid rows:{" "}
              <span className="text-green-500">
                {studentImport.import_success_count}
              </span>
            </span>
            <span>
              Invalid rows:{" "}
              <span className="text-red-500">
                {studentImport.import_error_count}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 relative z-[1] mb-24">
        <Table
          noOverflow
          className="whitespace-nowrap [&_tr>:first-child]:pl-12 [&_tr>:last-child]:pr-12 text-sm"
        >
          <thead className="uppercase [&_th]:sticky [&_th]:top-0 [&_th]:z-[999] [&_th]:bg-white [&_th]:py-4">
            <tr>
              <TableHeader>#</TableHeader>
              <TableHeader>Valid</TableHeader>
              <TableHeader>Exists</TableHeader>
              <TableHeader>Summary</TableHeader>
              {selectedColumns.map(([key, value]) => (
                <TableHeader key={key}>{value}</TableHeader>
              ))}
            </tr>
          </thead>
          <tbody className="bg-gray-100">
            {results.map((result, index) => (
              <tr key={index}>
                <TableCell>{index + 1}.</TableCell>
                <TableCell>
                  {studentImport.results[index].valid ? (
                    <span className="text-green-500">Yes</span>
                  ) : (
                    <span className="text-red-500">No</span>
                  )}
                </TableCell>
                <TableCell>
                  {studentImport.results[index].existing_student ? "Yes" : "No"}
                </TableCell>
                <TableCell>
                  {errorsForRow(index) &&
                  !studentImport.results[index].valid &&
                  (!studentImport.results[index].existing_student ||
                    !studentImport.results[index].already_enrolled) ? (
                    <span className="text-red-500">{errorsForRow(index)}</span>
                  ) : (
                    <span className="text-green-500">
                      {getResultMessage(studentImport.results[index], result)}
                    </span>
                  )}
                </TableCell>
                {Object.entries(result).map(([key, value]) => (
                  <TableCell key={key}>{value}</TableCell>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        {!studentImport.imported && (
          <div className="flex justify-center pt-4 fixed bottom-8 w-full">
            <div className="text-center md:mr-4">
              {!studentImport.imported && (
                <Button
                  onClick={() => setShowResults(false)}
                  type="button"
                  variant="secondary"
                  classNames="shadow-lg mr-2"
                >
                  Back
                </Button>
              )}

              <Button
                type="button"
                variant="primary"
                classNames="shadow-lg"
                onClick={handleExecuteImport}
                disabled={studentImport.import_success_count < 1}
              >
                Confirm and execute import
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
