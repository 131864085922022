import React, { useEffect, useState } from "react";
import InputGroup from "../../../UI/Inputs/InputGroup";
import {
  getAllCoursesSimpleList,
  getSimpleCourseRequest,
} from "../../../../requests/courses";
import SearchAsync from "../../../UI/SearchAsync";

export default function CourseSelect({ onChange, selectedCourseId }) {
  const [defaultValue, setDefaultValue] = useState(null);

  const loadOptions = async (inputValue) => {
    const res = await getAllCoursesSimpleList();

    return res.data.courses
      .filter((course) =>
        course.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((course) => ({
        label: `${course.name}`,
        value: course.id,
      }));
  };

  const fetchCourse = async (courseId) => {
    const res = await getSimpleCourseRequest(courseId);
    return res.data;
  };

  const handleChange = async (selected) => {
    onChange(null);
    const course = await fetchCourse(selected.value);
    onChange(course);
  };

  useEffect(() => {
    if (selectedCourseId) {
      const loadCourse = async () => {
        const course = await fetchCourse(selectedCourseId);

        const defaultOption = {
          label: `${course.name}`,
          value: course.id,
        };

        setDefaultValue(defaultOption);
      };

      loadCourse();
    }
  }, [selectedCourseId]);

  return (
    <div className="lg:w-1/2 m-auto min-h-[320px]">
      <label>Course</label>
      <InputGroup>
        <SearchAsync
          loadOptions={loadOptions}
          onChange={handleChange}
          placeholder="Search for a course"
          className="border rounded"
          defaultValue={defaultValue}
        />
      </InputGroup>
    </div>
  );
}
