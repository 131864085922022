import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../../UI/Button";
import {
  getAllSchoolsSimpleList,
  getSchoolRequest,
} from "../../../../requests/schools";
import InputGroup from "../../../UI/Inputs/InputGroup";
import SearchAsync from "../../../UI/SearchAsync";
import { checkPermission } from "../../../../utils/checkPermission";
import PageLoader from "../../../UI/PageLoader";

export default function PlaceManually({ searchFullNameArray }) {
  const methods = useForm();
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const selectedSchoolId = methods.watch("school")?.value;
  const [selectedSchool, setSelectedSchool] = useState();
  const [allSchools, setAllSchools] = useState();

  const loadAllSchools = async () => {
    const response = await getAllSchoolsSimpleList();
    setAllSchools(response.data.schools);
  };

  const schoolsOptions = async (inputValue) => {
    const schools = allSchools.map((school) => ({
      label: `(#${school.school_code}) ${school.church_name} (${school.church_city || ""}, ${school.church_state || ""} ${school.church_zip_code || ""}) (${school.students_count} students)`,
      value: school.id,
    }));

    if (inputValue) {
      return schools.filter((school) =>
        school.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    return schools;
  };

  const fetchSchool = async (schoolId) => {
    const response = await getSchoolRequest({ schoolId });
    setSelectedSchool(response.data);
  };

  const onSubmit = (data) => {
    window.location.href = `/students/new?school_id=${data.school?.value}&student_search_name=${searchFullNameArray}`;
  };

  useEffect(() => {
    loadAllSchools();
  }, []);

  useEffect(() => {
    if (selectedSchoolId) fetchSchool(selectedSchoolId);
  }, [selectedSchoolId]);

  if (!allSchools) return <PageLoader />;

  return (
    <>
      <h2 className="text-2xl">Place in School Manually</h2>
      <p className="text-[15px]">
        Select the school you would like to place the new student in.
      </p>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-6 rounded-md min-h-[350px]"
        >
          <InputGroup>
            <SearchAsync
              dark
              className="w-full"
              placeholder="Schools"
              loadOptions={schoolsOptions}
              onChange={(e) => methods.setValue("school", e)}
            />
          </InputGroup>

          <p className="text-[15px] mb-6 mt-4">
            To continue creating the new student, click the button below to
            complete the new student profile and enrollments.
          </p>

          {selectedSchool && !selectedSchool?.head_school_admin && (
            <p className="text-red-400 mb-6">
              The selected school needs a <b>Head School Admin</b> before it can
              accept students.{" "}
              {isAdmin && (
                <Link
                  className="text-blue-300"
                  to={`/schools/${selectedSchool.id}/overview`}
                >
                  Manage School
                </Link>
              )}
            </p>
          )}

          <div className="text-center">
            <Button
              type="submit"
              variant="primary"
              disabled={!selectedSchool?.head_school_admin}
            >
              Create New Student
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
