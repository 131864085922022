import React from "react";
import ProductsForm from "./ProductsForm";
import { updateEnrollmentRequest } from "../../../../../../requests/enrollments";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { useNavigate } from "react-router-dom";

export default function ProductsNew({
  enrollment,
  handleCancelAdd,
  products,
  setUpdatedEnrollment,
}) {
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const selectedProduct = products.find(
      (product) => product.id === data.product_id
    );

    try {
      const res = await updateEnrollmentRequest({
        product_deliveries_attributes: [
          {
            product_id: data.product_id,
            delivered_at: data.delivered_at,
            quantity: data.quantity,
            price: data.price || selectedProduct.default_price,
            visitor_name: data.visitor_name,
            delivery_type: data.delivery_type,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Product given successfully.");
        navigate(".", { replace: true });
        handleCancelAdd();
        setUpdatedEnrollment(res.data);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return (
    <ProductsForm
      products={products}
      enrollment={enrollment}
      handleCancelAdd={handleCancelAdd}
      onSubmit={onSubmit}
      column="all"
    />
  );
}
