import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import TableHeader from "../../../UI/Table/TableHeader";
import Table from "../../../UI/Table/Table";
import PageLoader from "../../../UI/PageLoader";
import { getAllSeminarPresentationsRequest } from "../../../../requests/seminars";
import { useParams } from "react-router-dom";
import { deletePresentationRequest } from "../../../../requests/presentations";
import { ToastNotification } from "../../../UI/ToastNotification";
import SeminarPresentationRow from "./SeminarPresentationRow";
import AddButton from "../../../UI/AddButton";
import SeminarPresentationRowNew from "./SeminarPresentationRowNew";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import Modal from "../../../UI/Modal";
import { displayError } from "../../../../utils/displayError";
import EmptyTable from "../../../UI/EmptyTable";
import { checkPermission } from "../../../../utils/checkPermission";

export default function SeminarPresentations() {
  const params = useParams();
  const seminarId = params.seminarId;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activePresentationId, setActivePresentationId] = useState(null);
  const [presentations, setPresentations] = useState();
  const [formOpen, setFormOpen] = useState({ open: false, action: null });

  const getAllSeminarPresentations = async () => {
    try {
      const res = await getAllSeminarPresentationsRequest({
        seminarId: seminarId,
      });

      if (res.data) {
        setPresentations(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const deletePresentation = async () => {
    try {
      const res = await deletePresentationRequest({
        presentationId: activePresentationId,
      });

      if (res) {
        ToastNotification("success", "Presentation deleted successfully.");
        setDeleteModalOpen(false);
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleDeleteModal = (e, presentationId) => {
    e.preventDefault();
    setDeleteModalOpen(true);
    setActivePresentationId(presentationId);
  };

  useEffect(() => {
    getAllSeminarPresentations();
  }, [formOpen, deleteModalOpen]);

  return presentations ? (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["global_admin", "school_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() => setFormOpen({ open: true, action: "new" })}
              />
            )}

            <h1 className="text-xl font-bold text-primary">PRESENTATIONS</h1>
          </div>
        </div>
        {presentations.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>PRESENTATION</TableHeader>
                <TableHeader>DATE</TableHeader>
                <TableHeader>ATTENDANCE</TableHeader>
                <TableHeader>DECISION CARD</TableHeader>
                <TableHeader></TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {presentations.map((presentation) => (
                <SeminarPresentationRow
                  key={presentation.id}
                  presentation={presentation}
                  formOpen={formOpen}
                  setFormOpen={setFormOpen}
                  handleDeleteModal={handleDeleteModal}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <>
            {formOpen.action !== "new" && (
              <EmptyTable message="No presentations found" />
            )}
          </>
        )}

        {formOpen.action === "new" && (
          <div>
            <SeminarPresentationRowNew
              seminarId={seminarId}
              setFormOpen={setFormOpen}
            />
          </div>
        )}

        {formOpen.action === "new" ? null : (
          <div className="flex justify-center mt-4">
            <AddButton
              onClick={() => setFormOpen({ open: true, action: "new" })}
              label="Add Presentation"
              variant="primary-outline"
            />
          </div>
        )}
      </Card>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deletePresentation()}
        />
      </Modal>
    </div>
  ) : (
    <Card>
      <PageLoader variant="primary" />
    </Card>
  );
}
