import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../UI/Card";
import ContactInformationForm from "./SchoolOverview/ContactInformation";
import SideSection from "./SchoolOverview/SideSection";
import { createSchoolRequest } from "../../../requests/schools";
import { ToastNotification } from "../../UI/ToastNotification";
import Button from "../../UI/Button";
import { displayError } from "../../../utils/displayError";

export default function SchoolNew() {
  const navigate = useNavigate();

  const defaultValues = useMemo(
    () => ({
      tag_list: [],
    }),
    []
  );

  const methods = useForm({ defaultValues });

  const onSubmit = async (data) => {
    try {
      // Filter out blank notes
      if (data.school_notes_attributes) {
        data.school_notes_attributes = data.school_notes_attributes.filter(
          (note) => note.note?.trim() !== ""
        );
      }

      const res = await createSchoolRequest(data);

      if (res.data) {
        ToastNotification("success", "School created successfully.");
        navigate(`/schools/${res.data.id}/overview`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card classNames="lg:mt-10">
            <div className="flex">
              <div className="w-2/3 border-r border-black pr-6">
                <ContactInformationForm />
              </div>
              <div className="w-1/3 pl-6">
                <SideSection />
              </div>
            </div>
          </Card>
          <div className="flex items-center p-6">
            <Button type="submit" variant="primary">
              Create School
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
