import React, { useState } from "react";
import TakeAttendanceForm from "./TakeAttendanceForm";
import TakeAttendanceCreateNew from "./TakeAttendanceCreateNew";
import { takeAttendanceRequest } from "../../../../../requests/enrollments";
import { displayError } from "../../../../../utils/displayError";
import Button from "../../../../UI/Button";
import { useOutletContext } from "react-router-dom";
import { ToastNotification } from "../../../../UI/ToastNotification";
import { fullName } from "../../../../../utils/fullName";
import dayjs from "dayjs";

export default function TakeAttendance({ presentation }) {
  const { user, seminar } = useOutletContext();
  const [renderCreateNew, setRenderCreateNew] = useState(false);
  const [renderSuccess, setRenderSuccess] = useState(false);
  const [renderExisted, setRenderExisted] = useState(false);
  const [renderNotFound, setRenderNotFound] = useState(false);
  const [response, setResponse] = useState(null);

  const playBuzzer = async () => {
    document.body.insertAdjacentHTML(
      "afterbegin",
      "<audio id=\"buzzer\" style={{ display: 'none' }} />"
    );

    const sound = document.getElementById("buzzer");
    sound.src = "/buzzer.mp3";
    sound.play();
  };

  const onSubmit = async (data) => {
    try {
      handleReset();

      const rest = await takeAttendanceRequest({
        episode_deliveries_attributes: [
          {
            date_completed: new Date(),
            episode_id: presentation.id,
            grading_instructor_id: user.id,
            delivery_type: "Personal Delivery",
          },
        ],
        registration_number: data.registration_number,
        series_id: presentation.series_id,
        presentation_id: presentation.id,
      });

      if (rest.data) {
        ToastNotification("success", "Attendance taken successfully");
        setRenderSuccess(true);
        setRenderNotFound(false);
        setResponse(rest.data);
      }
    } catch (e) {
      if (e.response.status === 404) {
        ToastNotification("error", "Registration number not found");
        setRenderNotFound(true);
        setResponse(data.registration_number);
        await playBuzzer();
      } else if (
        e.response.status === 422 &&
        e.response.data.error === "Student already attended"
      ) {
        ToastNotification("error", "Student already attended");
        setRenderExisted(true);
        setRenderNotFound(false);
        setResponse(e.response.data.data);
      } else {
        displayError(e);
      }
    }
  };

  const handleReset = () => {
    setRenderCreateNew(false);
    setRenderSuccess(false);
    setRenderExisted(false);
    setRenderNotFound(false);
    setResponse(null);
  };

  if (renderCreateNew) {
    return (
      <TakeAttendanceCreateNew
        seminar={seminar}
        handleReset={handleReset}
        registrationNumber={response}
        presentation={presentation}
        user={user}
      />
    );
  }

  if (renderSuccess) {
    return (
      <div>
        <p className="mb-4 text-center">
          Attendance successfully recorded for{" "}
          {
            <span className="font-bold">
              {fullName(
                response.student?.first_name,
                " ",
                response.student?.last_name
              )}
            </span>
          }
        </p>

        <TakeAttendanceForm onSubmit={onSubmit} />
      </div>
    );
  }

  if (renderExisted) {
    return (
      <div>
        <p className="mb-4 text-center">
          Attendance already recorded for{" "}
          <span className="font-bold">
            {fullName(
              response.student?.first_name,
              " ",
              response.student?.last_name
            )}
          </span>{" "}
          at{" "}
          <span className="font-bold">
            {dayjs(response.date_completed).format("DD/MM/YYYY")}
          </span>
          .
        </p>

        <TakeAttendanceForm onSubmit={onSubmit} />
      </div>
    );
  }

  if (renderNotFound) {
    return (
      <div>
        <p className="mb-4 text-center">
          Registration number <span className="font-bold">{response}</span> not
          found.
        </p>

        <div className="text-center pb-4">
          <Button
            classNames="mr-2"
            variant="primary"
            onClick={() => setRenderCreateNew(true)}
          >
            Create a student
          </Button>
        </div>

        <TakeAttendanceForm onSubmit={onSubmit} />
      </div>
    );
  }

  return <TakeAttendanceForm onSubmit={onSubmit} />;
}
