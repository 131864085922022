import React, { useEffect, useState } from "react";
import { Pagination, usePagination } from "../UI/Pagination";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import Card from "../UI/Card";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import Button from "../UI/Button";
import QuickFind from "../UI/QuickFind";
import Modal from "../UI/Modal";
import { ToastNotification } from "../UI/ToastNotification";
import ConfirmationDialog from "../UI/ConfirmationDialog";
import SeminarsMobileList from "./Mobile/SeminarsMobileList";
import { getAllSeminars, deleteSeminarRequest } from "../../requests/seminars";
import { checkPermission } from "../../utils/checkPermission";
import { displayError } from "../../utils/displayError";
import { hoverLink } from "../../utils/hoverLink";
import { highlightText } from "../../utils/highlightText";
import EmptyTable from "../UI/EmptyTable";

export default function Seminars() {
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const isGlobalAdmin = checkPermission("global_admin");
  const { setActionMenuModal } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const page = searchParams.get("page") || "1";
  const [loading, setLoading] = useState(false);
  const pagination = usePagination();
  const [seminars, setSeminars] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState([false, null]);

  const getSeminars = async () => {
    setLoading(true);

    try {
      const queryParams = {
        page: page,
        per_page: pagination.recordsPerPage,
        q: {},
      };

      // Only add the search parameter if query is not empty.
      // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
      // Splitting the query terms into an array for the `cont` matcher.
      if (query.trim()) {
        queryParams.q.name_i_cont_all = query.trim().split(" ");
      }

      const res = await getAllSeminars({ params: queryParams });

      if (res.data) {
        setSeminars(res.data.seminars);
        pagination.setData(res.data.pagination);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  const handleDeleteModal = (e, seminarId) => {
    e.preventDefault();
    setDeleteModalOpen([true, seminarId]);
  };

  const deleteSeminar = async (seminarId) => {
    try {
      const res = await deleteSeminarRequest({ seminarId });

      if (res) {
        setDeleteModalOpen([false, null]);
        ToastNotification("success", "Seminar deleted successfully.");
        getSeminars();
      }
    } catch (e) {
      displayError(e);
    }
  };

  // Fetch seminars when the component mounts
  // and when pagination or section changes.
  useEffect(() => {
    getSeminars();
  }, [searchParams, pagination.recordsPerPage]);

  if (!isAdmin) {
    return (
      <EmptyTable message="You do not have permission to view this page" />
    );
  }

  return (
    <>
      <div className="md:hidden">
        <SeminarsMobileList
          seminars={seminars}
          loading={loading}
          isGlobalAdmin={isGlobalAdmin}
        />

        <Pagination
          pagination={pagination}
          loading={loading && !seminars?.length}
          empty={!seminars?.length}
        />
      </div>
      <div className="hidden md:block">
        <Card classNames="lg:mt-10">
          <div className="flex items-center justify-between mb-6">
            <div className="relative">
              {checkPermission(["global_admin", "school_admin"]) && (
                <img
                  src="/add-new.svg"
                  className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                  width={30}
                  height={30}
                  alt="Add New"
                  onClick={() =>
                    setActionMenuModal({
                      open: true,
                      defaultRender: "SchoolSelect",
                    })
                  }
                />
              )}

              <h1 className="text-xl font-bold text-primary">SEMINARS</h1>
            </div>
            <div className="flex">
              <QuickFind onSubmit={onSubmit} value={query} />
            </div>
          </div>
          <Pagination
            pagination={pagination}
            loading={loading && !seminars?.length}
            empty={!seminars?.length}
          >
            <Table>
              <thead>
                <tr>
                  <TableHeader>SEMINAR</TableHeader>
                  <TableHeader>SCHOOLS</TableHeader>
                  <TableHeader>STUDENTS</TableHeader>
                  <TableHeader>PRESENTATIONS</TableHeader>
                  <TableHeader>PRODUCTS</TableHeader>
                  <TableHeader />
                </tr>
              </thead>
              <tbody>
                {seminars?.map((seminar) => (
                  <tr
                    key={seminar.id}
                    className={loading ? "opacity-25" : undefined}
                  >
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/seminars/${seminar.id}/details`}
                      >
                        {highlightText(seminar.name, query)}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/schools/${seminar.host_school?.id}/overview`}
                      >
                        (#{seminar.host_school?.school_code}){" "}
                        {seminar.host_school?.church_name}
                      </Link>
                      {seminar.schools?.length > 0 && (
                        <div className="text-sm pl-2 mt-1 [&>a]:block">
                          {seminar.schools?.map((school) =>
                            isGlobalAdmin ? (
                              <Link
                                key={school.id}
                                className={hoverLink}
                                to={`/schools/${school.id}/overview`}
                              >
                                + (#{school.school_code}) {school.church_name}
                              </Link>
                            ) : (
                              <span key={school.id} className="block">
                                + (#{school.school_code}) {school.church_name}
                              </span>
                            )
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/seminars/${seminar.id}/students`}
                      >
                        {seminar.students_count}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/seminars/${seminar.id}/presentations`}
                      >
                        {seminar.presentations_count}
                      </Link>
                    </TableCell>
                    <TableCell>{seminar.products_count}</TableCell>
                    <TableCell classNames="text-right">
                      {seminar?.students_count === 0 && (
                        <Button
                          variant="primary-outline"
                          onClick={(e) => handleDeleteModal(e, seminar.id)}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Pagination>
        </Card>
      </div>

      <Modal dark open={deleteModalOpen[0]} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen([false, null])}
          confirmAction={() => deleteSeminar(deleteModalOpen[1])}
        />
      </Modal>
    </>
  );
}
