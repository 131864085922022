import React, { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { Outlet, useOutletContext } from "react-router-dom";
import { useMobileNavigation } from "../../../contexts/MobileNavigationContext";
import InstructorNav from "./InstructorNav";
import { fullName } from "../../../utils/fullName";

export default function Instructor({ instructor }) {
  const { user, setActionMenuModal } = useOutletContext();
  const { setMobileNavigation } = useMobileNavigation();
  const [navOpen, setNavOpen] = useState(true);

  const renderAddress = () => {
    const hasFullAddress = instructor.city && instructor.state;
    const partialAddress = instructor.city || instructor.state;

    if (hasFullAddress) {
      return `| ${instructor.city}, ${instructor.state}`;
    }

    if (partialAddress) {
      return `| ${partialAddress}`;
    }

    return "";
  };

  useEffect(() => {
    if (instructor) {
      setMobileNavigation({
        title: fullName(instructor?.first_name, " ", instructor?.last_name),
        links: [
          {
            name: "Overview",
            href: `/instructors/${instructor.id}/overview`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Profile",
            href: `/instructors/${instructor.id}/profile`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Students",
            href: `/instructors/${instructor.id}/students`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Courses",
            href: `/instructors/${instructor.id}/courses`,
            show: true, // TODO: Add permission check
          },
        ],
      });
    }

    return () => {
      setMobileNavigation(null);
    };
  }, [instructor]);

  return (
    <div>
      <div
        className={twMerge(
          !navOpen && "mb-7",
          "md:mb-0 mb-4 flex items-center justify-center text-center"
        )}
      >
        <h1 className="md:block hidden text-2xl">
          {fullName(instructor.first_name, " ", instructor.last_name)}{" "}
          {renderAddress()}
        </h1>

        <div className="md:hidden block">
          <h1 className="text-2xl text-[40px] mb-2 tracking-tight">
            {fullName(instructor.first_name, " ", instructor.last_name)}{" "}
          </h1>
          <p>
            {[instructor?.city, instructor?.state].filter(Boolean).join(", ")}
          </p>
        </div>

        <EllipsisVerticalIcon
          className="md:block hidden ml-5 w-6 cursor-pointer"
          onClick={() => setNavOpen(!navOpen)}
        />
      </div>

      <div className="relative">
        <Transition.Root show={navOpen} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="md:block hidden">
              <InstructorNav instructor={instructor} />
            </div>
          </Transition.Child>
        </Transition.Root>
      </div>

      <Outlet context={{ user, instructor, setActionMenuModal }} />
    </div>
  );
}
