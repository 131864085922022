import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Card from "../../../UI/Card";
import CardHeader from "../../../UI/CardHeader";
import TableScrollable from "../../../UI/Table/TableScrollable";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import StudentMobileDecisions from "./Mobile/StudentMobileDecisions";
import EmptyTable from "../../../UI/EmptyTable";
import { getAllStudentDecisionsRequest } from "../../../../requests/students";
import dayjs from "dayjs";

export default function StudentDecisions() {
  const { student } = useOutletContext();
  const navigate = useNavigate();
  const [decisions, setDecisions] = useState();

  const getAllStudentDecisions = async () => {
    const res = await getAllStudentDecisionsRequest({
      studentId: student.id,
    });

    if (res.data) {
      setDecisions(res.data);
    }
  };

  const seriesTypePlural = (type) => {
    return type === "Course" ? "courses" : "seminars";
  };

  const decisionTypePlural = (decision) => {
    switch (decision.owner_type) {
      case "Visit":
        return "visits";
      case "EpisodeDelivery":
        return decision.decision_option.series_type === "Course"
          ? "lessons"
          : "presentations";
    }
  };

  useEffect(() => {
    getAllStudentDecisions();
  }, []);

  return (
    <>
      <div className="md:hidden">
        <StudentMobileDecisions
          decisions={decisions}
          seriesTypePlural={seriesTypePlural}
          decisionTypePlural={decisionTypePlural}
        />
      </div>
      <Card classNames="md:block hidden min-h-[260px]">
        <CardHeader title="Decisions" />

        {decisions?.length ? (
          <TableScrollable height={147}>
            <thead>
              <tr>
                <TableHeader classNames="w-5/12">DECISION</TableHeader>
                <TableHeader classNames="w-5/12">CONTEXT</TableHeader>
                <TableHeader classNames="w-2/12">DATE</TableHeader>
              </tr>
            </thead>
            <tbody className="h-[147px]">
              {decisions.map((decision) => (
                <tr
                  key={decision.id}
                  onClick={() =>
                    navigate(
                      `/students/${student.id}/${seriesTypePlural(
                        decision.decision_option.series_type
                      )}/enrollments/${decision.cached_enrollment_id}/${decisionTypePlural(
                        decision
                      )}`
                    )
                  }
                  className="cursor-pointer"
                >
                  <TableCell classNames="w-5/12">
                    {decision.decision_option.name}
                  </TableCell>
                  <TableCell classNames="w-5/12">{decision.context}</TableCell>
                  <TableCell classNames="w-2/12">
                    {dayjs(decision.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </TableScrollable>
        ) : (
          <EmptyTable message="No decisions found." />
        )}
      </Card>
    </>
  );
}
