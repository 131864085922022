import React, { useEffect, useState } from "react";
import DesktopDashboard from "./DesktopDashboard";
import MobileDashboard from "./MobileDashboard";
import { getAllCoursesSimpleList } from "../../requests/courses";
import { getAllSeminarsSimpleList } from "../../requests/seminars";
import { getAllUsersSimpleList } from "../../requests/users";
import { getAllStudents } from "../../requests/students";
import { getNeedingFollowupRequest } from "../../requests/enrollments";
import { checkPermission } from "../../utils/checkPermission";

export default function Dashboard() {
  const [courses, setCourses] = useState();
  const [seminars, setSeminars] = useState();
  const [instructors, setInstructors] = useState();
  const [needingFollowup, setNeedingFollowup] = useState();
  const [studentsCount, setStudentsCount] = useState();
  const [interestsCount, setInterestsCount] = useState();
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);

  const getCourses = async () => {
    const res = await getAllCoursesSimpleList();

    if (res.data) {
      setCourses(res.data.courses);
    }
  };

  const getSeminars = async () => {
    const res = await getAllSeminarsSimpleList();

    if (res.data) {
      setSeminars(res.data.seminars);
    }
  };

  const getInstructors = async () => {
    const res = await getAllUsersSimpleList();

    if (res.data) {
      setInstructors(res.data.users);
    }
  };

  const getNeedingFollowup = async () => {
    const res = await getNeedingFollowupRequest();

    if (res.data) {
      setNeedingFollowup(res.data);
    }
  };

  // Only gets total count of students
  const getAllStudentsCount = async () => {
    const res = await getAllStudents({
      params: { per_page: 1 },
    });

    if (res.data) {
      setStudentsCount(res.data.pagination.total_count);
    }
  };

  // Only gets total count of students as "Interests"
  const getAllInterestsCount = async () => {
    const res = await getAllStudents({
      params: { per_page: 1, section: "interests" },
    });

    if (res.data) {
      setInterestsCount(res.data.pagination.total_count);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getInstructors();
    }

    getCourses();
    getSeminars();
    getNeedingFollowup();
    getAllStudentsCount();
    getAllInterestsCount();
  }, []);

  return (
    <>
      <div className="hidden md:block">
        <DesktopDashboard
          needingFollowup={needingFollowup}
          courses={courses}
          seminars={seminars}
          instructors={instructors}
          isAdmin={isAdmin}
        />
      </div>

      <div className="md:hidden">
        <MobileDashboard
          studentsCount={studentsCount}
          interestsCount={interestsCount}
          needingFollowup={needingFollowup}
          courses={courses}
          seminars={seminars}
          instructors={instructors}
          isAdmin={isAdmin}
        />
      </div>
    </>
  );
}
