import React, { useEffect, useRef, useState, isValidElement } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Pill from "../Pill";
import { twMerge } from "tailwind-merge";

const normalizeOptions = (opts) => {
  return opts.map((opt) => {
    if (typeof opt === "string") {
      return { id: opt, name: opt };
    }
    return opt;
  });
};

const normalizeSelected = (sel) => {
  return sel.map((item) => {
    if (typeof item === "string") {
      return { id: item, name: item };
    }
    return item;
  });
};

const formatOptionLabel = (label, searchTerm) => {
  if (!searchTerm) return label;

  const searchWords = searchTerm
    .toLowerCase()
    .split(/\s+/)
    .filter((word) => word.length > 0);
  let result = [label];

  searchWords.forEach((searchWord) => {
    const newResult = [];

    result.forEach((chunk) => {
      if (isValidElement(chunk)) {
        newResult.push(chunk);
        return;
      }

      const text = chunk;
      let lastIndex = 0;
      const lowerText = text.toLowerCase();

      let index;
      while ((index = lowerText.indexOf(searchWord, lastIndex)) !== -1) {
        if (index > lastIndex) {
          newResult.push(text.slice(lastIndex, index));
        }

        newResult.push(
          <span key={`${index}-${searchWord}`} className="underline">
            {text.slice(index, index + searchWord.length)}
          </span>
        );

        lastIndex = index + searchWord.length;
      }

      if (lastIndex < text.length) {
        newResult.push(text.slice(lastIndex));
      }
    });

    result = newResult;
  });

  return <>{result}</>;
};

export default function MultiSelect({
  id,
  placeholder,
  options,
  dark,
  label,
  defaultSelected = [],
  readOnly,
  className,
  disabled,
}) {
  const { setValue, control, watch } = useFormContext();
  const watchedValue = watch(id);
  const [selected, setSelected] = useState(normalizeSelected(defaultSelected));
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef();
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const listRef = useRef(null);
  const inputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleSelect = (option) => {
    if (selected.some((item) => item.id === option.id)) {
      setSelected(selected.filter((item) => item.id !== option.id));
    } else {
      setSelected([...selected, option]);
    }
    setSearchTerm("");
    setDefaultOptions(normalizeOptions(options));
    inputRef.current?.focus();
  };

  const handleFilter = (value) => {
    setSearchTerm(value);
    setOpen(true);
    setFocusedIndex(-1);
    const filteredOptions = options.filter((option) => {
      if (typeof option === "string") {
        return option.toLowerCase().includes(value.toLowerCase());
      } else {
        return option.name.toLowerCase().includes(value.toLowerCase());
      }
    });

    setDefaultOptions(normalizeOptions(filteredOptions));
  };

  const handleKeyDown = (e) => {
    if (!open) {
      if (e.key === "ArrowDown" || e.key === "Enter") {
        setOpen(true);
        setFocusedIndex(0);
      }
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setFocusedIndex((prev) =>
          prev < defaultOptions.length - 1 ? prev + 1 : prev
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setFocusedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        break;
      case "Enter":
        e.preventDefault();
        if (focusedIndex >= 0) {
          handleSelect(defaultOptions[focusedIndex]);
        }
        break;
      case "Escape":
        setOpen(false);
        setFocusedIndex(-1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setDefaultOptions(normalizeOptions(options));

    if (watchedValue && watchedValue.length > 0) {
      setSelected(normalizeSelected(watchedValue));
    }
  }, [options, defaultSelected]);

  useEffect(() => {
    const selectedValues = selected.map((item) =>
      typeof options[0] === "string" ? item.id : item
    );

    const selectedIds = selectedValues?.map((item) => item.id || item).sort();
    const watchedIds = watchedValue?.map((item) => item.id || item).sort();

    if (JSON.stringify(selectedIds) !== JSON.stringify(watchedIds)) {
      setValue(id, selectedValues);
    }
  }, [selected, id, setValue, options, watchedValue]);

  // closes on mouse leave
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Add this effect to scroll focused item into view
  useEffect(() => {
    if (open && focusedIndex >= 0 && listRef.current) {
      const element = listRef.current.children[focusedIndex];
      if (element) {
        element.scrollIntoView({ block: "nearest" });
      }
    }
  }, [focusedIndex, open]);

  return (
    <div
      className={twMerge(
        "flex h-full w-full flex-col rounded-md text-popover-foreground overflow-visible",
        className
      )}
      ref={wrapperRef}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <Controller
        id={id}
        control={control}
        name={id}
        defaultValue={selected}
        render={() => (
          <div
            className={twMerge(
              dark ? "bg-transparent" : "bg-white",
              "group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2",
              disabled && "bg-gray-100 opacity-80 cursor-not-allowed"
            )}
          >
            <div className="flex flex-wrap gap-1">
              {selected.length > 0 &&
                selected.map((item) => (
                  <Pill key={item.id}>
                    {item.name}
                    {!readOnly && (
                      <div className="cursor-pointer bg-secondary-600 rounded-full w-6 md:w-4 ml-1 shadow-inner hover:bg-white hover:text-secondary">
                        <XMarkIcon
                          onClick={() =>
                            setSelected(
                              selected.filter(
                                (selectedItem) => selectedItem.id !== item.id
                              )
                            )
                          }
                        />
                      </div>
                    )}
                  </Pill>
                ))}
              <input
                ref={inputRef}
                id={id}
                value={searchTerm}
                placeholder={!readOnly ? placeholder : ""}
                className={twMerge(
                  dark && "placeholder-white",
                  "ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground pl"
                )}
                role="combobox"
                type="text"
                onChange={(e) => handleFilter(e.target.value)}
                onClick={() => !readOnly && setOpen(!open)}
                onKeyDown={handleKeyDown}
                disabled={readOnly || disabled}
              />
            </div>
          </div>
        )}
      />
      {/* We should not use "relative" here because it causes bugs in scrollable tables */}
      <div className="mt-2 h-full">
        {open && (
          <ul
            ref={listRef}
            className="absolute bg-white max-h-[250px] min-w-[200px] rounded shadow-lg z-50 overflow-y-scroll"
          >
            {defaultOptions.length > 0 ? (
              defaultOptions.map((option, index) => (
                <li
                  key={option.id}
                  className={twMerge(
                    "p-2 cursor-pointer text-black",
                    focusedIndex === index
                      ? "bg-neutral-600 text-white"
                      : "hover:bg-neutral-600 hover:text-white"
                  )}
                  onClick={() => handleSelect(option)}
                >
                  <input
                    type="checkbox"
                    checked={selected.some((item) => item.id === option.id)}
                    onChange={() => handleSelect(option)}
                    className="h-4 w-4 mr-2 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  />
                  {formatOptionLabel(option.name, searchTerm)}
                </li>
              ))
            ) : (
              <li className="p-2 text-black">No options available</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
