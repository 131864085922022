import { logout } from "../requests/auth";
import { removeToken } from "./auth";
import { ToastNotification } from "../components/UI/ToastNotification";

const handleLogout = async () => {
  try {
    const response = await logout();
    removeToken();
    window.location.href = response.data.url;
  } catch (e) {
    const errorMessage = e.response?.data?.error;
    ToastNotification("error", errorMessage);
  }
};

export default handleLogout;
