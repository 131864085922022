import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { login } from "../../../requests/auth";
import { storedToken, setToken } from "../../../utils/auth";
import Button from "../../UI/Button";

function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export default function Login() {
  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");
  const loggedIn = searchParams.get("logged_in");

  const handleLoggedInUser = () => {
    const redirectTo = localStorage.getItem("auth_redirect") || "/";
    localStorage.removeItem("auth_redirect");
    window.location.replace(redirectTo);
  };

  useEffect(() => {
    if (loggedIn === "true" && !storedToken) {
      const userToken = getCookie("user_token");
      setToken(`Bearer ${userToken}`);
      handleLoggedInUser();
    } else if (storedToken) {
      handleLoggedInUser();
    }
  }, [loggedIn, storedToken]);

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-10 px-4 text-white sm:px-6 lg:px-8">
      <div className="flex w-full max-w-sm flex-grow flex-col justify-center space-y-10">
        <h2 className="text-center text-2xl font-bold leading-10 uppercase">
          Student Records System
        </h2>
        {message && <p className="text-center text-red-500">{message}</p>}
        <div className="mt-7 flex items-center justify-center gap-x-6">
          <Button
            type="submit"
            variant="primary"
            classNames="w-40"
            onClick={login}
          >
            Sign In
          </Button>
        </div>
      </div>
      <div className="mx-auto flex flex-col items-center justify-center gap-2 px-2 md:flex-row md:gap-10">
        <p className="min-w-fit text-[17px] leading-9 -tracking-[0.425px]">
          Need an account?
        </p>

        <p className="text-[15px] text-center sm:text-left">
          If you don&apos;t have an SRS account, please contact your DBS
          Coordinator. If you are the DBS Coordinator, please contact the Voice
          of Prophecy at discoverschool@vop.com.
        </p>
      </div>
    </div>
  );
}
