import React, { useState } from "react";
import Info from "./Info";
import ParticipatingSchools from "./ParticipatingSchools";
import Card from "../../../UI/Card";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../UI/Button";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { ToastNotification } from "../../../UI/ToastNotification";
import {
  deleteSeminarRequest,
  updateSeminarRequest,
} from "../../../../requests/seminars";
import Modal from "../../../UI/Modal";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import { displayError } from "../../../../utils/displayError";

export default function SeminarDetails() {
  const { seminar } = useOutletContext();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const methods = useForm();
  const navigate = useNavigate();
  const params = useParams();

  const onSubmit = async (data) => {
    try {
      const res = await updateSeminarRequest({
        ...data,
        seminarId: params.seminarId,
      });

      if (res.data) {
        ToastNotification("success", "Seminar updated successfully.");
        navigate(`/seminars/${res.data.id}/details`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const deleteSeminar = async () => {
    try {
      const res = await deleteSeminarRequest({ seminarId: params.seminarId });

      if (res) {
        ToastNotification("success", "Seminar deleted successfully.");
        navigate(`/seminars`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  return (
    <div>
      <div className="mb-4">
        <Card>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="lg:flex mb-4">
                <Info seminar={seminar} />
              </div>
              <Button
                classNames="md:w-auto md:mr-4 md:mb-0 w-full mb-4"
                variant="primary"
                type="submit"
              >
                Update Seminar
              </Button>
              {seminar?.enrollments_count === 0 && (
                <Button
                  classNames="md:w-auto md:mr-4 md:mb-0 w-full mb-4"
                  variant="secondary"
                  onClick={(e) => handleDeleteModal(e)}
                >
                  Delete Seminar
                </Button>
              )}
            </form>
          </FormProvider>
        </Card>
      </div>

      <div>
        <ParticipatingSchools seminar={seminar} />
      </div>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteSeminar()}
        />
      </Modal>
    </div>
  );
}
