import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../UI/Card";
import ContactInformationForm from "./InstructorProfile/ContactInformationForm";
import AccountForm from "./InstructorProfile/AccountForm";
import Button from "../../UI/Button";
import { signUp } from "../../../requests/auth";
import { displayError } from "../../../utils/displayError";
import { ToastNotification } from "../../UI/ToastNotification";
import { useNavigate } from "react-router-dom";

export default function InstructorNew() {
  const navigate = useNavigate();
  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const payload = await signUp({
        user: {
          ...data,
          type: "Instructor",
          head_school_admin_of: data.head_school_admin
            ? data.head_school_admin_of
            : undefined,
          head_school_admin: undefined, // Dismiss from the payload and rely only on the head_school_admin_of presence
          roles_attributes: data.roles_attributes.map((role) => ({
            id: role.role_id,
            organization_id: role.organization_id,
            organization_type:
              role.role_type === "conference_admin" ? "Conference" : "School",
            role_type: role.role_type,
            allow_sharing: role.allow_sharing,
            _destroy: role.delete,
          })),
        },
      });

      if (payload.status === 201) {
        ToastNotification("success", "Instructor created successfully.");
        navigate(`/instructors/${payload.data.id}/overview`);
      }
    } catch (e) {
      displayError(
        e.response?.data?.errors
          ? {
              response: {
                statusText: JSON.stringify(e.response.data.errors),
              },
            }
          : e
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center mb-7">
        <h1 className="text-2xl">New Instructor | Location</h1>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card classNames="p-3 rounded-2xl md:rounded md:p-6">
            <div className="md:flex">
              <div className="md:w-3/5 md:border-r border-black md:pr-6">
                <ContactInformationForm />
              </div>
              <div className="md:w-2/5 md:pl-6">
                <AccountForm />
              </div>
            </div>
          </Card>

          <div className="md:flex mb-10 md:mb-0 items-center p-6">
            <Button
              variant="primary"
              type="submit"
              classNames="w-full md:w-auto"
              onClick={(e) => {
                e.preventDefault();
                methods.setValue("should_send_welcome_email", false);
                methods.handleSubmit(onSubmit)(e);
              }}
              disabled={loading}
            >
              Create Instructor
            </Button>

            <Button
              variant="primary"
              type="submit"
              classNames="w-full mt-4 md:mt-0 md:w-auto md:ml-4"
              onClick={(e) => {
                e.preventDefault();
                methods.setValue("should_send_welcome_email", true);
                methods.handleSubmit(onSubmit)(e);
              }}
              disabled={loading}
            >
              Create and Send Welcome Email
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
