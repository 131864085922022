import React, { useState } from "react";
import TableCell from "../../../../../../UI/Table/TableCell";
import DropdownMenu from "../../../../../../UI/DropdownMenu";
import DateSent from "./DateSent";
import DateReceived from "./DateReceived";
import DeliveryType from "./DeliveryType";
import Notes from "./Notes";
import Decisions from "./Decisions";
import { PlusIcon } from "@heroicons/react/20/solid";

export default function LessonsRow({
  lesson,
  decisionOptions,
  enrollment,
  user,
}) {
  const [addingDecision, setAddingDecision] = useState(false);
  const [addingNotes, setAddingNotes] = useState(false);
  const episodeDelivery = enrollment.episode_deliveries.find(
    (item) => item.episode_id === lesson.id
  );

  const dropdownItems = [
    {
      label: "Add Note",
      icon: <PlusIcon />,
      onClick: () => setAddingNotes(true),
    },
    {
      label: "Add Decision",
      icon: <PlusIcon />,
      onClick: () => setAddingDecision(true),
    },
  ];

  return (
    <tr key={lesson.id}>
      <TableCell>{lesson.name}</TableCell>
      <TableCell classNames="text-center">
        <DateSent
          enrollment={enrollment}
          lesson={lesson}
          episodeDelivery={episodeDelivery}
          user={user}
          disabled={enrollment.readOnly}
        />
      </TableCell>
      <TableCell classNames="text-center">
        <DateReceived
          enrollment={enrollment}
          lesson={lesson}
          episodeDelivery={episodeDelivery}
          user={user}
          disabled={enrollment.readOnly}
        />
      </TableCell>
      <TableCell classNames="text-center whitespace-nowrap">
        <DeliveryType
          enrollment={enrollment}
          lesson={lesson}
          episodeDelivery={episodeDelivery}
          user={user}
          disabled={enrollment.readOnly}
        />
      </TableCell>
      <TableCell>
        <Notes
          addingNotes={addingNotes}
          setAddingNotes={setAddingNotes}
          lesson={lesson}
          enrollment={enrollment}
          episodeDelivery={episodeDelivery}
          user={user}
          disabled={enrollment.readOnly}
        />
      </TableCell>
      <TableCell>
        <Decisions
          addingDecision={addingDecision}
          setAddingDecision={setAddingDecision}
          lesson={lesson}
          enrollment={enrollment}
          episodeDelivery={episodeDelivery}
          decisionOptions={decisionOptions}
          user={user}
          disabled={enrollment.readOnly}
        />
      </TableCell>
      {!enrollment.readOnly && (
        <TableCell classNames="text-center">
          <DropdownMenu items={dropdownItems} />
        </TableCell>
      )}
    </tr>
  );
}
