import React, { useEffect, useState } from "react";
import {
  getNadBibleSchools,
  downloadCsvNadBibleSchools,
} from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Pagination } from "../UI/Pagination";
import { DownloadCSVIcon, PrintIcon } from "../UI/PageActions/Icons";
import { ToastNotification } from "../UI/ToastNotification";
import { usePageActions } from "../../contexts/PageActionsContext";
import NadBibleSchoolsReportPrint from "./Print/NadBibleSchoolsReportPrint";

export default function NadBibleSchoolsReport({ archived = false }) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pagination = usePagination();
  const { setPageActions } = usePageActions();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [printingReports, setPrintingReports] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const getParams = ({ print = false, csv = false } = {}) => {
    const queryParams = {
      active: archived ? "inactive" : searchParams.get("status") || "active",
      conference_code: searchParams.get("conference_code"),
      discovery_center: searchParams.get("discovery_center"),
      pagination: print ? false : undefined,
      page: csv || print ? undefined : page,
      per_page: csv || print ? undefined : pagination.recordsPerPage,
    };

    return queryParams;
  };

  const getReport = async (callback) => {
    setLoading(true);

    try {
      const res = await getNadBibleSchools({
        params: getParams({ print: Boolean(callback) }),
      });

      if (res.data) {
        if (callback) {
          callback(res.data);
        } else {
          setReports(res.data);
          pagination.setData(res.data.pagination);
        }
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCsv = async () => {
    setDownloadingCsv(true);
    const res = await downloadCsvNadBibleSchools({
      params: getParams({ csv: true }),
    });

    if (!res.success) {
      ToastNotification("error", "Failed to download CSV");
    }

    setDownloadingCsv(false);
  };

  useEffect(() => {
    getReport();
  }, [searchParams, pagination.recordsPerPage]);

  useEffect(() => {
    setPageActions([
      {
        icon: DownloadCSVIcon,
        disabled: loading || downloadingCsv || reports?.conferences?.length < 1,
        loading: downloadingCsv,
        label: "Download CSV",
        onClick: handleDownloadCsv,
      },
      {
        icon: PrintIcon,
        label: "Print Records",
        disabled: loading || isPrinting || reports?.conferences?.length < 1,
        loading: isPrinting,
        onClick: () => {
          setIsPrinting(true);

          getReport((reports) => {
            setPrintingReports(reports);
            setIsPrinting(false);
          });
        },
      },
    ]);

    return () => {
      setPageActions([]);
    };
  }, [
    setPageActions,
    searchParams,
    reports,
    loading,
    isPrinting,
    downloadingCsv,
  ]);

  useEffect(() => {
    if (printingReports?.conferences?.length > 0) window.print();

    return () => {
      if (printingReports?.conferences?.length > 0) setPrintingReports([]);
    };
  }, [printingReports]);

  return (
    <>
      <div className="flex justify-between my-10">
        <p>NAD Bible School List</p>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports.length < 1}
        empty={reports.length < 1}
      >
        {reports.conferences?.length > 0 ? (
          reports.conferences.map((conference) => (
            <div key={conference.id}>
              <p className="text-2xl my-4">{conference.name.toUpperCase()}</p>
              <Table>
                <thead>
                  <tr>
                    <TableHeader>STATE</TableHeader>
                    <TableHeader>CITY</TableHeader>
                    <TableHeader>NAME/CHURCH</TableHeader>
                    <TableHeader>ADDRESS(ES)</TableHeader>
                    <TableHeader>CITY/STATE/ZIP</TableHeader>
                    <TableHeader>CHURCH CONTACT</TableHeader>
                    <TableHeader>SCHOOL ADMIN</TableHeader>
                    <TableHeader>#</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {conference.conference_schools.map((school) => (
                    <tr key={school.id}>
                      <TableCell>{school.church_state}</TableCell>
                      <TableCell>{school.church_city}</TableCell>
                      <TableCell>
                        <p>{school.school_name}</p>
                        <p>{school.church_name}</p>
                      </TableCell>
                      <TableCell>
                        <p>{school.school_address}</p>
                        <p>{school.church_address}</p>
                      </TableCell>
                      <TableCell>
                        <p>{`${school.school_city}, ${school.school_state} ${school.school_zip_code}`}</p>
                        <p>{`${school.church_city}, ${school.church_state} ${school.church_zip_code}`}</p>
                      </TableCell>
                      <TableCell>
                        <p>{school.church_contact}</p>
                        <p>{school.church_phone}</p>
                        <p>{school.church_email}</p>
                      </TableCell>
                      <TableCell>
                        <p>{`${school.head_school_admin?.first_name} ${school.head_school_admin?.last_name}`}</p>
                        <p>{school.head_school_admin?.phone}</p>
                        <p>{school.head_school_admin?.email}</p>
                      </TableCell>
                      <TableCell>{school.school_code}</TableCell>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))
        ) : (
          <EmptyTable message="No schools recorded." />
        )}
      </Pagination>

      <NadBibleSchoolsReportPrint reports={printingReports} />
    </>
  );
}
