import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { checkPermission } from "../../../../utils/checkPermission";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import Results from "./Results";
import PageLoader from "../../../UI/PageLoader";

export default function Search({
  students,
  onSubmit,
  isShowingResults,
  setCreateNewStudent,
  setPlaceManually,
  setStudentSearchName,
  loading,
}) {
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const methods = useForm();

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <p className="text-[15px]">
            Before creating a new student, enter the student's first and last
            name to make sure they do not already exist.
          </p>
          <div className="flex mt-6">
            <Input
              id="search"
              placeholder="Search student"
              classNames="md:w-[300px] mr-4"
              isRequired
              dark
            />
            <Button
              type="submit"
              variant="primary"
              classNames="md:w-[165px]"
              disabled={loading}
            >
              Search
            </Button>
          </div>
        </form>
      </FormProvider>

      <div className="mt-6">
        {loading ? (
          <PageLoader />
        ) : (
          isShowingResults && (
            <Results students={students} query={methods.watch("search")} />
          )
        )}
      </div>

      {isShowingResults && (
        <div className="mt-6 text-center">
          <p>
            If the student you're looking for doesn't exist yet, click the
            button below to add the student
          </p>

          <div className="flex justify-center mt-6">
            {isGlobalAdmin && (
              <Button
                onClick={() => {
                  setCreateNewStudent(true);
                  setStudentSearchName(methods.watch("search"));
                }}
                variant="primary"
                classNames="mr-4"
              >
                Create New Student
              </Button>
            )}
            <Button
              onClick={() => {
                setPlaceManually(true);
                setStudentSearchName(methods.watch("search"));
              }}
              variant="primary"
            >
              Place in School Manually
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
