import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/student_imports`;

const getAllStudentImportsRequest = async (params) => {
  const data = await axiosClient.get(endpoint, params);

  return data;
};

const createStudentImportRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const fetchStudentImportRequest = async (id) => {
  const data = await axiosClient.get(`${endpoint}/${id}`);

  return data;
};

const updateStudentImportRequest = async (id, params) => {
  const data = await axiosClient.put(`${endpoint}/${id}`, params);

  return data;
};

const importStudentImportRequest = async (id) => {
  const data = await axiosClient.post(`${endpoint}/${id}/import`);

  return data;
};

const deleteStudentImportRequest = async (id) => {
  const data = await axiosClient.delete(`${endpoint}/${id}`);

  return data;
};

export {
  createStudentImportRequest,
  getAllStudentImportsRequest,
  fetchStudentImportRequest,
  updateStudentImportRequest,
  deleteStudentImportRequest,
  importStudentImportRequest,
};
