import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import InputGroup from "../../../../../UI/Inputs/InputGroup";
import Input from "../../../../../UI/Inputs/Input";
import Button from "../../../../../UI/Button";

export default function QuestionResponseForm({ onSubmit, response, disabled }) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputGroup classNames="mb-0">
          <Input
            id="response"
            defaultValue={response?.response}
            isRequired
            disabled={disabled}
          />
          {!disabled && (
            <div>
              <Button type="submit" variant="primary-outline">
                Save
              </Button>
            </div>
          )}
        </InputGroup>
      </form>
    </FormProvider>
  );
}
