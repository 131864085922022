import React from "react";
import { defer } from "react-router-dom";
import RouteLoader from "../UI/RouteLoader";
import Seminars from "../Seminars/Seminars";
import Seminar from "../Seminars/Seminar/Seminar";
import SeminarDetails from "../Seminars/Seminar/SeminarDetails/SeminarDetails";
import SeminarStudents from "../Seminars/Seminar/SeminarStudents/SeminarStudents";
import SeminarPresentations from "../Seminars/Seminar/SeminarPresentations/SeminarPresentations";
import SeminarAttendance from "../Seminars/Seminar/SeminarAttendance/SeminarAttendance";
import SeminarDecisions from "../Seminars/Seminar/SeminarDecisions/SeminarDecisions";
import SeminarProducts from "../Seminars/Seminar/SeminarProducts/SeminarProducts";
import SeminarNew from "../Seminars/Seminar/SeminarNew";
import SeminarPrintTickets from "../Seminars/Seminar/SeminarPrintTickets/SeminarPrintTickets";
import { getSeminarRequest } from "../../requests/seminars";

export default [
  {
    path: "/seminars",
    element: <Seminars />,
  },
  {
    path: "/seminars/new",
    element: <SeminarNew />,
  },
  {
    path: "/seminars/:seminarId",
    element: (
      <RouteLoader>
        {(loader) => <Seminar seminar={loader?.data} />}
      </RouteLoader>
    ),
    id: "seminar",
    loader: ({ params }) => {
      return defer({
        data: getSeminarRequest({ seminarId: params.seminarId }),
      });
    },
    children: [
      {
        path: "/seminars/:seminarId/details",
        element: <SeminarDetails />,
      },
      {
        path: "/seminars/:seminarId/students",
        element: <SeminarStudents />,
      },
      {
        path: "/seminars/:seminarId/presentations",
        element: <SeminarPresentations />,
      },
      {
        path: "/seminars/:seminarId/attendance",
        element: <SeminarAttendance />,
      },
      {
        path: "/seminars/:seminarId/decisions",
        element: <SeminarDecisions />,
      },
      {
        path: "/seminars/:seminarId/products",
        element: <SeminarProducts />,
      },
      {
        path: "/seminars/:seminarId/print-tickets",
        element: <SeminarPrintTickets />,
      },
    ],
  },
];
