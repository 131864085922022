import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { twMerge } from "tailwind-merge";
import convertUTCToLocalDate from "../../../utils/dateAndTime/convertUTCToLocalDate";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import Input from "./Input";

export default function DatePickerInput({
  id,
  classNames,
  selected,
  label,
  dark,
  disabled,
  isRequired,
  autoFocus = false,
}) {
  const isMobile = useMediaQuery("md", "down");

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getSelectedDate = (value) => {
    if (!value) return null;
    const convertedDate = convertUTCToLocalDate(value);
    return convertedDate instanceof Date && !isNaN(convertedDate)
      ? convertedDate
      : null;
  };

  return (
    <div>
      {label && <label htmlFor={id}>{label}</label>}
      <Controller
        control={control}
        name={id}
        defaultValue={selected}
        rules={{
          required: isRequired
            ? typeof isRequired === "string"
              ? isRequired
              : "This field is required"
            : false,
        }}
        render={({ field }) =>
          isMobile ? (
            <Input
              id={id}
              type="date"
              inputClassNames={twMerge(
                "border border-gray-200 px-2 py-1.5 rounded w-full bg-white",
                dark && "bg-transparent",
                errors[id] && "border-red-500",
                classNames
              )}
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              autoFocus={autoFocus}
              disabled={disabled}
              placeholder="00/00/0000"
            />
          ) : (
            <ReactDatePicker
              id={id}
              autoFocus={autoFocus}
              disabled={disabled}
              className={twMerge(
                "border border-gray-200 px-2 py-1.5 rounded w-full",
                dark && "bg-transparent",
                errors[id] && "border-red-500",
                classNames
              )}
              selected={getSelectedDate(field.value)}
              onChange={(date) => field.onChange(date)}
              placeholderText="00/00/0000"
            />
          )
        }
      />

      {errors[id] && (
        <p className="absolute text-red-500">{errors[id]?.message}</p>
      )}
    </div>
  );
}
