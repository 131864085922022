import React from "react";
import BackLink from "../../Mobile/BackLink";
import CardLink from "../../UI/Card/CardLink";
import { fullName } from "../../../utils/fullName";
import PageLoader from "../../UI/PageLoader";

export default function InstructorsMobileList({ instructors }) {
  if (!instructors) return <PageLoader />;

  return (
    <div>
      <BackLink path="/" label="Instructors" />

      <div className="pt-4">
        {instructors?.map((instructor) => (
          <CardLink
            key={instructor.id}
            to={`/instructors/${instructor.id}/overview`}
            className="mb-4 last:mb-0 block"
            cardClassName="rounded-xl p-0 pb-2"
          >
            <span className="flex p-4 pb-2 justify-between">
              {fullName(instructor.first_name, " ", instructor.last_name)}
            </span>
          </CardLink>
        ))}
      </div>
    </div>
  );
}
