import React from "react";
import { Link } from "react-router-dom";
import { EnvelopeIcon, PrinterIcon } from "@heroicons/react/20/solid";
import { checkPermission } from "../../../utils/checkPermission";
import { Tooltip } from "../../UI/Tooltip";
import { useMediaQuery } from "../../../utils/useMediaQuery";

export default function PageActions({ schoolId }) {
  const isXlUp = useMediaQuery("xl", "up");
  const isGlobalAdmin = checkPermission(["global_admin"]);

  return (
    <div className="mb-4 flex justify-center">
      <Tooltip content="Print Information" disabled={isXlUp}>
        <Link
          to={`/reports?report_type=school_information_forms_report&school_id=${schoolId}&viewOnly=1`}
          className="mr-5 flex items-center cursor-pointer"
        >
          <PrinterIcon className="w-5" />
          <p className="hidden xl:block ml-2">Print Information</p>
        </Link>
      </Tooltip>

      {isGlobalAdmin && (
        <Tooltip content="Print Envelope" disabled={isXlUp}>
          <Link
            to={`/reports?report_type=print_school_envelopes_report&schools_ids=${schoolId}`}
            className="flex items-center cursor-pointer"
          >
            <EnvelopeIcon className="w-5" />
            <p className="hidden xl:block ml-2">Print Envelope</p>
          </Link>
        </Tooltip>
      )}
    </div>
  );
}
