import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import CardHeader from "../../../UI/CardHeader";
import TableScrollable from "../../../UI/Table/TableScrollable";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import StudentMobileProducts from "./Mobile/StudentMobileProducts";
import EmptyTable from "../../../UI/EmptyTable";
import { getAllStudentProductsRequest } from "../../../../requests/students";
import { useNavigate, useOutletContext } from "react-router-dom";
import dayjs from "dayjs";

export default function StudentProducts() {
  const navigate = useNavigate();
  const { student } = useOutletContext();
  const [deliveries, setDeliveries] = useState();

  const getAllStudentProducts = async () => {
    const res = await getAllStudentProductsRequest({
      studentId: student.id,
    });

    if (res.data) {
      setDeliveries(res.data);
    }
  };

  const seriesTypePlural = (type) => {
    return type === "Course" ? "courses" : "seminars";
  };

  useEffect(() => {
    getAllStudentProducts();
  }, []);

  return (
    <>
      <div className="md:hidden">
        <StudentMobileProducts
          deliveries={deliveries}
          seriesTypePlural={seriesTypePlural}
        />
      </div>
      <Card classNames="md:block hidden min-h-[260px]">
        <CardHeader title="Products" />

        {deliveries?.length ? (
          <TableScrollable height={147}>
            <thead>
              <tr>
                <TableHeader classNames="w-2/12">SKU</TableHeader>
                <TableHeader classNames="w-4/12">PRODUCT</TableHeader>
                <TableHeader classNames="w-4/12">CONTEXT</TableHeader>
                <TableHeader classNames="w-2/12">DATE</TableHeader>
              </tr>
            </thead>
            <tbody className="h-[147px]">
              {deliveries.map((delivery) => (
                <tr
                  key={delivery.id}
                  onClick={() =>
                    navigate(
                      `/students/${student.id}/${seriesTypePlural(
                        delivery.series_type
                      )}/enrollments/${delivery.enrollment_id}/products`
                    )
                  }
                  className="cursor-pointer"
                >
                  <TableCell classNames="w-2/12">
                    {delivery.product.sku}
                  </TableCell>
                  <TableCell classNames="w-4/12">
                    {delivery.product.name}
                  </TableCell>
                  <TableCell classNames="w-4/12">{delivery.context}</TableCell>
                  <TableCell classNames="w-2/12">
                    {dayjs(delivery.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </TableScrollable>
        ) : (
          <EmptyTable message="No products found." />
        )}
      </Card>
    </>
  );
}
