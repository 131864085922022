import React from "react";
import Print from "../../UI/Print";

export default function SchoolContactReportPrint({
  reports,
  handleAfterPrint,
}) {
  return (
    <Print
      header="NAD Bible School List"
      empty={reports?.conferences?.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      {reports?.conferences?.map((conference) => (
        <div key={conference.id}>
          <h2 className="mt-4 font-bold">{conference.name.toUpperCase()}</h2>

          <table>
            <thead>
              <tr>
                <th>STATE</th>
                <th>CITY</th>
                <th>NAME/CHURCH</th>
                <th>ADDRESS(ES)</th>
                <th>CITY/STATE/ZIP</th>
                <th>CHURCH CONTACT</th>
                <th>SCHOOL ADMIN</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {conference.conference_schools.map((school) => (
                <tr key={school.id}>
                  <td>{school.church_state}</td>
                  <td>{school.church_city}</td>
                  <td>
                    {school.school_name}
                    {school.church_name}
                  </td>
                  <td>
                    {school.school_address}
                    {school.church_address}
                  </td>
                  <td>
                    <p>{`${school.school_city}, ${school.school_state} ${school.school_zip_code}`}</p>
                    <p>{`${school.church_city}, ${school.church_state} ${school.church_zip_code}`}</p>
                  </td>
                  <td>
                    <p>{school.church_contact}</p>
                    <p>{school.church_phone}</p>
                    <p>{school.church_email}</p>
                  </td>
                  <td>
                    <p>{`${school.head_school_admin?.first_name} ${school.head_school_admin?.last_name}`}</p>
                    <p>{school.head_school_admin?.phone}</p>
                    <p>{school.head_school_admin?.email}</p>
                  </td>
                  <td>{school.school_code}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </Print>
  );
}
