import React from "react";
import Card from "../UI/Card";
import TableScrollable from "../UI/Table/TableScrollable";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import EmptyTable from "../UI/EmptyTable";
import PageLoader from "../UI/PageLoader";
import { fullName } from "../../utils/fullName";
import { Link } from "react-router-dom";
import { hoverLink } from "../../utils/hoverLink";
import dayjs from "dayjs";
import { relativeTime } from "../../utils/dateAndTime/relativeTime";
import CardHeader from "../UI/CardHeader";
import { checkPermission } from "../../utils/checkPermission";

export default function NeedingFollowupTable({ needingFollowup }) {
  dayjs.extend(relativeTime);
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);

  return (
    <Card classNames={isAdmin ? "min-h-[1307px]" : "min-h-[866px]"}>
      <CardHeader title="Students needing follow-up" />

      {needingFollowup?.length > 0 ? (
        <TableScrollable height={isAdmin ? 1177 : 736}>
          <thead>
            <tr>
              <TableHeader classNames="w-1/4">STUDENT NAME</TableHeader>
              <TableHeader classNames="w-1/4">COURSE</TableHeader>
              <TableHeader classNames="w-1/4">INSTRUCTOR</TableHeader>
              <TableHeader classNames="w-1/4">LAST FOLLOW UP</TableHeader>
            </tr>
          </thead>
          <tbody className={isAdmin ? "h-[1177px]" : "h-[736px]"}>
            {needingFollowup.map((followup) => (
              <tr key={followup.id}>
                <TableCell classNames="w-1/4">
                  <Link
                    className={hoverLink}
                    to={`/students/${followup.student.id}/overview`}
                  >
                    {fullName(
                      followup.student.first_name,
                      " ",
                      followup.student.last_name
                    )}
                  </Link>
                </TableCell>
                <TableCell classNames="w-1/4">
                  <Link
                    className={hoverLink}
                    to={`/students/${followup.student.id}/${followup.series.type.toLowerCase()}s/enrollments/${followup.id}/${followup.series.type === "Course" ? "lessons" : "presentations"}`}
                  >
                    {followup.series.name}
                  </Link>
                </TableCell>
                <TableCell classNames="w-1/4">
                  {isAdmin ? (
                    <Link
                      className={hoverLink}
                      to={`/instructors/${followup.student.instructor?.id}/overview`}
                    >
                      {fullName(
                        followup.student.instructor?.first_name,
                        " ",
                        followup.student.instructor?.last_name
                      )}
                    </Link>
                  ) : (
                    fullName(
                      followup.student.instructor?.first_name,
                      " ",
                      followup.student.instructor?.last_name
                    )
                  )}
                </TableCell>
                <TableCell classNames="w-1/4">
                  {dayjs().to(dayjs(followup.cached_latest_followup_date))}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </TableScrollable>
      ) : needingFollowup ? (
        <EmptyTable message="No students needing followup." />
      ) : (
        <PageLoader />
      )}
    </Card>
  );
}
