import React from "react";
import SearchAsync from "./SearchAsync";
import { getAllUsers } from "../../requests/users";

export default function SearchUser({
  userType,
  field,
  reassign = false,
  light = false,
  filterByschoolId,
  highlightSchoolId,
}) {
  const queryParams = {
    type: userType,
    include_all_schools: reassign,
    per_page: 200,
    q: { s: "first_name asc" },
  };

  // If schoolId is provided we restrict the search to it's users
  if (filterByschoolId) {
    queryParams.q.head_school_admin_for_schools_id_or_schools_id_eq =
      filterByschoolId;
  }

  const loadOptions = async (inputValue) => {
    try {
      // Only add the search parameter if query is not empty.
      // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
      // Splitting the query terms into an array for the `cont` matcher.
      if (inputValue.trim()) {
        const attributes = [
          "full_name",
          "head_school_admin_for_schools_school_code",
          "head_school_admin_for_schools_church_name",
          "schools_school_code",
          "schools_church_name",
        ];

        queryParams.q[`${attributes.join("_or_")}_i_cont_all`] = inputValue
          ?.trim()
          .replace("#", "")
          .split(" ");
      }

      const response = await getAllUsers({ params: queryParams });
      let groupedUsers;

      if (reassign) {
        // Group users based in all_schools and create a group for each School
        // repeating the same user for different schools if User belongs to multiple schools.
        // If a filterByschoolId is provided we only show that school as a group.
        // If a highlightSchoolId is provided we put that school as the first group.
        groupedUsers = response.data.users.reduce((acc, user) => {
          user.all_schools
            .filter((school) =>
              filterByschoolId ? school.id === filterByschoolId : true
            )
            .forEach((school) => {
              const group = `(#${school.school_code}) ${school.church_name}`;
              if (!acc[group]) {
                acc[group] = [];
              }
              acc[group].push({
                label: `${user.first_name} ${user.last_name} (${user.email})`,
                value: `${school.id}#${user.id}`,
              });
            });
          return acc;
        }, {});

        // Transform grouped users into array and sort to highlight specific school
        const options = Object.entries(groupedUsers).map(([group, users]) => ({
          label: group,
          options: users,
        }));

        if (highlightSchoolId) {
          options.sort((a) => {
            const aSchoolId = a.options[0]?.value.split("#")[0];
            return aSchoolId === highlightSchoolId ? -1 : 1;
          });
        }

        return options;
      } else {
        // Group users by search_group_name
        groupedUsers = response.data.users.reduce((acc, user) => {
          const group = "Instructor";
          if (!acc[group]) {
            acc[group] = [];
          }
          acc[group].push({
            label: `${user.first_name} ${user.last_name} (${user.email})`,
            value: user.id,
          });
          return acc;
        }, {});
      }

      // Transform grouped users into the required format
      const options = Object.entries(groupedUsers).map(([group, users]) => ({
        label: group,
        options: users,
      }));

      return options;
    } catch (error) {
      return [];
    }
  };

  return (
    <SearchAsync
      {...field}
      className="w-full"
      placeholder={`Search ${userType}`}
      loadOptions={loadOptions}
      light={light}
    />
  );
}
