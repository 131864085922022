import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../UI/Card";
import ContactInformationForm from "./StudentProfile/ContactInformationForm";
import DemographicsForm from "./StudentProfile/DemographicsForm";
import { createStudentRequest } from "../../../requests/students";
import { getSchoolRequest } from "../../../requests/schools";
import { createEnrollmentRequest } from "../../../requests/enrollments";
import { ToastNotification } from "../../UI/ToastNotification";
import Button from "../../UI/Button";
import { checkPermission } from "../../../utils/checkPermission";
import Instructors from "./StudentProfile/Instructors";
import { displayError } from "../../../utils/displayError";
import PageLoader from "../../UI/PageLoader";
import EnrollmentForm from "./StudentProfile/EnrollmentForm";

export default function StudentNew() {
  const [searchParams] = useSearchParams();
  const schoolId = searchParams.get("school_id");
  const methods = useForm();
  const navigate = useNavigate();
  const [school, setSchool] = useState();
  const [loading, setLoading] = useState(true);
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);

  const onSubmit = async (data) => {
    window.scrollTo(0, 0);
    setLoading(true);

    try {
      const res = await createStudentRequest({
        ...data,
        school_id: data.school_and_instructor?.value?.split("#")[0],
        instructor_id: data.school_and_instructor?.value?.split("#")[1],
      });

      if (res.data) {
        if (data.series_id) {
          // Using FormData approach to send the interest card file
          const formData = new FormData();

          formData.append("enrollment[student_id]", res.data.id);
          formData.append("enrollment[series_id]", data.series_id);
          formData.append("enrollment[school_id]", schoolId);
          formData.append(
            "enrollment[notify_instructor_by_email]",
            data.notify_instructor_by_email
          );

          if (data.registration_number) {
            formData.append(
              "enrollment[registration_number]",
              data.registration_number
            );
          }

          if (data.interest_card && data.interest_card[0]) {
            formData.append(
              "enrollment[interest_card_file]",
              data.interest_card[0]
            );
          }

          if (data.presentation) {
            formData.append(
              "enrollment[episode_deliveries_attributes][0][date_completed]",
              new Date()
            );
            formData.append(
              "enrollment[episode_deliveries_attributes][0][episode_id]",
              data.presentation
            );
            formData.append(
              "enrollment[episode_deliveries_attributes][0][grading_instructor_id]",
              data.school_and_instructor?.value?.split("#")[1]
            );
            formData.append(
              "enrollment[episode_deliveries_attributes][0][delivery_type]",
              "Personal Delivery"
            );
          }

          await createEnrollmentRequest(formData);
        }

        ToastNotification("success", "Student created successfully.");
        navigate(`/students/${res.data.id}/overview`);
      }
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (schoolId) {
      getSchoolRequest({ schoolId }).then((res) => {
        setSchool(res.data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [schoolId]);

  if (loading)
    return (
      <Card classNames="p-6 lg:mt-10">
        <PageLoader />
      </Card>
    );

  return (
    <div>
      <div className="flex items-center justify-center mb-7">
        <h1 className="text-2xl">New Student</h1>
      </div>
      {school && !school.head_school_admin_id ? (
        <Card classNames="p-6">
          <p className="text-red-600 mb-4">
            The selected school needs a <b>Head School Admin</b> before it can
            accept students.
          </p>

          {isAdmin && (
            <Button
              classNames=""
              variant="primary"
              onClick={() => navigate(`/schools/${schoolId}/overview`)}
            >
              Manage School
            </Button>
          )}
        </Card>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <Card classNames="p-6">
              <div className="flex md:flex-row flex-col">
                <div className="md:w-3/5 md:border-r md:border-black md:pr-6">
                  {schoolId && (
                    <EnrollmentForm school={school} methods={methods} />
                  )}
                  <ContactInformationForm isAdmin={isAdmin} />
                </div>
                <div className="md:w-2/5 md:pl-6">
                  <Instructors
                    isAdmin={isAdmin}
                    school={school}
                    methods={methods}
                  />
                  <DemographicsForm
                    isAdmin={isAdmin}
                    prisonMinistry={school?.prison_ministry}
                  />
                </div>
              </div>
            </Card>
            <div className="flex items-center p-6">
              <Button
                classNames="md:w-auto w-full mb-4 mr-4"
                variant="primary"
                type="submit"
              >
                Create Student
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
}
