import { removeToken, storedToken } from "../../utils/auth";

export default function PrivateRoute({ children }) {
  if (storedToken) {
    return children;
  }

  removeToken();

  // Store the current path in localStorage before redirecting
  const currentPath = window.location.pathname + window.location.search;
  localStorage.setItem("auth_redirect", currentPath);

  return window.location.replace("/login");
}
